const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";
const userTypeKey = "userType";
const subscribed = "subscribed";
const isSurveyCompleted = "isSurveyCompleted";

const saveSurveyInfo = (survey) => {
  localStorage.setItem(isSurveyCompleted, survey);
};

const retrieveSurveyInfo = () => {
  return localStorage.getItem(isSurveyCompleted);
};

const saveSubscriptionInfo = (subscribe) => {
  localStorage.setItem(subscribed, subscribe);
};

const retrieveSubscriptionInfo = () => {
  return localStorage.getItem(subscribed);
};

const saveUserType = (userType) => {
  localStorage.setItem(userTypeKey, userType);
};

const retrieveUserType = () => {
  return localStorage.getItem(userTypeKey);
};

const saveAccessToken = (token) => {
  localStorage.setItem(accessTokenKey, token);
};

const saveRefreshToken = (token) => {
  localStorage.setItem(refreshTokenKey, token);
};

const retrieveTokens = () => {
  const access = localStorage.getItem(accessTokenKey);
  const refresh = localStorage.getItem(refreshTokenKey);
  return { access, refresh };
};

const removeTokens = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
};

export const authStorage = {
  saveAccessToken,
  saveRefreshToken,
  retrieveTokens,
  removeTokens,
  saveUserType,
  retrieveUserType,
  saveSubscriptionInfo,
  retrieveSubscriptionInfo,
  saveSurveyInfo,
  retrieveSurveyInfo,
};
