import React from "react";
import "./Spinner.css";

function Spinner() {
  return (
    <div className="spinner-body">
      <div id="spinner"></div>
    </div>
  );
}

export default Spinner;
