import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as subscriptionsActions } from "../redux/actions/subscriptionsActions";
import InnerHTML from "dangerously-set-html-content";

const ConfirmPayment = ({ checkPayment, paymentDetails }) => {
  let history = useHistory();
  let { id } = useParams();

  if (paymentDetails) {
    return (
      <InnerHTML html={paymentDetails.html_snippet}/>
    );
  }

  useEffect(() => {
    checkPayment(id).then(() => {
      history.push("/");
    });
  }, [checkPayment, id]);

  return (
    <div>
      Payment received
    </div>
  );
};

ConfirmPayment.propTypes = {
  checkPayment: PropTypes.func.isRequired,
  paymentDetails: PropTypes.object
};

function mapStateToProps(state) {
  return {
    paymentDetails: state.subscriptions.paymentDetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkPayment: bindActionCreators(
      subscriptionsActions.checkPayment,
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPayment);
