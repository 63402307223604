/* eslint-disable camelcase */
export const COURSE_LIST_REQUEST = "COURSE_LIST_REQUEST";
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS";
export const COURSE_LIST_FAILURE = "COURSE_LIST_FAILURE";

export const COURSE_DETAILS_REQUEST = "COURSE_DETAILS_REQUEST";
export const COURSE_DETAILS_SUCCESS = "COURSE_DETAILS_SUCCESS";
export const COURSE_DETAILS_FAILURE = "COURSE_DETAILS_FAILURE";

export const COURSE_SUBMIT_SURVEY_REQUEST = "COURSE_SUBMIT_SURVEY_REQUEST";
export const COURSE_SUBMIT_SURVEY_SUCCESS = "COURSE_SUBMIT_SURVEY_SUCCESS";
export const COURSE_SUBMIT_SURVEY_FAILURE = "COURSE_SUBMIT_SURVEY_FAILURE";

export const COURSE_SUBMIT_PROGRESS_REQUEST = "COURSE_SUBMIT_PROGRESS_REQUEST";
export const COURSE_SUBMIT_PROGRESS_SUCCESS = "COURSE_SUBMIT_PROGRESS_SUCCESS";
export const COURSE_SUBMIT_PROGRESS_FAILURE = "COURSE_SUBMIT_PROGRESS_FAILURE";

const fetchAllCourses = () => ({
  types: [COURSE_LIST_REQUEST, COURSE_LIST_SUCCESS, COURSE_LIST_FAILURE],
  endpoint: "courses/",
  method: "GET",
});

const fetchCourseDetails = (title) => ({
  types: [
    COURSE_DETAILS_REQUEST,
    COURSE_DETAILS_SUCCESS,
    COURSE_DETAILS_FAILURE,
  ],
  endpoint: `course/${title}`,
  method: "GET",
});

const submitQuizSurvey = (
  course,
  goal1,
  goal2,
  activity,
  date_from,
  date_to,
  decide_who,
  status1,
  status2,
  comments
) => ({
  types: [
    COURSE_SUBMIT_SURVEY_REQUEST,
    COURSE_SUBMIT_SURVEY_SUCCESS,
    COURSE_SUBMIT_SURVEY_FAILURE
  ],
  endpoint: "quiz_survey/",
  method: "POST",
  body: {
    course,
    goal1,
    goal2,
    activity,
    date_from,
    date_to,
    decide_who,
    status1,
    status2,
    comments,
  }
});

const submitCourseProgress = (courseTitle, lessonType, lessonId) => ({
  types: [
    COURSE_SUBMIT_PROGRESS_REQUEST,
    COURSE_SUBMIT_PROGRESS_SUCCESS,
    COURSE_SUBMIT_PROGRESS_FAILURE
  ],
  endpoint: "complete-lesson/",
  method: "POST",
  body: {
    course: courseTitle,
    lesson_type: lessonType,
    lesson_id: lessonId
  }
});

export const actions = {
  fetchAllCourses,
  fetchCourseDetails,
  submitQuizSurvey,
  submitCourseProgress
};

const ACTION_HANDLERS = {
  [COURSE_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    courses: payload.results,
  }),
  [COURSE_DETAILS_SUCCESS]: (state, { payload }) => ({
    ...state,
    courseDetails: payload,
  }),
};

export const initialState = {
  courses: [],
  courseDetails: {},
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
