import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../../redux/actions/authActions";
import { Route, Switch, Redirect, } from "react-router-dom";
import CoursesLoggedOutPage from "../CoursesLoggedOutPage";
import LandingPage from "../LandingPage";
import LoginPageMobile from "../LoginPageMobile";
import RegisterPageMobile from "../RegisterPageMobile";
import TopBarMenuLanding from "../../components/TopBarMenuLanding";
import ContentLoggedOutPage from "../ContentLoggedOutPage";

const RoutingLoggedOut = () => {
  return (
    <>
      <TopBarMenuLanding/>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={LoginPageMobile} />
        <Route exact path="/registrera" component={RegisterPageMobile} />
        <Route exact path="/teman" component={CoursesLoggedOutPage} />
        <Route exact path="/registrera/:token" component={LandingPage} />
        <Route exact path="/aterstall-losenord/:token" component={LandingPage} />
        <Route exact path="/kakor" component={ContentLoggedOutPage} />
        <Route exact path="/kopvillkor" component={ContentLoggedOutPage} />
        <Route exact path="/integritetspolicy" component={ContentLoggedOutPage} />
        <Route exact path="/om-oss" component={ContentLoggedOutPage} />
        <Route exact path="/hur-det-fungerar" component={ContentLoggedOutPage} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(authActions.logout, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RoutingLoggedOut);
