import React from "react";
import PropTypes from "prop-types";

const FormError = ({ error }) => {
  if (Array.isArray(error)) {
    return (<div>
      {error.map((err, idx) => (
      <p key={idx}>{err[Object.keys(err)[0]]}</p>
      ))}
    </div>);
  }
  if (typeof error === typeof "") {
    return <p>{error}</p>;
  }
  return <p>An error occurred</p>;
};

FormError.propTypes = {
  error: PropTypes.any.isRequired
};

export default FormError;
