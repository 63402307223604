import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../../redux/actions/authActions";
import { Route, Switch, Redirect } from "react-router-dom";
import TopBarMenuCompany from "../../components/TopBarMenuCompany";
import AudioPage from "../AudioPage";
import CourseDetails from "../CourseDetails";
import CourseQuiz from "../CourseQuiz";
import CourseReadingChapter from "../CourseReadingChapter";
import CoursesPage from "../CoursesPage";
import ContentLoggedOutPage from "../ContentLoggedOutPage";
import ReadingPage from "../ReadingPage";
import VideoPage from "../VideoPage";
import SubscribePage from "../SubscribePage";
import ConfirmPayment from "../ConfirmPayment";
import DashboardCompanyPage from "../DashboardCompanyPage";
import ReportPage from "../ReportPage";
import ProfilePage from "../ProfilePage";
import SurveyPage from "../SurveyPage";
import GoToSurveyPage from "../GoToSurveyPage";
import SurveyResultPage from "../SurveyResultPage";
import QuizSurveyPage from "../QuizSurveyPage";
import QuizResultPage from "../QuizResultPage";
import IndividualCoursesPage from "../IndividualCoursesPage";
import OrganizationCoursesPage from "../OrganizationCoursesPage";
import GroupCoursesPage from "../GroupCoursesPage";
import { actions as userActions } from "../../redux/actions/userActions";

const RoutingCompany = ({
  logout,
  userData,
  fetchUserData,
}) => {

  useEffect(() => {
    fetchUserData();
  }, []);

// Will change components after merge so this branch got access to company dashboard and pick subscription
  return (
    <>
      <TopBarMenuCompany logout={logout} userData={userData}/>
      <Switch>
        <Route exact path="/" component={DashboardCompanyPage} />
        <Route exact path="/teman" component={CoursesPage} />
        <Route exact path="/tema/:title" component={CourseDetails} />
        <Route exact path="/tema/:title/quiz" component={CourseQuiz} />
        <Route exact path="/tema/:title/quiz-resultat/:id" component={QuizResultPage} />
        <Route exact path="/tema/:title/quiz-enkat" component={QuizSurveyPage} />
        <Route
          exact
          path="/tema/:title/lasning"
          component={ReadingPage}
        />
        <Route
          exact
          path="/tema/:title/lasning/kapitel/:id"
          component={CourseReadingChapter}
        />
        <Route exact path="/tema/:title/audio" component={AudioPage} />
        <Route exact path="/tema/:title/video/:id" component={VideoPage} />
        <Route exact path="/tema/:title/video/" component={VideoPage} />
        <Route exact path="/betalning/bekraftelse/:id" component={ConfirmPayment} />
        <Route exact path="/se-enkat" component={GoToSurveyPage} />
        <Route exact path="/enkat" component={SurveyPage} />
        <Route exact path="/enkat-resultat" component={SurveyResultPage} />
        <Route exact path="/teman/individ" component={IndividualCoursesPage} />
        <Route exact path="/teman/organisation" component={OrganizationCoursesPage} />
        <Route exact path="/teman/grupp" component={GroupCoursesPage} />
        <Route exact path="/rapporter" component={ReportPage} />
        <Route exact path="/prenumerationer" component={SubscribePage} />
        <Route exact path="/kakor" component={ContentLoggedOutPage} />
        <Route exact path="/kopvillkor" component={ContentLoggedOutPage} />
        <Route exact path="/profil" component={ProfilePage} />
        <Route exact path="/integritetspolicy" component={ContentLoggedOutPage} />
        <Route exact path="/om-oss" component={ContentLoggedOutPage} />
        <Route exact path="/hur-det-fungerar" component={ContentLoggedOutPage} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

RoutingCompany.propTypes = {
  logout: PropTypes.func.isRequired,
  userData: PropTypes.object,
  fetchUserData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userData: state.userData.userdata,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(authActions.logout, dispatch),
    fetchUserData: bindActionCreators(userActions.fetchUserData, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutingCompany);
