import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../components/LoadingIndicator";

const CompanyUserList = ({ invitedUsers }) => {
  if (!invitedUsers) {
    return <LoadingIndicator />;
  }

  return (
    <div className="w-48per bg-white rounded-card flex flex-col md:w-full sm:w-full">
      <div className="p-5">
        <h1 className="font-semibold text-2xl pb-2 max-w-md md:max-w-none sm:text-xl">
          Se dina medarbetare som använder sig av LearnFlow
        </h1>
        <p className="text-sm text-gray-500">
          Här visas dina medarbetare som använder sig av LearnFlow
        </p>
      </div>
      <hr />
      <div className="flex flex-col overflow-hidden md:pb-10">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 lg:overflow-x-scroll">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Namn
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {invitedUsers.map((person) => (
                    <tr key={person.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm font-medium text-gray-900">
                            {person.name}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {person.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {person.status ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Aktiv
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-green-800">
                            Injuden
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyUserList.propTypes = {
  invitedUsers: PropTypes.array.isRequired,
};

export default CompanyUserList;
