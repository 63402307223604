import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./CoursesCarousel.css";

const CourseProfile = ({ courses }) => {
  return (
    <>
      {courses &&
        courses.map((el) => (
          <div className="flex mr-6 w-56 md:w-3/5 flex-col md:mr-4 carousel-item" key={el.id}>
            <Link to={`/tema/${encodeURIComponent(el.slug)}`}>
              <div className="relative">
                <div className="rounded-xl w-full h-52">
                  <img
                    className="rounded-lg object-cover h-full w-full"
                    src={el.image}
                  />
                </div>
                <div className="absolute text-white bg-black top-0 left-0 right-0 rounded-tr-lg rounded-tl-lg text-sm py-1 px-3">
                  {el.title}
                </div>
                <div className="absolute text-white bg-black bottom-0 right-0 rounded-br-lg text-xs py-1 px-3">
                  {el.length} min
                </div>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
};

CourseProfile.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default CourseProfile;
