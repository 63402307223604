import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { GrFormClose } from "react-icons/gr";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../../redux/actions/authActions";
import RegisterFormUser from "../forms/RegisterFormUser";

import logo from "../../assets/login-background.png";
import FormError from "../forms/FormError";

const RegisterRegularUserModal = ({ registerUser, login, closeModal }) => {
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  let history = useHistory();

  const onSubmit = ({ username, password, firstName, lastName }) => {
    setErrors(false);
    setLoading(true);
    registerUser(username, password, firstName, lastName, token)
      .catch((e) => {
        setErrors(e);
        setLoading(false);
      })
      .then(() => {
        login(username, password).then(() => {
          history.push("/se-enkat");
        });
      });
  };

  return (
    <div className="w-full h-full bg-white flex">
      <div className="h-full w-1/2 relative flex justify-center">
        <img className="h-full object-cover w-full" src={logo} alt="logo" />
        <h1 className="text-white absolute bottom-5 text-6xl">
          Learn<span className="font-bold">Flow</span>
          <sup className="text-4xl">®</sup>
        </h1>
      </div>
      <div className="flex flex-col flex-grow px-14 py-10 relative justify-evenly">
        <div
          onClick={closeModal}
          className="absolute top-10 right-10 cursor-pointer"
        >
          <GrFormClose className="w-6 h-6" />
        </div>
        <RegisterFormUser onSubmit={onSubmit} />
        <div className="h-10">
          {errors && (
            <div className="mt-1 text-center text-red-500 text-sm">
              <FormError error={errors} />
            </div>
          )}
          {loading && (
            <div className="mt-1 text-center text-sm">Läser in...</div>
          )}
        </div>
      </div>
    </div>
  );
};

RegisterRegularUserModal.propTypes = {
  registerUser: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    registerUser: bindActionCreators(authActions.registerUser, dispatch),
    login: bindActionCreators(authActions.login, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RegisterRegularUserModal);
