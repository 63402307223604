import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../redux/actions/userActions";
import DetailedReportBanner from "../components/DetailedReportBanner";
import ReportBanner from "../components/ReportBanner";
import ReportStats from "../components/ReportStats";
import LoadingIndicator from "../components/LoadingIndicator";
import ReportResults from "../components/ReportResults";

const ReportPage = ({ fetchUserData, userdata }) => {
  const [active, setActive] = useState(true);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultat, setResultat] = useState([]);
  const answerNumber = [1, 2, 3, 4, 5, 6];

  const sumSurveys = () => {
    if (userdata.profile_data.users_surveys.CHANGE.values) {
      const sum = userdata.profile_data.users_surveys.CHANGE.values.map(
        (val, idx) => {
          return (
            val +
            userdata.profile_data.users_surveys.INDIVIDUAL.values[idx] +
            userdata.profile_data.users_surveys.TEAM.values[idx] +
            userdata.profile_data.users_surveys.ORGANISATION.values[idx]
          );
        }
      );
      return sum;
    }
  };

  useEffect(() => {
    setErrors(false);
    setLoading(true);
    fetchUserData()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setErrors(true);
      });
  }, [fetchUserData]);

  useEffect(() => {
    if (userdata) {
      setResultat(sumSurveys());
    }
  }, [userdata]);

  if (!userdata || loading) {
    return <LoadingIndicator />;
  }

  if (errors) {
    return <div>Error occurred</div>;
  }

  const toggleActive = () => {
    setActive(!active);
  };

  return (
    <div className="px-28 py-20 mx-auto lg:px-16 md:px-12 sm:px-6 sm:py-0 mt-10">
      <div className="bg-white rounded-card md:shadow-none md:border-none sm:mt-16 sm:shadow-none sm:border-none">
        <div className="flex justify-center px-6 pt-4">
          <div
            onClick={() => toggleActive()}
            className={`${
              active === true ? "border-blue-500" : "text-gray-400"
            } text-center py-4 font-semibold cursor-pointer w-1/5 border-b-4 md:py-2 sm:py-2 sm:w-2/5`}
          >
            Översikt
          </div>
          <div
            onClick={() => toggleActive()}
            className={`${
              active === true ? "text-gray-400" : "border-blue-500"
            } text-center py-4 font-semibold cursor-pointer w-1/5 border-b-4 md:py-2 sm:py-2 sm:w-2/5`}
          >
            Detaljerat
          </div>
        </div>
        <div className="w-full px-10 pt-10 pb-20 lg:px-6 lg:pb-14 lg:pt-6 md:px-0 sm:pb-10 sm:pt-2 sm:px-0">
          {active === true ? (
            <div className="flex justify-between flex-wrap mt-5 sm:flex-col md:mt-0 sm:mt-8">
              <ReportBanner />
              <ReportResults
                individual={userdata.profile_data.users_surveys.INDIVIDUAL.values}
                team={userdata.profile_data.users_surveys.TEAM.values}
                organisation={userdata.profile_data.users_surveys.ORGANISATION.values}
                title={"Resultat"}
              />
              <ReportStats
                answers={userdata.profile_data.users_surveys.CHANGE.values}
                title={"Förändringströtthet"}
              />
              <ReportStats
                answers={userdata.profile_data.users_surveys.INDIVIDUAL.values}
                title={"Individuellt"}
              />
              <ReportStats
                answers={userdata.profile_data.users_surveys.TEAM.values}
                title={"Team/Grupp"}
              />
              <ReportStats
                answers={
                  userdata.profile_data.users_surveys.ORGANISATION.values
                }
                title={"Organisation"}
              />
            </div>
          ) : (
            <div className="flex flex-col mt-5 md:mt-0 sm:mt-8">
              {resultat ? (
                <>
              <div className="flex justify-between w-full px-2 sm:hidden">
                <div className="text-sm font-semibold">Fråga: </div>
                <div className="flex flex-col w-1/3">
                  <div className="text-sm font-semibold">Svar:</div>
                  <div className="flex justify-between font-semibold pr-2">
                    {answerNumber &&
                      answerNumber.map((el, idx) => (
                        <div
                          key={idx}
                          className="w-2/12 flex justify-center"
                        >
                          {el}.
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <DetailedReportBanner
                title={"Förändringströtthet"}
                questions={
                  userdata.profile_data.users_surveys.CHANGE.answers
                }
              />
              <DetailedReportBanner
                title={"Individuellt"}
                questions={
                  userdata.profile_data.users_surveys.INDIVIDUAL.answers
                }
              />
              <DetailedReportBanner
                title={"Team/Grupp"}
                questions={userdata.profile_data.users_surveys.TEAM.answers}
              />
              <DetailedReportBanner
                title={"Organisation"}
                questions={
                  userdata.profile_data.users_surveys.ORGANISATION.answers
                }
              />
               </>
              ) : (
                <div className="flex justify-center w-full px-2 sm:hidden">
                  <div className="text-sm font-semibold">
                    Inga nulägesanalyser har genomförts
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ReportPage.propTypes = {
  fetchUserData: PropTypes.func.isRequired,
  userdata: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    userdata: state.userData.userdata,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUserData: bindActionCreators(actions.fetchUserData, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
