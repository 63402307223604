import React from "react";
import PropTypes from "prop-types";
import cutText from "../../utils/cutText";
import { FiHexagon } from "react-icons/fi";

const AchievementElement = ({
  name,
  description,
  currentScore,
  maxScore,
  color,
}) => {
  return (
    <>
      <div className="w-12 h-14 mr-2 flex items-center justify-center relative">
        <FiHexagon style={{ color: color }} className="w-12 h-12 absolute" />
        <div style={{ color: color }} className="text-sm">
          {currentScore > maxScore ? maxScore : currentScore}/{maxScore}
        </div>
      </div>
      <div className="pl-2 w-full">
        <h1 className="font-semibold text-sm">{name}</h1>
        <p className="text-xs md:hidden">{description}</p>
        <p className="hidden md:inline-block text-xs">{cutText(description, 40)}</p>
      </div>
    </>
  );
};

AchievementElement.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  currentScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default AchievementElement;
