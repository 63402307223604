import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../redux/actions/authActions";
import LoginForm from "../components/forms/LoginForm";

const LoginPageMobile = ({ login }) => {
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const onSubmit = (data) => {
    setErrors(false);
    setLoading(true);
    login(data.email, data.password)
      .catch(() => {
        setErrors(true);
        setLoading(false);
      })
      .then(() => {
        history.push("/");
      });
  };

  return (
    <div className="w-full h-screen pt-20 bg-black px-6 flex justify-evenly flex-col">
      <div className="logo-gradient text-6xl font-bold">
        Läs.
        <br />
        Lyssna.
        <br />
        Titta.
      </div>
      <LoginForm
        onSubmit={onSubmit}
      />
      <div className="text-gray-500 text-center">
        Har du inget konto?{" "}
        <Link to="/registrera">
          <button className="text-gray-100">Registrera</button>
        </Link>
      </div>
      <div className="h-10">
        {errors && (
          <div className="mt-1 text-center text-red-500 text-sm">
            Fel e-postadress eller lösenord
          </div>
        )}
        {loading && <div className="mt-1 text-center text-sm text-white">Läser in...</div>}
      </div>
    </div>
  );
};

LoginPageMobile.propTypes = {
  login: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(authActions.login, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LoginPageMobile);
