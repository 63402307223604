import React, { useState, useRef } from "react";
import Modal from "react-modal";
import Footer from "../components/Footer";
import RegisterCompanyModal from "../components/modals/RegisterCompanyModal";
import LoginModal from "../components/modals/LoginModal";
import LandingWelcomeBanner from "../components/LandingWelcomeBanner";
import CoursesLoggedOutBanner from "../components/CoursesLoggedOutBanner";
import IndividualCoursesList from "../components/IndividualCoursesList";
import GroupCoursesList from "../components/GroupCoursesList";
import OrganizationCoursesList from "../components/OrganizationCoursesList";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const modalStyles = {
  content: {
    padding: "0",
    height: "80%",
    width: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const CoursesLoggedOutPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setLoginModal(false);
  };

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <>
      <div className="w-full">
        <LandingWelcomeBanner
          openModal={openModal}
          executeScroll={executeScroll}
          title="har teman inom en rad olika områden och nivåer."
          description="Våra teman anpassar oss efter dig. Samtliga teman går att se på,
          lyssna på eller läsa. Scrolla ner för att se några av våra
          tillgängliga teman."
          buttonName="Bli medlem"
        />
        <CoursesLoggedOutBanner />
        <div
          className="px-28 ld:px-16 md:px-12 sm:px-0 sm:pb-0"
          ref={myRef}
        >
          <h2 className="max-w-8xl mx-auto text-3xl font-bold sm:pl-6 py-2 sm:py-6">
            Några av våra teman.
          </h2>
          <p className="max-w-8xl mx-auto sm:pl-6">
            Se vårt utbud av teman. Här kan du se vilka teman vi har inom tre
            olika kategorier.
          </p>
          <IndividualCoursesList />
          <GroupCoursesList />
          <OrganizationCoursesList />
        </div>
        <Modal isOpen={modalIsOpen} style={modalStyles}>
          <RegisterCompanyModal closeModal={closeModal} />
        </Modal>
        <Modal isOpen={loginModal} style={modalStyles}>
          <LoginModal closeModal={closeModal} />
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default CoursesLoggedOutPage;
