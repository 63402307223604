import React from "react";
import PropTypes from "prop-types";
import { FormFieldRequiredError } from "./FormFieldError";

const NewFormInput = ({ id, name, type = "text", register, validation, errors }) => {
  return (
    <>
      <label htmlFor={id} className="sr-only">
        { name }
      </label>
      <input
        type={type}
        id={id}
        className="form-control mb-4 rounded  py-2 px-3"
        placeholder={name}
        {...register(id, validation)}
      />
      {errors[id]?.type === "required" && <FormFieldRequiredError />}
    </>
  );
};

NewFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  type: PropTypes.string,
  validation: PropTypes.object,
  errors: PropTypes.object
};

export default NewFormInput;
