import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as readingActions } from "../redux/actions/readingCourseActions";
import { actions as coursesActions } from "../redux/actions/coursesActions";
import { ImArrowRight2 } from "react-icons/im";
import isEmptyObject from "../utils/isEmptyObject";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import CourseIntroBanner from "../components/CourseIntroBanner";
import BackgroundImage from "../components/BackgroundImage";
import ReadingSingleChapter from "../components/ReadingSingleChapter";

import image from "../assets/example-reading.png";
import imageMobile from "../assets/example-reading-mobile.png";
import bgImage from "../assets/bg-reading-page.png";
import bgImageRwd from "../assets/bg-rwd-reading.png";

const ReadingPage = ({
  fetchReadingCourse,
  fetchCourseDetails,
  resetReadingDetails,
  readingDetails,
  courseDetails,
}) => {
  const [errorReadingDetailsOccured, setErrorReadingDetailsOccured] =
    useState(false);
  const [errorCourseDetailsOccured, setErrorCourseDetailsOccured] =
    useState(false);
  let { title } = useParams();

  useEffect(() => {
    setErrorReadingDetailsOccured(false);
    fetchReadingCourse(title).catch(() => {
      setErrorReadingDetailsOccured(true);
    });
    return () => {
      resetReadingDetails();
    };
  }, [fetchReadingCourse]);

  useEffect(() => {
    if (!isEmptyObject(courseDetails)) {
      return;
    }
    setErrorCourseDetailsOccured(false);
    fetchCourseDetails(title).catch(() => {
      setErrorCourseDetailsOccured(true);
    });
  }, [fetchCourseDetails]);

  if (isEmptyObject(readingDetails) || isEmptyObject(courseDetails)) {
    return <LoadingIndicator />;
  }
  if (errorReadingDetailsOccured || errorCourseDetailsOccured) {
    return <ErrorOccurred />;
  }

  return (
    <div className="w-full -z-10">
      <BackgroundImage title={courseDetails.title} slug={courseDetails.slug} image={image} imageMobile={imageMobile} type="text" category={courseDetails.category} />
      <CourseIntroBanner
        courseDetails={courseDetails}
        isReadingPage="isReadingPage"
        title="Läsa"
      />
      <div className="flex w-full justify-evenly py-20 flex-wrap relative sm:py-6 md:px-20 md:py-10">
        <img
          alt="background-image"
          className="w-full absolute -z-20 object-contain -top-44 sm:hidden md:top-0"
          src={bgImage}
        />
        <img
          alt="background-image"
          className="w-full absolute -z-20 object-contain bottom-0 sm:inline-block hidden"
          src={bgImageRwd}
        />
        {readingDetails &&
          readingDetails.map((el, idx) => (
            <ReadingSingleChapter
              key={el.id}
              idx={idx}
              name={el.name}
              title={title}
              id={el.id}
            />
          ))}
      </div>
      <div className="px-20">
        {courseDetails.quiz_enabled && (
          <div className="flex bg-yellow-500 shadow-accent max-w-md h-36 p-4 flex-col justify-around mb-4 rounded-lg mx-auto">
            <div className="bg-white text-yellow-500 rounded-md flex justify-center items-center w-1/6">
              Quiz
            </div>
            <div className="font-bold text-white">
              Är du klar? Sätt igång quizet nu!
            </div>
            <Link
              to={`/tema/${title}/quiz`}
              className="text-white font-bold flex items-center sm:self-end sm:text-sm"
            >
              Starta Quiz
              <ImArrowRight2 className="ml-2" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

ReadingPage.propTypes = {
  fetchReadingCourse: PropTypes.func.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  resetReadingDetails: PropTypes.func.isRequired,
  readingDetails: PropTypes.array.isRequired,
  courseDetails: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    readingDetails: state.readingCourse.readingCourse,
    courseDetails: state.courses.courseDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchReadingCourse: bindActionCreators(
      readingActions.fetchReadingCourse,
      dispatch
    ),
    fetchCourseDetails: bindActionCreators(
      coursesActions.fetchCourseDetails,
      dispatch
    ),
    resetReadingDetails: bindActionCreators(
      readingActions.resetReadingDetails,
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadingPage);
