import React, { useState } from "react";
import PropTypes from "prop-types";
import { GrFormClose } from "react-icons/gr";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../../redux/actions/authActions";
import { actions as userActions } from "../../redux/actions/userActions";
import logo from "../../assets/login-background.png";
import LoginForm from "../forms/LoginForm";
import RequestResetPasswordForm from "../forms/RequestResetPasswordForm";

const LoginModal = ({ login, closeModal, sendResetPasswordEmail, openRegisterModal }) => {
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    setErrors(false);
    setLoading(true);
    login(data.email, data.password).catch(() => {
      setErrors(true);
      setLoading(false);
    });
  };

  const onSubmitResetPassword = (data) => {
    sendResetPasswordEmail(data.email)
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        setErrors(e);
      });
  };

  const toggleResetPasswordForm = () => {
    setShowReset(!showReset);
  };

  return (
    <div className="w-full h-full bg-white flex">
      <div className="h-full w-1/2 relative flex justify-center md:hidden sm:hidden">
        <img
          className="h-full object-cover w-full"
          src={logo}
          alt="logo"
        />
        <h1 className="text-white absolute bottom-5 text-6xl md:text-3xl">
          Learn<span className="font-bold">Flow</span>
          <sup className="text-4xl">®</sup>
        </h1>
      </div>
      <div className="w-1/2 flex flex-col flex-grow px-14 py-10 relative justify-evenly lg:px-6 md:w-2/3 md:px-12 sm:py-2 sm:w-full sm:px-6">
        <div
          onClick={closeModal}
          className="absolute top-10 right-10 cursor-pointer h-md:top-7 h-md:right-7 sm:top-2 sm:right-2"
        >
          <GrFormClose className="w-6 h-6" />
        </div>
        <h1 className="font-semibold text-3xl text-blue-500 h-md:text-2xl sm:text-xl">
          {showReset ? "Återställ lösenord" : "Logga in"}
        </h1>
        <p className="text-sm py-5 sm:py-1 h-md:py-1">
          {showReset ? (
            "Ange din e-postadress för att söka efter ditt konto."
           ) : (
            "Logga in med dem uppgifterna du angav när du skapade konto"
           )}
        </p>
        {showReset ? (
          <RequestResetPasswordForm
            success={success}
            onSubmit={onSubmitResetPassword}
            toggleResetPasswordForm={toggleResetPasswordForm}
          />
        ) : (
          <LoginForm
            onSubmit={onSubmit}
            toggleResetPasswordForm={toggleResetPasswordForm}
          />
        )}
        <div className="h-10">
          {errors && (
            <div className="mt-1 text-center text-red-500 text-sm">
              Fel e-postadress eller lösenord
            </div>
          )}
          {loading && (
            <div className="mt-1 text-center text-sm">Läser in...</div>
          )}
        </div>
        <div className="rounded border border-gray-400 px-9 py-8 h-md:px-5 h-md:py-4 sm:px-5 sm:py-4">
          <h1 className="font-semibold text-xl text-blue-500 h-md:text-base sm:text-base">
            Registrera företag
          </h1>
          <p className="text-base text-gray-600 py-4 mb-6 h-md:mb-0 sm:mb-0 h-md:text-sm sm:text-sm">
            Är ni inte medlemmar? Registrera ditt företag idag och bjud in dina
            kollegor.
          </p>
          <button
            className="bg-gray-400 text-white w-full rounded font-semibold py-2 sm:py-1 h-md:py-1 sm:text-sm h-md:text-sm"
            onClick={openRegisterModal}
          >
            Registrera dig
          </button>
        </div>
      </div>
    </div>
  );
};

LoginModal.propTypes = {
  login: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendResetPasswordEmail: PropTypes.func.isRequired,
  openRegisterModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(authActions.login, dispatch),
    sendResetPasswordEmail: bindActionCreators(userActions.sendResetPasswordEmail, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LoginModal);
