import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as popularCoursesActions } from "../redux/actions/popularCoursesActions";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import CoursesCarousel from "../components/CoursesCarousel";
import Footer from "../components/Footer";
import CoursesCarouselMobile from "../components/CoursesCarouselMobile";
import arrow from "../assets/black-arrow.svg";
import cutText from "../utils/cutText";
import Breadcrumbs from "../components/Breadcrumbs";

const GroupCoursesPage = ({ fetchGroupCourses, groupCourses }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setErrorOccurred(false);
    fetchGroupCourses()
      .catch(() => {
        setErrorOccurred(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchGroupCourses]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <>
      <div className="pt-8">
        <div className="pt-16 sm:hidden mx-auto pl-28 lg:pl-6 md:pl-6 sm:pl-0">
          <Breadcrumbs courseCategory={2} />
          <div className="pb-5 font-bold text-3xl pr-28 md:text-2xl md:pt-3 sm:text-2xl text-center">
            Teman på Gruppnivå
          </div>
          <CoursesCarousel courses={groupCourses} />
        </div>
        <div className="hidden sm:block">
          <CoursesCarouselMobile courses={groupCourses} />
        </div>
        <div className="px-28 lg:px-6 md:px-6 sm:px-0">
          <div>
            <div className="max-w-8xl mx-auto pt-6 font-semibold text-2xl flex items-center">
              <span className="h-1 w-4 bg-blue-600 mr-2"></span>
              Alla teman på Gruppnivå
            </div>
            <div className="max-w-8xl m-auto py-6 flex flex-wrap justify-between md:justify-center md:flex-col sm:flex-col sm:bg-gray-100">
              {groupCourses &&
                groupCourses?.map((el) => {
                  const url = "/tema/" + encodeURIComponent(el.slug);
                  return (
                    <div
                      className="flex mb-10 w-48per md:justify-center md:items-center md:w-full sm:items-center sm:w-full sm:px-6"
                      key={el.id}
                    >
                      <Link className="flex" to={url}>
                        <div className="bg-gray-300 rounded w-36 h-40 mr-4">
                          <img
                            className="rounded-lg object-cover h-full w-full"
                            src={el.image}
                          />
                        </div>
                        <div className="flex flex-col flex-grow h-full justify-between max-w-sm w-2/3 md:w-3/4 md:max-w-none sm:max-w-none sm:w-1/2">
                          <h3 className="font-bold text-lg md:text-lg">
                            {el.title}
                          </h3>
                          <div className="lg:text-sm md:py-2 sm:py-2 sm:text-xs">
                            {cutText(el.description, 180)}
                          </div>
                          <div className="underline flex cursor-pointer md:text-sm">
                            Läs, titta eller lyssna
                            <img className="ml-2 w-3.5" src={arrow} />
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

GroupCoursesPage.propTypes = {
  fetchGroupCourses: PropTypes.func.isRequired,
  groupCourses: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    groupCourses: state.popularCourses.groupCourses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGroupCourses: bindActionCreators(
      popularCoursesActions.fetchGroupCourses,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupCoursesPage);
