import React from "react";
import { Link } from "react-router-dom";

import background from "../assets/landing-bg.svg";
import backgroundMobile from "../assets/mobile-bg-landing.png";

const GoToSurveyPage = () => {
  return (
    <div className="w-full h-screen">
      <div className="bg-black bg-landingpage text-white flex items-center px-28 h-full md:px-12 sm:bg-transparent sm:text-black sm:flex-col sm:px-0 sm:relative sm:h-full sm:justify-end sm:pb-10">
        <div className="hidden w-full sm:flex justify-center items-center absolute -z-20 -top-14">
          <img src={backgroundMobile} className="w-full" />
        </div>
        <div className="w-1/2 md:pr-6 sm:w-full sm:px-6">
          <div className="text-6xl font-semibold max-w-lg md:text-4xl sm:hidden">
            Välkommen till LearnFlow!
          </div>
          <div className="hidden sm:inline-block text-center w-full text-4xl font-bold">Innan vi börjar</div>
          <div className="text-xl max-w-md py-10 md:text-base md:max-w-xs sm:text-center sm:text-sm sm:py-6 sm:mx-auto">
            Vill vi att du genomför en nulägesanalys. Detta för att vi ska kunna
            rekommendera teman som passar just er!{" "}
            <div className="pt-4">
              Testet tar ca{" "}
              <span className="underline text-blue-500">10 minuter</span> att
              genomföra
            </div>
          </div>
          <Link
            className="bg-blue-600 rounded-lg flex items-center h-12 w-64 justify-center md:h-10 md:w-48 md:rounded sm:text-white sm:mx-auto sm:w-11/12"
            to="/enkat"
          >
            Påbörja test
          </Link>
        </div>
        <div className="w-1/2 flex justify-center items-center h-full sm:hidden">
          <img src={background} className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default GoToSurveyPage;
