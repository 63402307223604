import React from "react";
import PropTypes from "prop-types";
import { ImArrowRight2 } from "react-icons/im";
import { FaArrowDown } from "react-icons/fa";

import background from "../assets/landing-bg.svg";

const LandingWelcomeBanner = ({ openModal, executeScroll, title, description, buttonName, image }) => {

  return (
      <div className="h-screen bg-blacktext-white flex items-center bg-black">
        <div className="w-1/2 px-28 text-white lg:px-16 md:px-12 sm:w-full sm:px-6 sm:h-full">
          <div className="text-5xl md:text-4xl sm:hidden">
            <span className="sm:text-blue-400">
              Learn<span className="font-semibold">Flow</span>
            </span>{" "}
            {title}
          </div>
          <div className="text-3xl sm:flex flex-col hidden h-2/5 justify-end">
            <span className="logo-gradient text-5xl">
              Learn<span className="font-semibold">Flow</span>
            </span> {title}
          </div>
          <div
          className="text-xl max-w-md py-10 md:text-lg sm:text-base sm:py-6 sm:h-1/3"
          dangerouslySetInnerHTML={{ __html: description }}
          >
          </div>
          <button
            className="border-2 border-white rounded-lg flex items-center justify-between h-12 w-64 sm:hidden"
            onClick={openModal}
          >
            <div className="w-1/4 landing-button-gradient h-full flex justify-center items-center rounded-tl-md rounded-bl-md">
              <ImArrowRight2 className="w-20 h-6" />
            </div>
            <div className="w-3/4 flex justify-center items-center">
              {buttonName}
            </div>
          </button>
          <div className="h-1/4 py-10 items-end hidden sm:flex" onClick={executeScroll}>
            <button className="flex bg-gradient rounded-full w-10 h-10 justify-center items-center mx-auto outline-none">
              <FaArrowDown className="h-6 w-6 font-bold" />
            </button>
          </div>
        </div>
        <div className="w-1/2 flex justify-center items-center h-full pr-28 lg:pr-16 lg:justify-end md:pr-12 sm:hidden">
          <img src={image ? image : background} className="h-3/5 lg:w-11/12 object-contain" />
        </div>
      </div>
  );
};

LandingWelcomeBanner.propTypes = {
  openModal: PropTypes.func.isRequired,
  executeScroll: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default LandingWelcomeBanner;
