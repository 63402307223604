import React from "react";
import { Link } from "react-router-dom";

const Reports = () => {
  return (
    <div className="w-48per bg-white rounded-card flex flex-col justify-center  p-6 relative sm:w-full sm:mb-4">
      <h1 className="font-semibold text-2xl text-center sm:text-xl sm:w-11/12 sm:mx-auto sm:pb-2">
        Ladda ner och läs rapporter från LearnFlow
      </h1>
      <p className="text-sm text-center text-gray-500 pb-5 sm:w-8/12 sm:mx-auto">
        Läs rapporter från LearnFlow genom att klicka på knappen.
      </p>
      <Link to="/rapporter" className="underline font-semibold py-2 m-auto">
        Se rapport
      </Link>
    </div>
  );
};

export default Reports;
