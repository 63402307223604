import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as audioActions } from "../redux/actions/audioCourseActions";
import { actions as coursesActions } from "../redux/actions/coursesActions";
import isEmptyObject from "../utils/isEmptyObject";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import CourseIntroBanner from "../components/CourseIntroBanner";
import AudioSingleChapter from "../components/AudioSingleChapter";
import AudioSingleChapterVimeo from "../components/AudioSingleChapterVimeo";
import BackgroundImage from "../components/BackgroundImage";

import image from "../assets/example-audio.png";
import imageMobile from "../assets/example-audio-mobile.png";
import bgImage from "../assets/bg-audio-page.png";
import { ImArrowRight2 } from "react-icons/im";

const AudioPage = ({
  fetchAudioCourse,
  fetchCourseDetails,
  resetAudioDetails,
  audioDetails,
  courseDetails,
}) => {
  const [errorAudioDetailsOccurred, setErrorAudioDetailsOccurred] =
    useState(false);
  const [errorCourseDetailsOccurred, setErrorCourseDetailsOccurred] =
    useState(false);
  const [activeSound, setActiveSound] = useState(null);
  const [autoplay, setAutoPlay] = useState(false);
  const [totalTracks, setTotalTracks] = useState(0);
  let { title } = useParams();

  useEffect(() => {
    setErrorAudioDetailsOccurred(false);
    fetchAudioCourse(title).catch(() => {
      setErrorAudioDetailsOccurred(true);
    });
    return () => {
      resetAudioDetails();
    };
  }, [fetchAudioCourse]);

  useEffect(() => {
    if (!isEmptyObject(courseDetails)) {
      return;
    }
    setErrorCourseDetailsOccurred(false);
    fetchCourseDetails(title).catch(() => {
      setErrorCourseDetailsOccurred(true);
    });
  }, [fetchCourseDetails]);

  useEffect(() => {
    setTotalTracks(audioDetails.length);
  }, [setTotalTracks, audioDetails]);

  const handleAudioEnd = (currentIndex) => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < audioDetails.length) {
      setAutoPlay(true);
      setActiveSound(nextIndex);
    } else {
      setActiveSound(null);
    }
  };

  if (isEmptyObject(audioDetails) || isEmptyObject(courseDetails)) {
    return <LoadingIndicator />;
  }
  if (errorAudioDetailsOccurred || errorCourseDetailsOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <div className="w-full">
      <BackgroundImage
        title={courseDetails.title}
        slug={courseDetails.slug}
        image={image}
        imageMobile={imageMobile}
        type="audio"
        category={courseDetails.category}
      />
      <CourseIntroBanner courseDetails={courseDetails} title="Lyssna" />
      <img
        className="w-full absolute -z-20 object-contain sm:hidden"
        src={bgImage}
      />
      <div className="flex flex-col justify-evenly w-3/4 py-16 mx-auto md:w-10/12 md:py-10 sm:w-full sm:p-6 bg-audio-mobile">
        <div className="flex border-b border-gray-300 px-3 py-2 items-center sm:hidden">
          <div className="font-bold text-2xl">Avsnitt</div>
          <div className="text-gray-500 ml-4">{totalTracks} avsnitt</div>
        </div>
        {audioDetails?.map((el, idx) => {
          if (el.vimeo_url === null) {
            return (
              <AudioSingleChapter
                key={el.id}
                id={el.id}
                idx={idx}
                name={el.name}
                content={el.content}
                audio={el.stream}
                active={activeSound}
                setActive={setActiveSound}
                autoplay={autoplay}
                setAutoPlay={setAutoPlay}
                onEnd={() => handleAudioEnd(idx)}
              />
            );
          }
            return (
              <AudioSingleChapterVimeo
                key={el.id}
                id={el.id}
                idx={idx}
                name={el.name}
                content={el.content}
                audio={el.vimeo_url}
                active={activeSound}
                setActive={setActiveSound}
                autoplay={autoplay}
                onEnd={() => handleAudioEnd(idx)}
              />
            );

        }
          )}
      </div>
      <div className="w-3/4 mx-auto pb-10">
        {courseDetails.quiz_enabled && (
          <div className="flex bg-green-500 shadow-accent mx-auto max-w-md h-36 p-4 flex-col justify-around mb-4 rounded-lg">
            <div className="bg-white text-green-500 rounded-md flex justify-center items-center w-1/6">
              Quiz
            </div>
            <div className="font-bold text-white">
              Är du klar? Sätt igång quizet nu!
            </div>
            <Link
              to={`/tema/${title}/quiz`}
              className="text-white font-bold flex items-center sm:self-end sm:text-sm"
            >
              Starta Quiz
              <ImArrowRight2 className="ml-2" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

AudioPage.propTypes = {
  fetchAudioCourse: PropTypes.func.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  resetAudioDetails: PropTypes.func.isRequired,
  audioDetails: PropTypes.array.isRequired,
  courseDetails: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    audioDetails: state.audioCourse.audioCourse,
    resetAudioDetails: state.audioCourse.resetAudioDetails,
    courseDetails: state.courses.courseDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAudioCourse: bindActionCreators(
      audioActions.fetchAudioCourse,
      dispatch
    ),
    fetchCourseDetails: bindActionCreators(
      coursesActions.fetchCourseDetails,
      dispatch
    ),
    resetAudioDetails: bindActionCreators(
      audioActions.resetAudioDetails,
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioPage);
