import React from "react";
import PropTypes from "prop-types";

const DetailedReportBanner = ({ title, questions }) => {
  const mobileAnswers = [1, 2, 3, 4, 5, 6];
  return (
    <div className="w-full flex flex-col mb-6">
      <div className="font-semibold pl-2 sm:pl-0">{title}</div>
      <div className="shadow-accent rounded-lg border border-gray-100 px-4 sm:shadow-none sm:border-none sm:px-0">
        {questions &&
          questions.map((el, idx) => (
            <div key={idx} className="flex justify-between py-4 sm:flex-col sm:shadow-accent sm:border sm:border-gray-100 sm:px-4 sm:mb-4">
              <div className="w-2/3 pr-4 md:text-sm sm:w-full sm:pr-0 sm:pb-4">{el.name}</div>
              <div className="hidden sm:flex justify-between w-full px-2">
                {mobileAnswers &&
                  mobileAnswers.map((element, elementIdx) => (
                    <div className="font-semibold w-2/12" key={elementIdx}>{element}.</div>
                  ))}
              </div>
              <div className="flex w-1/3 justify-between sm:w-full">
                {el.answers &&
                  el.answers.map((elem, elemIdx) => (
                    <div className="font-semibold w-2/12 flex justify-center" key={elemIdx}>{elem}%</div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

DetailedReportBanner.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
};

export default DetailedReportBanner;
