import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoIosHelpCircleOutline } from "react-icons/io";

const ToolTipIcon = ({
  text,
  icon,
  align,
  style,
}) => {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  return (
    <div
      className={`text-3xl w-fit h-fit w-fit absolute opacity-70 hover:opacity-100 ${style} z-30`}
      onMouseEnter={toggleActive}
      onMouseLeave={toggleActive}
      onClick={toggleActive}
    >
      {icon ? icon : <IoIosHelpCircleOutline/>}
      {active &&
       <div
        className={`absolute bg-black text-white rounded-md shadow-lg p-2 text-sm text-left top-8
         ${align === "left" ? "right-6" : "left-6"}
        z-20 w-48 text-black drop-shadow-xl shadow-xl opacity-90`}
        onMouseEnter={toggleActive}
        onClick={toggleActive}
      >
        {text}
      </div>
      }
    </div>
  );
};

ToolTipIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  align: PropTypes.string,
  style: PropTypes.string,
};


export default ToolTipIcon;
