import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ImArrowRight2 } from "react-icons/im";

const ReadingSingleChapter = ({ id, idx, name, title }) => {
  return (
    <div className="bg-white shadow-accent w-56 h-44 p-4 flex flex-col justify-around mb-4 sm:h-36 sm:rounded-lg md:m-4 sm:w-11/12">
      <div className="bg-yellow-500 text-white rounded-md flex justify-center items-center w-1/2 sm:w-1/3">
        Kapitel {idx + 1}
      </div>
      <div className="font-bold">{name}</div>
      <Link
        to={`/tema/${title}/lasning/kapitel/${id}`}
        className="text-yellow-500 font-bold flex items-center sm:self-end sm:text-sm"
      >
        Läs
        <ImArrowRight2 className="ml-2" />
      </Link>
    </div>
  );
};

ReadingSingleChapter.propTypes = {
  id: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ReadingSingleChapter;
