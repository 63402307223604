import React from "react";
import PropTypes from "prop-types";
import FormRenderer from "./FormRenderer";

const LoginForm = ({ onSubmit, toggleResetPasswordForm }) => {

  const fields = [
    {
      fieldName: "email",
      placeholder: "E-postadress",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "password",
      placeholder: "Lösenord",
      type: "password",
      validation: {
        required: true,
      },
    },
  ];

  const bottom = (
    <>
      <p className="text-right pb-6 cursor-pointer" onClick={() => toggleResetPasswordForm()}>Glömt ditt lösenord?</p>
      <button type="submit" className="bg-blue-600 text-white w-full rounded font-semibold py-3 h-md:mb-3 sm:py-2 h-md:py-2">
        Logga in
      </button>
    </>
  );

  return (
    <FormRenderer submit={onSubmit} fields={fields} bottom={bottom} />
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleResetPasswordForm: PropTypes.func.isRequired,
};

export default LoginForm;
