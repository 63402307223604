import React from "react";
import PropTypes from "prop-types";
import headphones from "../assets/headphones-green.svg";
import headphonesWhite from "../assets/headphones-icon.svg";

const AudioIcon = ({ type }) => {
  return (
    <>
      {type === "gradient" && (
        <div className="bg-gradient-green rounded-lg w-10 h-10 flex items-center justify-center">
          <img src={headphonesWhite} alt="" />
        </div>
      )}
      {type === "faded" && (
        <div className="bg-green-200 rounded-lg w-10 h-10 flex items-center justify-center">
          <img src={headphones} alt="" />
        </div>
      )}
      {type === "color" && (
        <div className="bg-green-500 rounded-lg w-10 h-10 flex items-center justify-center md:w-9 md:h-9">
          <img src={headphonesWhite} className="md:w-5 md:h-5" />
        </div>
      )}
      {type === "landing" && (
        <div className="bg-button-gradient rounded-lg w-10 h-10 flex items-center justify-center md:w-9 md:h-9">
          <img src={headphonesWhite} className="md:w-5 md:h-5" />
        </div>
      )}
    </>
  );
};

AudioIcon.propTypes = {
  type: PropTypes.string,
};

export default AudioIcon;
