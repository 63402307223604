export const PAGE_DATA_REQUEST = "PAGE_DATA_REQUEST";
export const PAGE_DATA_SUCCESS = "PAGE_DATA_SUCCESS";
export const PAGE_DATA_FAILURE = "PAGE_DATA_FAILURE";
export const HOMEPAGE_DATA_REQUEST = "HOMEPAGE_DATA_REQUEST";
export const HOMEPAGE_DATA_SUCCESS = "HOMEPAGE_DATA_SUCCESS";
export const HOMEPAGE_DATA_FAILURE = "HOMEPAGE_DATA_FAILURE";
export const SEO_DATA_REQUEST = "SEO_DATA_REQUEST";
export const SEO_DATA_SUCCESS = "SEO_DATA_SUCCESS";
export const SEO_DATA_FAILURE = "SEO_DATA_FAILURE";

const fetchPageDetails = (slug) => ({
  types: [PAGE_DATA_REQUEST, PAGE_DATA_SUCCESS, PAGE_DATA_FAILURE],
  endpoint: `page/${slug}`,
  method: "GET",
});

const fetchHomePageDetails = () => ({
  types: [HOMEPAGE_DATA_REQUEST, HOMEPAGE_DATA_SUCCESS, HOMEPAGE_DATA_FAILURE],
  endpoint: "homepage/",
  method: "GET",
});

const fetchSEODetails = () => ({
  types: [SEO_DATA_REQUEST, SEO_DATA_SUCCESS, SEO_DATA_FAILURE],
  endpoint: "seo/",
  method: "GET",
});

export const actions = {
  fetchPageDetails,
  fetchHomePageDetails,
  fetchSEODetails,
};

const ACTION_HANDLERS = {
  [PAGE_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    pageDetails: payload,
  }),
  [HOMEPAGE_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    homePageDetails: payload,
  }),
  [SEO_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    seoDetails: payload,
  }),
};

export const initialState = {
  pageDetails: {},
  homePageDetails: {},
  seoDetails: {},
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
