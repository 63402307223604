import React from "react";
import PropTypes from "prop-types";
import film from "../assets/film-blue.svg";
import filmWhite from "../assets/film-icon.svg";

const VideoIcon = ({ type }) => {
  return (
    <>
      {type === "gradient" && (
        <div className="bg-gradient-blue rounded-lg w-10 h-10 flex items-center justify-center">
          <img src={filmWhite} alt="" />
        </div>
      )}
      {type === "faded" && (
        <div className="bg-blue-200 rounded-lg w-10 h-10 flex items-center justify-center">
          <img src={film} alt="" />
        </div>
      )}
      {type === "color" && (
        <div className="bg-blue-500 rounded-lg w-10 h-10 flex items-center justify-center md:w-9 md:h-9">
          <img src={filmWhite} className="md:w-5 md:h-5" />
        </div>
      )}
      {type === "landing" && (
        <div className="bg-button-gradient rounded-lg w-10 h-10 flex items-center justify-center md:w-9 md:h-9">
          <img src={filmWhite} className="md:w-5 md:h-5" />
        </div>
      )}
    </>
  );
};

VideoIcon.propTypes = {
    type: PropTypes.string,
};

export default VideoIcon;
