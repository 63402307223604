import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import LoginModal from "./modals/LoginModal";
import RegisterCompanyModal from "./modals/RegisterCompanyModal";
import { VscMenu, VscChromeClose } from "react-icons/vsc";

const modalStyles = {
  content: {
    padding: 0,
    height: "80%",
    width: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "hsl(0deg 0% 0% / 75%)",
  },
};

const TopBarMenuLanding = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [register, setRegister] = useState(false);

  const openMenu = () => {
    setActive(true);
  };

  const closeMenu = () => {
    setActive(false);
  };

  const openModal = () => {
    setIsOpen(true);
    setActive(false);
  };

  const openRegisterModal = () => {
    setRegister(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setRegister(false);
  };

  return (
    <div className="w-full flex justify-center"
    >
    <div
      className="sm:w-full sm:py-4 sm:rounded-none w-fit rounded-menu bg-white flex justify-between py-2 items-center px-14 z-10
       fixed sm:bg-black md:px-12 sm:px-6 sm:m-0 mt-5 sm:text-white text-black space-x-5 whitespace-nowrap"
    >
      <Link to="/">
        <h1 className="bottom-5 text-2xl sm:text-lg">
          Learn<span className="font-bold">Flow</span>
          <sup className="text-xl">®</sup>
        </h1>
      </Link>
      <div className="flex w-2/5 justify-evenly h-1/2 sm:hidden">
        <Link to="/teman">
          Teman
        </Link>
      </div>
      <button
        to="/login"
        className="px-3 sm:hidden"
        onClick={openModal}
      >
        Logga in
      </button>
      <div className="hidden sm:block">
        {active === false ? (
          <VscMenu className="text-white w-8 h-6" onClick={openMenu} />
        ) : (
          <VscChromeClose className="text-white w-8 h-6" onClick={closeMenu}/>
        )}
        <div
          className={`${
            active === true ? "h-36" : "h-0 transition-all"
          } bg-black overflow-hidden absolute top-11 right-0 flex flex-col items-center justify-around w-44 px-4 transition-all`}
        >
          <Link
            to="/teman"
            className="border-b border-white h-1/2 flex items-center justify-center w-full"
            onClick={closeMenu}
          >
            Teman
          </Link>
          <Link
            to="/login"
            className="h-1/2 flex items-center justify-center w-full"
            onClick={closeMenu}
          >
            Logga in
          </Link>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} style={modalStyles}>
        {register ? <RegisterCompanyModal closeModal={closeModal} /> : <LoginModal closeModal={closeModal} openRegisterModal={openRegisterModal} />}
      </Modal>
    </div>
    </div>
  );
};

export default TopBarMenuLanding;
