import React from "react";
import PropTypes from "prop-types";
import AchievementElement from "./AchievementElement";

const Achievement = ({
  name,
  description,
  status,
  currentScore,
  maxScore,
  color,
}) => {
  return (
    <>
      {status ? (
        <div
          className="opacity-100 border-b border-gray-300 py-2 flex justify-start items-center px-2 w-48per sm:w-full sm:border-0"
        >
          <AchievementElement name={name} description={description} currentScore={currentScore} maxScore={maxScore} color={color}/>
        </div>
      ) : (
        <div
          className="opacity-70 border-b border-gray-300 py-2 flex justify-start items-center px-2 w-48per sm:w-full sm:border-0"
        >
          <AchievementElement name={name} description={description} currentScore={currentScore} maxScore={maxScore} color={color}/>
        </div>
      )}
    </>
  );
};

Achievement.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  currentScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Achievement;
