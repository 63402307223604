import React from "react";
import PropTypes from "prop-types";
import book from "../assets/book-orange.svg";
import bookWhite from "../assets/book-icon.svg";

const ReadingIcon = ({ type }) => {
  return (
    <>
      {type === "gradient" && (
        <div className="bg-gradient-yellow rounded-lg w-10 h-10 flex items-center justify-center">
          <img src={bookWhite} alt="" />
        </div>
      )}
      {type === "faded" && (
        <div className="bg-yellow-200 rounded-lg w-10 h-10 flex items-center justify-center">
          <img src={book} alt="" />
        </div>
      )}
      {type === "color" && (
        <div className="bg-yellow-500 rounded-lg w-10 h-10 flex items-center justify-center md:w-9 md:h-9">
          <img src={bookWhite} className="md:w-5 md:h-5" />
        </div>
      )}
      {type === "landing" && (
        <div className="bg-button-gradient rounded-lg w-10 h-10 flex items-center justify-center md:w-9 md:h-9">
          <img src={bookWhite} className="md:w-5 md:h-5" />
        </div>
      )}
    </>
  );
};

ReadingIcon.propTypes = {
  type: PropTypes.string,
};

export default ReadingIcon;
