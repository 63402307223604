import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as popularCoursesActions } from "../redux/actions/popularCoursesActions";
import cutText from "../utils/cutText";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";

const IndividualCoursesList = ({ fetchIndividualCourses, individualCourses }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setErrorOccurred(false);
    fetchIndividualCourses()
      .catch(() => {
        setErrorOccurred(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchIndividualCourses]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <div>
      <div className="max-w-8xl mx-auto pt-6 font-semibold text-2xl flex items-center sm:py-6 sm:pl-6">
        <span className="h-1 w-4 bg-blue-600 mr-2"></span>
        Individnivå
      </div>
      <div className="max-w-8xl m-auto py-6 flex flex-wrap justify-between md:justify-center md:flex-col sm:flex-col sm:bg-gray-100">
        {individualCourses &&
          individualCourses?.map((el) => {
            return (
              <div
                className="flex mb-10 w-48per md:justify-center md:items-center md:w-full sm:items-center sm:w-full sm:px-6"
                key={el.id}
              >
                <div className="bg-gray-300 rounded w-36 h-40 mr-4">
                  <img
                    className="rounded-lg object-cover h-full w-full"
                    src={el.image}
                  />
                </div>
                <div className="flex flex-col flex-grow h-full justify-start max-w-sm w-2/3 md:w-3/4 md:max-w-none sm:max-w-none sm:w-1/2">
                  <h3 className="font-bold text-lg md:text-lg mb-3">{el.title}</h3>
                  <div className="lg:text-sm md:py-2 sm:py-2 sm:text-xs">
                    {cutText(el.description, 190)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

IndividualCoursesList.propTypes = {
  fetchIndividualCourses: PropTypes.func.isRequired,
  individualCourses: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    individualCourses: state.popularCourses.individualCourses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchIndividualCourses: bindActionCreators(
      popularCoursesActions.fetchIndividualCourses,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualCoursesList);
