import React from "react";
import PropTypes from "prop-types";
import { FiHexagon } from "react-icons/fi";

const NotificationMsg = ({ achievement }) => {

  return (
    <div className="py-2 flex justify-start items-center px-2">
      <div className="flex items-center justify-center relative mr-5">
        <FiHexagon style={{ color: achievement.color }} className="w-12 h-12 absolute" />
        <div style={{ color: achievement.color }} className="text-sm">
          {achievement.target_points}/{achievement.target_points}
        </div>
      </div>
      <div className="pl-2 w-full">
        <h1 className="font-semibold text-sm">{achievement.name}</h1>
        <p className="text-xs md:hidden">{achievement.description}</p>
      </div>
    </div>
  );
};

NotificationMsg.propTypes = {
  achievement: PropTypes.object,
};

export default NotificationMsg;
