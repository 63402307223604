import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { actions as courseActions } from "../redux/actions/coursesActions";
import { connect } from "react-redux";
import { Howl } from "howler";
import { AiFillCaretRight, AiOutlinePause } from "react-icons/ai";
import useTimer from "../hooks/useTimer";
import cutText from "../utils/cutText";
import rotate from "../assets/rotate.svg";
import rotateBack from "../assets/rotate-back.svg";
import { useParams } from "react-router-dom";

function AudioSingleChapter({ idx, id, name, content, audio, setActive, submitCourseProgress, active, onEnd, autoplay, setAutoPlay }) {
  const [soundID, setSoundID] = useState();
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState();
  const { seconds, isRunning, start, pause, stop, changeTime } = useTimer();
  let { title } = useParams();
  const sound = useMemo(() => {
    return new Howl({
      src: [audio],
      html5: true,
      // eslint-disable-next-line func-names
      onplay: function (ID) {
        setSoundID(ID);
        setPlaying(true);
      },
      // eslint-disable-next-line func-names
      onend: function () {
        setPlaying(false);
        stop();
        submitCourseProgress(title, "audio", id);
        onEnd();
      },
      // eslint-disable-next-line func-names
      onpause: function () {
        setPlaying(false);
        pause();
      },
      // eslint-disable-next-line func-names
      onload: function () {
        setDuration(sound.duration());
      },
      preload: true,
    });
  }, []);

  useEffect(() => {
    const savedTime = JSON.parse(localStorage.getItem(`audioPlaybackTime-${title}-${idx}`));
    changeTime(savedTime);
    if (active !== idx) {
      savePlaybackTime();
      sound.pause(soundID);
    }

    if (active === idx && autoplay) {
      setAutoPlay(false);
      if (savedTime && savedTime != 0) {
        sound.seek(savedTime);
      }
      sound.play();
      toggleTimer();
    }
  }, [active]);

  const savePlaybackTime = () => {
    if (soundID) {
        const currentTime = sound.seek();
        changeTime(JSON.stringify(currentTime));
        localStorage.setItem(`audioPlaybackTime-${title}-${idx}`, JSON.stringify(currentTime));
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", savePlaybackTime);

    return () => {
        savePlaybackTime();
        window.removeEventListener("beforeunload", savePlaybackTime);
    };
  }, [sound, soundID, title, idx]);

  useEffect(() => {
    return () => {
      sound.stop(soundID);
      setAutoPlay(false);
    };
  }, []);

  const togglePlay = () => {
    setActive(idx);
    toggleTimer();
    const savedTime = JSON.parse(localStorage.getItem(`audioPlaybackTime-${title}-${idx}`));
    if (!soundID) {
      sound.volume(0.5);
      if (savedTime && savedTime != 0) {
          sound.seek(savedTime);
      }
      sound.play();
      return;
    }

    if (sound.playing(soundID)) {
      savePlaybackTime();
      sound.pause(soundID);
      return;
    }
    if (savedTime) {
      sound.seek(savedTime);
    }
    sound.play(soundID);
  };

  const toggleTimer = () => {
    if (!isRunning) {
      start();
      return;
    }
    pause();
  };

  const formatSoundTiming = (time) => {
    // eslint-disable-next-line newline-per-chained-call
    return new Date(time * 1000).toISOString().substr(11, 8);
  };

  const formatCurrentTiming = () => {
    return formatSoundTiming(seconds);
  };

  const addTime = () => {
    if (active !== idx || !playing) {
      return;
    }
    let newTime = seconds + 30;
    if (newTime > duration) {
      newTime = duration;
    }
    sound.seek(newTime, soundID);
    changeTime(newTime);
  };

  const substractTime = () => {
    if (active !== idx || !playing) {
      return;
    }
    let newTime = seconds - 30;
    if (newTime < 0) {
      newTime = 0;
    }
    sound.seek(newTime, soundID);
    changeTime(newTime);
  };

  return (
    <div className="flex w-full shadow-accent h-44 p-4 border border-gray-100 my-3 bg-white md:h-36 sm:flex-col sm:h-auto sm:rounded-lg">
      <div className="bg-white my-3 w-1/4 md:my-0 md:flex md:justify-between md:flex-col sm:w-full sm:my-0">
        <div className="bg-green-500 text-white rounded-md flex justify-center items-center w-24 sm:bg-transparent sm:text-gray-400 sm:justify-start sm:text-xs sm:font-semibold">
          Avsnitt {idx + 1}
        </div>
        <div className="font-bold py-6 md:py-0 sm:py-4">{name}</div>
      </div>
      <div className="w-3/4 px-4 py-2 flex flex-col justify-between md:py-0 sm:w-full sm:px-0 sm:py-0">
        <div className="font-medium md:text-sm md:font-normal sm:pb-5">
          {cutText(content, 150)}
        </div>
        <div className="flex items-center justify-between sm:flex-col">
          <button
            className="bg-green-500 rounded-full w-10 h-10 flex justify-center items-center sm:hidden"
            onClick={togglePlay}
          >
            {playing ? (
              <AiOutlinePause className="text-white text-2xl" />
            ) : (
              <AiFillCaretRight className="text-white text-2xl" />
            )}
          </button>
          {duration && (
            <div className="flex px-2 items-center w-9/12 sm:w-full sm:px-0">
              <div className="text-xs w-14">{formatCurrentTiming()}</div>
              <div className="bg-gray-300 rounded-lg h-2 w-9/12 mx-2 relative">
                <div
                  className="absolute h-full rounded-lg bg-green-500"
                  style={{ width: ((seconds / duration) * 100) + "%" }}
                />
              </div>
              <div className="text-xs w-14">{formatSoundTiming(duration)}</div>
            </div>
          )}
          <div className="flex sm:pt-5 sm:w-1/2 sm:justify-between">
            <button
              className="relative h-11 w-11 flex items-center justify-center"
              onClick={substractTime}
            >
              <span className="absolute text-xs mr-1">30</span>
              <img className="h-full" src={rotateBack} />
            </button>
            <button
              className="bg-green-500 rounded-full w-10 h-10 justify-center items-center hidden sm:flex"
              onClick={togglePlay}
            >
              {playing ? (
                <AiOutlinePause className="text-white text-2xl" />
              ) : (
                <AiFillCaretRight className="text-white text-2xl" />
              )}
            </button>
            <button
              className="relative h-11 w-11 flex items-center justify-center"
              onClick={addTime}
            >
              <span className="absolute text-xs ml-1">30</span>
              <img className="h-full" src={rotate} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

AudioSingleChapter.propTypes = {
  idx: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  audio: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  submitCourseProgress: PropTypes.func.isRequired,
  active: PropTypes.number,
  onEnd: PropTypes.func,
  autoplay: PropTypes.bool,
  setAutoPlay: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    submitCourseProgress: bindActionCreators(courseActions.submitCourseProgress, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AudioSingleChapter);
