import React from "react";

const ReportBanner = () => {
  return (
    <div className="w-49per shadow-accent rounded-lg flex flex-col p-6 border border-gray-100 sm:w-full mb-4">
      <div className="font-semibold pb-4">Nulägesanalys rapport</div>
      <div className="text-sm">
        Här kan du se fördelningen på svar från nulägesanalysen. Graferna är
        indelade i samma kategorier som nulägesanalysen för att lätt kunna
        identifiera och analysera resultatet.
        <br />
        <br />
        Vill du se mer detaljerat kring varje fråga? klicka på detaljerat så kan
        du se svar i procent per fråga.
      </div>
    </div>
  );
};

export default ReportBanner;
