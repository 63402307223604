import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { VscMenu, VscChromeClose } from "react-icons/vsc";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const TopBarMenu = ({ logout }) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  if (location.pathname === "/enkat") {
    return <></>;
  }


  const openMenu = () => {
    setActive(true);
  };

  const closeMenu = () => {
    setActive(false);
  };
  // TODO Logo should link to startpage which is not yet made so it goes to the explore page for now
  return (
    <div className="w-full flex justify-center">
    <div
      className="sm:w-full shadow-menu w-fit sm:rounded-none rounded-menu bg-white flex justify-between py-2 items-center px-14 z-10
       fixed sm:bg-black md:px-12 sm:px-6 sm:m-0 mt-5 sm:text-white text-black space-x-5 whitespace-nowrap"
    >
      <Link to="/">
        <h1 className="bottom-5 text-2xl sm:text-lg">
          Learn<span className="font-bold">Flow</span>
          <sup className="text-xl">®</sup>
        </h1>
      </Link>
      <div className="flex w-3/5 justify-evenly h-1/2 sm:hidden space-x-6 items-center select-none">
        <NavLink exact to="/"
         activeClassName="bg-active-menu rounded-active-menu px-4 py-2"
         >
          Utforska
        </NavLink>
        <NavLink exact to="/profil"
         activeClassName="bg-active-menu rounded-active-menu px-4 py-2"
         >
          Min profil
        </NavLink>
      </div>
      <Link to="/" onClick={logout} className="sm:hidden">
        <div>Logga ut</div>
      </Link>
      <div className="hidden sm:block">
        {active === false ? (
          <VscMenu className="text-white w-8 h-6" onClick={openMenu} />
        ) : (
          <VscChromeClose className="text-white w-8 h-6" onClick={closeMenu} />
        )}
        <div
          className={`${
            active === true ? "h-48" : "h-0 transition-all"
          } bg-black overflow-hidden absolute top-11 right-0 flex flex-col items-center justify-around w-44 px-4 transition-all`}
        >
          <Link
            to="/"
            className="border-b border-white h-1/3 flex items-center justify-center w-full"
            onClick={closeMenu}
          >
            Utforska
          </Link>
          <Link
            to="/profil"
            className="border-b border-white h-1/3 flex items-center justify-center w-full"
            onClick={closeMenu}
          >
            Min Profil
          </Link>
          <Link
            to="/"
            onClick={logout}
            className="h-1/3 flex items-center justify-center w-full"
          >
            <div>Logga ut</div>
          </Link>
        </div>
      </div>
    </div>
    </div>
  );
};

TopBarMenu.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default TopBarMenu;
