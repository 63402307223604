import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../redux/actions/pagesActions";
import isEmptyObject from "../utils/isEmptyObject";
import Modal from "react-modal";
import PlatformDescription from "../components/PlatformDescription";
import LandingBanner from "../components/LandingBanner";
import Footer from "../components/Footer";
import RegisterCompanyModal from "../components/modals/RegisterCompanyModal";
import RegisterRegularUserModal from "../components/modals/RegisterRegularUserModal";
import ResetPasswordModal from "../components/modals/ResetPasswordModal";
import LandingWelcomeBanner from "../components/LandingWelcomeBanner";
import CookieBanner from "../components/CookieBanner";
import HowItWorkLanding from "../components/HowItWorkLanding";
import VideoLanding from "../components/VideoLanding";
import LandingGradientBanner from "../components/LandingGradientBanner";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const modalStyles = {
  content: {
    padding: "0",
    height: "80%",
    width: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "hsl(0deg 0% 0% / 75%)"
  }
};

Modal.setAppElement("#root");

const LandingPage = ({ fetchHomePageDetails, homePageDetails }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/registrera")) {
      setRegisterModal(true);
    }
    if (location.pathname.startsWith("/aterstall-losenord")) {
      setResetPasswordModal(true);
    }
  }, [location]);

  useEffect(() => {
    fetchHomePageDetails().catch(() => {
      setErrorOccurred(true);
    });
  }, [fetchHomePageDetails, location]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setRegisterModal(false);
    setResetPasswordModal(false);
  }

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  if (isEmptyObject(homePageDetails)) {
    return <LoadingIndicator />;
  }

  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <div className="w-full">
       <LandingWelcomeBanner
        openModal={openModal}
        executeScroll={executeScroll}
        title={homePageDetails.hero_title}
        description={homePageDetails.hero_description}
        buttonName={homePageDetails.hero_button}
        image={homePageDetails.hero_image}
      />
      <div className="pb-28 px-28 lg:px-16 md:px-12 sm:px-0 lg:pb-16 md:pb-12 sm:pb-4" ref={myRef}>
        <LandingBanner title={homePageDetails.what_is_title} description={homePageDetails.what_is_description} />
        <PlatformDescription
          isLanding="isLanding"
          title={homePageDetails.boxes_title}
          subtitle={homePageDetails.boxes_subtitle}
          listeningDescription={homePageDetails.listening_box}
          readingDescription={homePageDetails.reading_box}
          videoDescription={homePageDetails.video_box}
        />
        {homePageDetails.vimeo_url && (
          <VideoLanding
            title={homePageDetails.video_title}
            subtitle={homePageDetails.video_subtitle}
            vimeo={homePageDetails.vimeo_url}
          />
        )}
        {homePageDetails.video && homePageDetails.vimeo_url == null && (
          <VideoLanding
            title={homePageDetails.video_title}
            subtitle={homePageDetails.video_subtitle}
            video={homePageDetails.video}
          />
        )}
        <HowItWorkLanding
          title={homePageDetails.how_it_works_title}
          subtitle={homePageDetails.how_it_works_subtitle}
          content={homePageDetails.how_it_works_description}
          image={homePageDetails.how_it_works_image}
        />
        <LandingGradientBanner
          title={homePageDetails.cta_title}
          content={homePageDetails.cta_description}
          buttonText={homePageDetails.cta_button}
          buttonUrl={homePageDetails.cta_url}
        />
      </div>
      <Footer />
      <Modal isOpen={modalIsOpen} style={modalStyles}>
        <RegisterCompanyModal closeModal={closeModal} />
      </Modal>
      <Modal isOpen={registerModal} style={modalStyles}>
        <RegisterRegularUserModal closeModal={closeModal}/>
      </Modal>
      <Modal isOpen={resetPasswordModal} style={modalStyles}>
        <ResetPasswordModal closeModal={closeModal}/>
      </Modal>
      <CookieBanner />
    </div>
  );
};

LandingPage.propTypes = {
  fetchHomePageDetails: PropTypes.func.isRequired,
  homePageDetails: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    homePageDetails: state.pages.homePageDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchHomePageDetails: bindActionCreators(
      actions.fetchHomePageDetails,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
