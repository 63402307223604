import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const CoursesCarouselMobile = ({ courses }) => {
  const settings = {
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    dots: false,
  };

  return (
    <>
      <div className="mt-12 px-6">
        <div className="font-semibold text-3xl">Rekommenderat för dig</div>
        <div className="py-1">Bläddra bland de olika teman för att hitta<br />något som passar dig!</div>
      </div>
      <div className="pb-8 w-full overflow-hidden slider-min-padding">
        <Slider {...settings}>
          {courses &&
            courses.map((el) => (
              <div className="flex mr-6 w-48" key={el.id}>
                <Link to={`/tema/${encodeURIComponent(el.slug)}`}>
                  <div className="relative">
                    <div className="rounded-xl w-full h-44">
                      <img
                        className="rounded-lg object-cover h-full w-full"
                        src={el.image}
                      />
                    </div>
                    <div className="absolute text-white bg-yellow-700 top-0 left-0 rounded-tl-lg text-xs py-2 px-4 bg-opacity-70">
                      {el.title}
                    </div>
                    <div className="absolute text-white bg-black bottom-0 right-0 rounded-br-lg text-xs py-2 px-4 bg-opacity-70">
                      {el.length} min
                    </div>
                  </div>
                  <div className="leading-4 py-3 font-semibold">{el.title}</div>
                </Link>
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
};

CoursesCarouselMobile.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default CoursesCarouselMobile;
