import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as coursesActions } from "../redux/actions/coursesActions";
import isEmptyObject from "../utils/isEmptyObject";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import CourseOptions from "../components/CourseOptions";
import CourseQuizBanner from "../components/CourseQuizBanner";
import Breadcrumbs from "../components/Breadcrumbs";

import individaulImage from "../assets/individual-image.png";
import organisationImage from "../assets/organisation-image.png";
import teamImage from "../assets/team-image.png";
import Footer from "../components/Footer";

const CourseDetails = ({ fetchCourseDetails, courseDetails }) => {
  const [errorOccured, setErrorOccured] = useState(false);
  const [courseCategory, setCourseCategory] = useState({
    "name": "",
    "image": "",
    "link": "",
  });
  const { title } = useParams();

  useEffect(() => {
    setErrorOccured(false);
    fetchCourseDetails(title).catch(() => {
      setErrorOccured(true);
    });
  }, [fetchCourseDetails]);

  const setCategoryFields = (category) => {
    if (category === 0) {
      setCourseCategory({
        "name": "Individnivå",
        "image": individaulImage,
        "link": "/teman/individ",
      });
    }

    if (category === 1) {
      setCourseCategory({
        "name": "Organisationsnivå",
        "image": organisationImage,
        "link": "/teman/organisation",
      });
    }

    if (category === 2) {
      setCourseCategory({
        "name": "Gruppnivå",
        "image": teamImage,
        "link": "/teman/grupp",
      });
    }
  };

  useEffect(() => {
    if (courseDetails) {
      setCategoryFields(courseDetails.category);
    }
  }, [courseDetails]);

  if (isEmptyObject(courseDetails)) {
    return <LoadingIndicator />;
  }
  if (errorOccured) {
    return <ErrorOccurred />;
  }

  return (
    <>
      <div className="px-28 py-20 md:px-12 md:py-16 sm:p-6 sm:mt-12">
        <Breadcrumbs courseTitle={courseDetails.title} courseSlug={courseDetails.slug} courseCategory={courseDetails.category} />
        <div className="flex justify-between">
          <div className="w-1/2 sm:w-full">
            <div className="bg-gray-300 rounded w-60 h-52 md:w-44 md:h-44 sm:w-full">
              <img
                className="rounded-lg object-cover h-full w-full"
                src={courseDetails.image}
              />
            </div>
            <div className="pt-6 font-bold text-3xl md:text-2xl md:pt-3 sm:text-2xl">
              {courseDetails.title}
            </div>
            <div className="pb-3">
              <Link
                to={courseCategory.link}
                className="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-blue-200 text-blue-700 rounded-full"
              >
                {courseCategory.name}
              </Link>
            </div>
            <div className="max-w-lg text0base md:text-sm">
              {courseDetails.description}
            </div>
          </div>
          <div className="rounded w-96 h-96 md:w-72 md:h-72 sm:hidden">
            <img
              className="rounded-lg object-cover h-full w-full"
              src={courseCategory.image}
            />
          </div>
        </div>
        {(!courseDetails.reading_enabled === false && !courseDetails.audio_enabled === false && !courseDetails.video_enabled === false) ? (
          <>
            <CourseOptions
              courseSlug={courseDetails.slug}
              readingData={courseDetails.reading_description}
              audioData={courseDetails.audio_description}
              videoData={courseDetails.video_description}
              readingEnabled={courseDetails.reading_enabled}
              audioEnabled={courseDetails.audio_enabled}
              videoEnabled={courseDetails.video_enabled}
            />
            <CourseQuizBanner
              quizData={courseDetails.quiz_description}
              courseSlug={courseDetails.slug}
              isEnabled={courseDetails.quiz_enabled}
            />
          </>
        ) : null}
      </div>
      <Footer />
    </>
  );
};

CourseDetails.propTypes = {
  fetchCourseDetails: PropTypes.func.isRequired,
  courseDetails: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    courseDetails: state.courses.courseDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCourseDetails: bindActionCreators(
      coursesActions.fetchCourseDetails,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
