import React from "react";
import PropTypes from "prop-types";
import { GrFormClose } from "react-icons/gr";

const QuizSurveyModal = ({ data, closeModal }) => {
  return (
    <div className="w-full h-full bg-white flex">
      <div className="w-full flex flex-col flex-grow py-1 relative justify-start lg:px-6 md:w-2/3 md:px-12 sm:py-2 sm:w-full sm:px-6">
        <div
          onClick={closeModal}
          className="absolute top-5 right-5 cursor-pointer"
        >
          <GrFormClose className="w-6 h-6" />
        </div>
        <div className="flex">
          <div className="w-full border-b-4 border-gray-300 justify-between px-8 py-4">
            <div className="font-semibold text-lg">{data.course}</div>
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Mål 1:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            {data.goal1}
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Mål 2:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            {data.goal2}
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Aktivitet:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            {data.activity}
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Datum:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            Datum från: {data.date_from}, Datum till: {data.date_to}
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Ansvarig för:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            {data.decide_who}
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Status 1:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            {data.status1}
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Status 2:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            {data.status2}
          </div>
        </div>
        <div className="flex flex-row px-8 border-b-2 border-gray-300 sm:flex-col">
          <div className="w-1/4 py-4 border-r-2 border-gray-300 font-semibold sm:w-full sm:border-r-0">
            Kommentarer:
          </div>
          <div className="w-3/4 py-4 px-2 sm:w-full sm:px-0">
            {data.comments}
          </div>
        </div>
      </div>
    </div>
  );
};

QuizSurveyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default QuizSurveyModal;
