import React from "react";
import PropTypes from "prop-types";
import Chart from "react-google-charts";
import img from "../../assets/account.png";
import ToolTipIcon from "../ToolTipIcon";

const ProfileInfo = ({
  firstName,
  lastName,
  position,
  company,
  surveyResult,
}) => {
  const team = surveyResult.team == null || surveyResult.team === 0 ? null : Number(surveyResult.team.toFixed(2));
  const individual = surveyResult.individual == null || surveyResult.individual === 0 ? null : Number(surveyResult.individual.toFixed(2));
  const organization = surveyResult.organization == null || surveyResult.organization === 0 ? null : Number(surveyResult.organization.toFixed(2));
  const hasAllData = team && individual && organization;

  return (
    <div className="w-48per bg-white rounded-2xl flex flex-row justify-between relative md:flex-col sm:w-full sm:flex-col sm:mb-4">
      <div className="flex md:p-4 sm:p-4">
        <div className="w-52 h-full lg:w-36 md:w-28 md:h-28 sm:w-24 sm:h-24">
          <img
            className="max-w-full h-full object-cover rounded-tl-lg rounded-bl-lg md:rounded-md sm:rounded-md"
            src={img}
            alt="logo"
          />
        </div>
        <div className="flex flex-col pl-4 py-8 w-1/3 lg:w-2/5 md:w-2/3 md:py-0 md:justify-center sm:w-2/3 sm:py-0">
          <div className="font-semibold text-3xl">
            {firstName + " " + lastName}
          </div>
          <p className="font-light py-1">{position}</p>
          <p className="text-xs">{company}</p>
        </div>
      </div>
      <div className="flex items-center h-full w-1/2 pr-6 md:hidden sm:hidden">
        {hasAllData && (
          <Chart
            width={"100%"}
            height={"100%"}
            chartType="ColumnChart"
            loader={<div>Läser in diagram</div>}
            data={[
              ["", " ", { role: "style" }],
              ["Team/Grupp", team, "#2563eb"],
              ["Individuellt", individual, "#10b981"],
              ["Organisation", organization, "#4c1d95"],
            ]}
            options={{
              legend: { position: "none" },
              vAxis: {
                viewWindow: {
                    min: 0
                }
              },
              hAxis: { textPosition: "none" },
            }}
          />
        )}
      </div>
      {hasAllData && (
        <>
        <div className="absolute bottom-2 left-52 flex items-center justify-evenly w-7/12 lg:w-4/6 lg:left-36 md:hidden sm:hidden">
          <div className="flex items-center">
            <div className="w-3 h-3 rounded bg-blue-600 mr-1"></div>
            <div className="text-xs">Team/Grupp</div>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 rounded bg-green-500 mr-1"></div>
            <div className="text-xs">Individuellt</div>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 rounded bg-purple-900 mr-1"></div>
            <div className="text-xs">Organisation</div>
          </div>
        </div>
        <ToolTipIcon
          style="right-3 top-3"
          align="left"
          text="
          Här är resultatet av nulägesanalysen om hur du ser på din arbetsplats som en lärande organisation.
          Beräknat för individ, grupp och organisation, där maxpoäng för varje område är 6 poäng.
          Rekommenderade kunskapsteman utgår också från detta.
          "
        />
        </>
      )}
      <div className="hidden md:flex sm:flex justify-evenly items-center pb-4">
        {hasAllData && (
            <Chart
              width={"100%"}
              height={"100%"}
              chartType="ColumnChart"
              loader={<div>Läser in diagram</div>}
              data={[
                ["", " ", { role: "style" }],
                ["Team/Grupp", team, "#2563eb"],
                ["Individuellt", individual, "#10b981"],
                ["Organisation", organization, "#4c1d95"],
              ]}
              options={{
                legend: { position: "none" },
                vAxis: {
                  viewWindow: {
                      min: 0
                  }
                },
                hAxis: { textPosition: "none" },
              }}
            />
          )}
      </div>
      {hasAllData && (
        <div className="hidden md:flex sm:flex justify-evenly items-center pb-4">
          <div className="flex flex-col justify-center">
            <div className="text-gray-400 text-sm">Team/Grupp</div>
            <div className="text-xl font-bold text-center">{team}</div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-gray-400 text-sm">Individuellt</div>
            <div className="text-xl font-bold text-center">{individual}</div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-gray-400 text-sm">Organisation</div>
            <div className="text-xl font-bold text-center">{organization}</div>
          </div>
        </div>
      )}
    </div>
  );
};

ProfileInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  surveyResult: PropTypes.object.isRequired,
};

export default ProfileInfo;
