import React from "react";
import PropTypes from "prop-types";
import { FaArrowDown } from "react-icons/fa";
import img from "../assets/subscribe-background.png";

const SubscriptionHeader = ({ executeScroll }) => {
  return (
    <div className="h-80 w-full mt-10 sm:h-screen sm:mt-0 sm:relative">
      <div className="px-28 pt-20 flex justify-center items-center flex-col text-white sm:px-6 sm:py-0 sm:h-full">
        <div className="text-xl pb-10">Vilket paket passar dig bäst?</div>
        <div className="text-3xl text-center max-w-4xl">
          Kolla in vilka typer av paket som passar bäst för er. Du kan när som
          helst uppdatera ditt paket.
        </div>
      </div>
      <div className="filter brightness-75 w-full h-1/2 absolute -top-14 sm:h-full -z-10 sm:top-0">
        <div className="bg-black h-full w-full flex justify-between overflow-hidden sm:justify-center">
          <img src={img} className="sm:w-full sm:object-cover"/>
          <img src={img} className="sm:hidden"/>
        </div>
      </div>
      <div
          className="h-1/4 py-10 items-end hidden sm:flex absolute w-full bottom-10"
          onClick={executeScroll}
        >
          <button className="flex bg-white rounded-full w-10 h-10 justify-center items-center mx-auto outline-none">
            <FaArrowDown className="h-6 w-6 font-bold text-black" />
          </button>
        </div>
    </div>
  );
};

SubscriptionHeader.propTypes = {
  executeScroll: PropTypes.func.isRequired,
};

export default SubscriptionHeader;
