import React from "react";
import PropTypes from "prop-types";

const ProfileQuizSurvey = ({ idx, data, setCurrentId, openModal }) => {

  const handleClick = () => {
    openModal();
    setCurrentId(idx);
  };

  return (
    <>
      <div className="flex border-b border-gray-300 justify-between px-8 py-4">
        <div>{data.course}</div>
        <div className="underline text-blue-400 cursor-pointer">
          <button onClick={() => handleClick()}>
            Visa
          </button>
        </div>
      </div>
    </>
  );
};

ProfileQuizSurvey.propTypes = {
  idx: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  setCurrentId: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default ProfileQuizSurvey;
