import React from "react";
import PropTypes from "prop-types";
import FormRenderer from "./FormRenderer";

const RegisterFormCompany = ({ onSubmit }) => {

  const fields = [
    {
      fieldName: "firstName",
      placeholder: "Förnamn",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "lastName",
      placeholder: "Efternamn",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "email",
      placeholder: "Din e-postadress",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "username",
      placeholder: "Ditt användarnamn",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "city",
      placeholder: "Stad",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "street",
      placeholder: "Gata",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "postalCode",
      placeholder: "Postnummer",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "orgNumber",
      placeholder: "Organisationsnummer",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "orgName",
      placeholder: "Organisationens namn",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "password",
      placeholder: "Lösenord",
      type: "password",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "password-confirm",
      placeholder: "Bekräfta lösenord",
      type: "passwordConfirmation",
      validation: {
        required: true,
      },
    },
  ];

  const bottom = (
    <button type="submit" className="bg-blue-600 text-white w-full rounded font-semibold py-2">
      Registrera konto
    </button>
  );

  return (
    <FormRenderer fields={fields} submit={onSubmit} bottom={bottom}/>
  );
};

RegisterFormCompany.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RegisterFormCompany;
