import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "./redux/actions/userActions";
import { actions as seoActions } from "./redux/actions/pagesActions";
import { Route, Switch, useHistory } from "react-router-dom";
import Routing from "./pages/routing/Routing";
import RoutingCompany from "./pages/routing/RoutingCompany";
import RoutingLoggedOut from "./pages/routing/RoutingLoggedOut";

function App({ isLoggedIn, userType, survey, userData, fetchUserData, seoDetails, fetchSEODetails }) {

  let history = useHistory();

  useEffect(() => {
    if (isLoggedIn && !userData) {
      fetchUserData();
    }
  }, [isLoggedIn, userData]);

  useEffect(() => {
    fetchSEODetails();
  }, [fetchSEODetails]);

  useEffect(() => {
    if (!userData) {
      return;
    }
    if (userType === "company" && !userData.profile_data.is_subscribed) {
      history.push("/prenumerationer");
    }

    if (userType === "employee" && !survey) {
      history.push("/enkat");
    }

  }, [userData, survey, userType]);

  const selectRouter = (usrType) => {
    if (!isLoggedIn) {
      return <Route path="/" component={RoutingLoggedOut} />;
    }
    if (usrType === "company") {
      return <Route path="/" component={RoutingCompany} />;
    }
    return <Route path="/" component={Routing} />;
  };

  return (
    <div className="h-screen w-screen px-0 mx-0 relative overflow-x-hidden">
      <Helmet>
          <title>{seoDetails?.title}</title>
          <meta name="description" content={seoDetails?.description} />
          <meta property="og:image" content={seoDetails?.image} />
          <style>{"body { background-color: #EFEFEF; }"}</style>
      </Helmet>
      <Switch>
        {selectRouter(userType)}
      </Switch>
    </div>
  );
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  fetchSEODetails: PropTypes.func.isRequired,
  userType: PropTypes.string,
  survey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  userData: PropTypes.object,
  seoDetails: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.access != null,
    userType: state.auth.userType,
    survey: state.auth.survey,
    userData: state.userData.userdata,
    seoDetails: state.pages.seoDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserData: bindActionCreators(actions.fetchUserData, dispatch),
    fetchSEODetails: bindActionCreators(
      seoActions.fetchSEODetails,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
