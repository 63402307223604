const ACHIEVEMENT_NOTIFICATION_SEEN_REQUEST = "ACHIEVEMENT_NOTIFICATION_SEEN_REQUEST";
const ACHIEVEMENT_NOTIFICATION_SEEN_SUCCESS = "ACHIEVEMENT_NOTIFICATION_SEEN_SUCCESS";
const ACHIEVEMENT_NOTIFICATION_SEEN_FAILURE = "ACHIEVEMENT_NOTIFICATION_SEEN_FAILURE";

const markNotificationAsSeen = (notificationId) => ({
  types: [ACHIEVEMENT_NOTIFICATION_SEEN_REQUEST, ACHIEVEMENT_NOTIFICATION_SEEN_SUCCESS, ACHIEVEMENT_NOTIFICATION_SEEN_FAILURE],
  endpoint: `achievements/notifications/${notificationId}/view/`,
  method: "POST",
});

export const actions = {
  markNotificationAsSeen,
};

export const ACTION_HANDLERS = {
  [ACHIEVEMENT_NOTIFICATION_SEEN_REQUEST]: (state) => ({
    ...state,
    newAchievement: null,
  })
};

export const initialState = {
  newAchievement: null,
};

export default function reducer(state = initialState, action) {
  if (action?.payload != null && action.payload.new_achievements) {
    const newAchievements = action.payload.new_achievements;
    if (newAchievements.length > 0 && state.newAchievement?.id !== newAchievements[0].id) {
      return {
        ...state,
        newAchievement: newAchievements[0],
      };
    }
  }
  return state;
}
