import React from "react";
import PropTypes from "prop-types";

const LandingBanner = ({ title, description }) => {
  return (
    <div className="pt-10 sm:px-6">
      <div className="bg-white rounded-2xl max-w-6xl m-auto px-12 py-10 flex justify-around items-center border border-gray-100 md:py-8 md:px-10 sm:flex-col sm:px-4 sm:py-6">
        <div className="landing-gradient text-right font-semibold text-4xl lg:text-3xl md:text-3xl sm:text-center" dangerouslySetInnerHTML={{ __html: title }}/>
        <div className="text-lg w-1/2 leading-8 tracking-wide lg:text-base lg:w-7/12 md:text-sm md:w-8/12 sm:w-11/12 sm:pt-5 sm:text-center" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

LandingBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default LandingBanner;
