import React from "react";
import PropTypes from "prop-types";
import ReadingIcon from "./ReadingIcon";
import AudioIcon from "./AudioIcon";

const CourseIntroBanner = ({ courseDetails, isReadingPage, title }) => {
  return (
    <div className="w-3/4 min-h-36 flex bg-white shadow-accent rounded-md mx-auto sm:w-full sm:flex-col sm:bg-transparent sm:shadow-none sm:absolute sm:top-20 sm:px-6 md:w-10/12">
      <div className="h-auto w-40 p-4 flex flex-col justify-between relative rounded-bl-lg rounded-tl-lg sm:px-0">
        <img
          alt="background-image"
          src={courseDetails.image}
          className="absolute w-full h-full object-cover left-0 top-0 z-0 rounded-bl-lg rounded-tl-lg sm:hidden"
        />
        <div className="z-20">
          {isReadingPage ? (
            <ReadingIcon type="color" />
          ) : (
            <AudioIcon type="color" />
          )}
        </div>
        <div
          className={`${
            isReadingPage ? "sm:text-yellow-500" : "sm:text-green-500"
          } text-white text-sm font-bold z-10 sm:text-2xl`}
        >
          {title}
        </div>
        <div className="text-white text-sm z-10 sm:hidden">
          {courseDetails.title}
        </div>
      </div>
      <div className="p-4 w-4/5 flex flex-col justify-center sm:p-0 sm:text-white sm:w-full">
        <div className="text-xs font-bold sm:text-base">
          Vad handlar temat om?
        </div>
        <div className="text-base md:text-sm">{courseDetails.description}</div>
      </div>
    </div>
  );
};

CourseIntroBanner.propTypes = {
  courseDetails: PropTypes.object.isRequired,
  isReadingPage: PropTypes.string,
  title: PropTypes.string,
};

export default CourseIntroBanner;
