export const QUIZ_COURSE_REQUEST = "QUIZ_COURSE_REQUEST";
export const QUIZ_COURSE_SUCCESS = "QUIZ_COURSE_SUCCESS";
export const QUIZ_COURSE_FAILURE = "QUIZ_COURSE_FAILURE";

export const SUBMIT_QUIZ_REQUEST = "SUBMIT_QUIZ_REQUEST";
export const SUBMIT_QUIZ_SUCCESS = "SUBMIT_QUIZ_SUCCESS";
export const SUBMIT_QUIZ_FAILURE = "SUBMIT_QUIZ_FAILURE";

export const QUIZ_SCORE_REQUEST = "QUIZ_SCORE_REQUEST";
export const QUIZ_SCORE_SUCCESS = "QUIZ_SCORE_SUCCESS";
export const QUIZ_SCORE_FAILURE = "QUIZ_SCORE_FAILURE";

export const QUIZ_SCORE_RESET = "QUIZ_SCORE_RESET";

const fetchQuizCourse = (title) => ({
  types: [
    QUIZ_COURSE_REQUEST,
    QUIZ_COURSE_SUCCESS,
    QUIZ_COURSE_FAILURE,
  ],
  endpoint: `course/quiz/${encodeURIComponent(title)}`,
  method: "GET",
});

const submitQuizCourse = (quizId, answers) => ({
  types: [
    SUBMIT_QUIZ_REQUEST,
    SUBMIT_QUIZ_SUCCESS,
    SUBMIT_QUIZ_FAILURE,
  ],
  endpoint: "grade-user/",
  method: "POST",
  body: {
    quiz: quizId,
    questions: answers,
  }
});

const fetchQuizScore = (quiz) => ({
  types: [
    QUIZ_SCORE_REQUEST,
    QUIZ_SCORE_SUCCESS,
    QUIZ_SCORE_FAILURE,
  ],
  endpoint: `get-user-score/${quiz}/`,
  method: "GET",
});

const resetQuiz = () => ({
  type: QUIZ_SCORE_RESET
});

export const actions = {
  fetchQuizCourse,
  submitQuizCourse,
  fetchQuizScore,
  resetQuiz,
};

const ACTION_HANDLERS = {
  [QUIZ_COURSE_SUCCESS]: (state, { payload }) => ({
    ...state,
    quizCourse: payload,
  }),
  [SUBMIT_QUIZ_SUCCESS]: (state, { payload }) => ({
    ...state,
    quizResults: payload,
  }),
  [QUIZ_SCORE_SUCCESS]: (state, { payload }) => ({
    ...state,
    quizScore: payload,
  }),
  // eslint-disable-next-line no-unused-vars
  [QUIZ_SCORE_RESET]: (state, { payload }) => ({
    ...state,
    quizResults: null,
    quizScore: [],
  }),
};

export const initialState = {
  quizCourse: null,
  quizResults: null,
  quizScore: [],
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
