import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { actions as courseActions } from "../redux/actions/coursesActions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { FaPause, FaPlay, FaVolumeUp } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { BsFullscreen, BsCheck } from "react-icons/bs";

const VideoPlayer = ({ id, idx, videos, submitCourseProgress, handleVideoEnd, autoplay, setAutoplay }) => {
  const [source, setSource] = useState("");
  const [quality, setQuality] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showVolumeBar, setShowVolumeBar] = useState(false);
  const [showOptionsBar, setShowOptionsBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const videoRef = useRef(null);
  const progressRef = useRef(null);

  const { title } = useParams();

  useEffect(() => {
    if (videos != null && Object.keys(videos).length != 0) {
      let q = Object.entries(videos).sort((x, y) => y[1] - x[1])[0];
      setSource(q[1]);
      setQuality(q[0]);
      if (videoRef.current != null) {
        videoRef.current.pause();
        setIsPlaying(false);
        videoRef.current.load();
      }
    }
  }, [id, idx, videos]);

  const onSelectQuality = (q) => {
    setSource(videos[q]);
    setQuality(q);
    videoRef.current.pause();
    setIsPlaying(false);
    videoRef.current.load();
  };

  useEffect(() => {
    setProgress(0);
    setIsLoaded(false);
  }, [videos]);

  const onLoadedData = () => {
    const savedTime = JSON.parse(localStorage.getItem(`videoPlaybackTime-${title}-${idx}`));
    if (savedTime && videoRef.current) {
        videoRef.current.currentTime = savedTime;
    }
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLoaded && autoplay) {
      setAutoplay(false);
      playVideo();
    }
  }, [isLoaded]);

  const playVideo = () => {
    videoRef.current.play();
  };

  const pauseVideo = () => {
    savePlaybackTime();
    videoRef.current.pause();
  };

  const muteVideo = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
    } else {
      videoRef.current.muted = true;
    }
  };

  const toggleVolumeBar = () => {
    setShowVolumeBar(!showVolumeBar);
  };

  const changeVolume = (e) => {
    setVolume(e.target.value);
    videoRef.current.volume = e.target.value;
  };

  const toggleFullScreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    }
  };

  const updateProgressBar = () => {
    const percentage = Math.floor(
      (100 / videoRef.current.duration) * videoRef.current.currentTime
    );
    setProgress(percentage);
    if (percentage === 100) {
      setIsPlaying(false);
    }
  };

  const formatCurrentTiming = (input) => {
    if (typeof input == "number" && !isNaN(input)) {
      let time = Math.round(input);
      return new Date(time * 1000).toISOString()
        .substr(11, 8);
    }

    return "00:00:00";
  };

  function seek(e) {
    // eslint-disable-next-line no-invalid-this
    let percent = e.offsetX / this.offsetWidth;
    videoRef.current.currentTime = percent * videoRef.current.duration;
    savePlaybackTime();
    e.target.value = Math.floor(percent * 100);
  }

  if (progressRef && progressRef.current) {
    progressRef.current.addEventListener("click", seek);
  }

  const onEnded = () => {
    submitCourseProgress(title, "video", id);
    handleVideoEnd(idx);
  };

  const toggleOptions = () => {
    setShowOptionsBar(!showOptionsBar);
  };

  const savePlaybackTime = () => {
    if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        localStorage.setItem(`videoPlaybackTime-${title}-${idx}`, JSON.stringify(currentTime));
    }
  };

  useEffect(() => {
    if (isPlaying) {
      savePlaybackTime();
    }
  }, [videoRef?.current?.currentTime, isPlaying]);

  return (
    <div className="h-2/5 w-8/12 flex flex-col justify-between relative rounded-bl-lg rounded-tl-lg mx-auto md:w-11/12 sm:w-full sm:mb-5">
      {!isLoaded && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="loader"></div>
        </div>
      )}
        {source && (
          <>
            <video
            key={idx}
            ref={videoRef}
            className="object-contain bg-black w-full h-full"
            playsInline={true}
            onTimeUpdate={updateProgressBar}
            onLoadedData={onLoadedData}
            onEnded={onEnded}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            controlsList="nodownload"
          >
            <source src={source} type="video/mp4" />
          </video>
          </>
        )}
        <div
          id="controls"
          className="flex align-center absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 pt-3 pb-2 px-5 d-none"
        >
          <progress
            key={idx}
            id={idx}
            ref={progressRef}
            className="absolute top-0 left-0 right-0 w-full h-1 text-blue-500 cursor-pointer"
            min="0"
            max="100"
            value={progress}
          />
        {isPlaying ? (
            <button onClick={() => pauseVideo()}>
              <FaPause className="text-white" />
            </button>
        ) : (
          <button onClick={() => playVideo()}>
            <FaPlay className="text-white" />
          </button>
        )}
        <button
          onClick={() => muteVideo()}
          onMouseEnter={() => toggleVolumeBar()}
        >
          <FaVolumeUp className="text-white ml-3 text-xl" />
        </button>
        {showVolumeBar && (
          <input
            type="range"
            className="ml-3"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onMouseLeave={() => toggleVolumeBar()}
            onChange={(e) => changeVolume(e)}
          />
        )}
        <div className="ml-3 inline text-white text-xs">
          {videoRef && videoRef.current ? formatCurrentTiming(videoRef.current.currentTime) : "00:00:00"}
          /
          {videoRef && videoRef.current ? formatCurrentTiming(videoRef.current.duration) : "00:00:00"}
        </div>
        <div className="ml-auto" />
        <button
          onClick={() => toggleOptions()}
          className="relative"
        >
          <FiSettings className="text-white mr-3 text-xl" />
          {showOptionsBar && (
            <div className="absolute flex flex-col bg-black bg-opacity-50 z-30 bottom-7 -right-9 pl-2 pr-8 py-2">
              {Object.keys(videos).map((q) => (
                <button
                  key={q}
                  className="text-white cursor-pointer text-sm py-1"
                  onClick={() => onSelectQuality(q)}>
                    <div className="flex items-center">
                      <BsCheck className={"mr-2 " + (quality === q ? "opacity-100" : "opacity-0")}/>
                      <div className="text-xs">{q}</div>
                    </div>
                </button>))}
            </div>
          )}
        </button>
        <button onClick={() => toggleFullScreen()}>
          <BsFullscreen className="text-white" />
        </button>
      </div>
    </div>
  );
};

VideoPlayer.propTypes = {
  id: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  videos: PropTypes.object.isRequired,
  submitCourseProgress: PropTypes.func.isRequired,
  handleVideoEnd: PropTypes.func,
  autoplay: PropTypes.bool,
  setAutoplay: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    submitCourseProgress: bindActionCreators(courseActions.submitCourseProgress, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(VideoPlayer);
