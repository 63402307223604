import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const CoursesSlider = ({ courses, completed }) => {
  const settings = {
    className: "slider",
    infinite: courses.length !== 1,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          dots: true,
          // eslint-disable-next-line react/display-name
          appendDots: (dots) => (
            <div
              style={{
                bottom: "-15px"
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {courses &&
        courses.map((el) => (
          <div
            className="relative bg-white rounded shadow-accent h-48 w-42 md:shadow-none"
            key={el.id}
          >
            <Link to={`/tema/${encodeURIComponent(el.slug)}`}>
            <div className="rounded-lg w-full h-full p-2 md:p-1">
              <img
                className="rounded-lg object-cover h-full w-full"
                src={el.image}
              />
            </div>
            <div className="absolute text-white bg-black top-2 left-2 rounded-tl-lg text-xs py-1 px-3">
              {el.title}
            </div>
            {!completed && (
              <div className="absolute text-white bg-black bottom-2 right-2 rounded-br-lg text-xs py-1 px-3">
                {(typeof el.course_progress === "string") ? el.course_progress : el.course_progress + "% avklarat"}
              </div>
            )}
            </Link>
          </div>
        ))}
    </Slider>
  );
};

CoursesSlider.propTypes = {
  courses: PropTypes.array.isRequired,
  completed: PropTypes.bool,
};

export default CoursesSlider;
