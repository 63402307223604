/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as courseActions } from "../redux/actions/coursesActions";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import background from "../assets/bg-quiz-result.svg";
import { FormFieldRequiredError } from "../components/forms/FormFieldError";

const QuizSurveyPage = ({ submitQuizSurvey }) => {
  let { title } = useParams();
  const history = useHistory();
  const errorsObj = {
    "goal": false,
    "secondGoal": false,
    "activity": false,
    "start": false,
    "end": false,
    "status": false,
    "who": false,
    "secondStatus": false,
    "comments": false,
  };

  const [goal, setGoal] = useState("");
  const [secondGoal, setSecondGoal] = useState("");
  const [activity, setActivity] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [status, setStatus] = useState("");
  const [who, setWho] = useState("");
  const [secondStatus, setSecondStatus] = useState("");
  const [comments, setComments] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState(errorsObj);

  const resetErrors = () => {
    setErrors(errorsObj);
  };

  const validate = () => {
    resetErrors();
    const tmpErr = {
      "goal": goal === "",
      "secondGoal": secondGoal === "",
      "activity": activity === "",
      "start": start === "",
      "end": end === "",
      "status": status === "",
      "who": who === "",
      "secondStatus": secondStatus === "",
      "comments": comments === "",
    };
    setErrors(tmpErr);
    if (Object.keys(tmpErr).every((k) => !tmpErr[k])) {
      submitQuizSurvey(
        title,
        goal,
        secondGoal,
        activity,
        start,
        end,
        who,
        status,
        secondStatus,
        comments
      ).then(() => {
        history.push("/");
      });
    }
  };

  useEffect(() => {
    if (startDate != null) {
      const days = startDate.getDate();
      const month = startDate.getMonth() + 1;
      const year = startDate.getFullYear();
      setStart(days + "/" + month + "/" + year);
    }

    if (endDate != null) {
      const days = endDate.getDate();
      const month = endDate.getMonth() + 1;
      const year = endDate.getFullYear();
      setEnd(days + "/" + month + "/" + year);
    }
  }, [start, setStart, end, setEnd, startDate, endDate]);


  const onSubmit = () => {
    validate();
  };

  return (
    <>
      <img src={background} className="fixed -z-20 w-full sm:hidden" />
      <div className="py-10 flex flex-col items-center pt-24 lg:px-16 md:px-12 sm:pt-6">
        <div
          className={
            "bg-white shadow-accent rounded-lg w-4/5 " +
            "border border-gray-100 py-6 lg:w-full md:w-full " +
            "sm:shadow-none sm:border-none sm:w-full"
          }
        >
          <div className="flex border-b border-gray-400 pb-4 sm:hidden">
            <div className="pl-7 font-bold">Handlingsplan : </div>
            <div className="pl-2">{title}</div>
          </div>
          <div className="px-14 pt-5 md:px-8 sm:px-6">
            <div className="py-4">
              <div className="text-xl font-semibold py-2">Syfte</div>
              <div className="text-base md:text-sm sm:text-sm">
                Syftet med handlingsplanen är planera in de aktiviteter som
                innebär tillämpningen av de nya kunskaperna som du tar med sig
                från varje tema i LearnFlow. En av de största anledningarna till
                att nya kunskaper och färdigheter aldrig kommer till nytta är
                att de inte ”planeras in” i det vardagliga arbetet.
              </div>
            </div>
            <div className="py-4">
              <div className="text-xl font-semibold py-2">Mål</div>
              <div className="text-base md:text-sm sm:text-sm">
                Dina mål kan både ligga i närtid eller på längre sikt, men dina
                aktiviteter behöver starta upp så snart som möjligt medan dina
                kunskaper är i färskt minne. Genom att besvara frågorna kommer
                dina svar automatiskt läggas in i din handlingsplan som
                sammanställs och visas i din profil. <br />
                <br /> Handlingsplanen kommer att hjälpa dig att både
                identifiera dina mål och vilka aktiviteter/insatser du tänker
                göra för att uppnå dina mål.
              </div>
            </div>
            <div className="pb-3">
              <div className="py-2 text-sm sm:py-4">
                1. Välj ut minst två mål som du vill uppnå med dina nya
                kunskaper från genomförd tema:
              </div>
              <div className="flex justify-between sm:flex-col">
                <div className="w-48per sm:w-full sm:pb-4">
                  <div className="text-gray-500 text-sm">Mål 1:</div>
                  <textarea
                    className="shadow-accent rounded-md w-full h-28 border border-gray-100 outline-none p-4"
                    onChange={(e) => setGoal(e.target.value)}
                  />
                  {errors.goal && <FormFieldRequiredError />}
                </div>
                <div className="w-48per sm:w-full">
                  <div className="text-gray-500 text-sm">Mål 2:</div>
                  <textarea
                    className="shadow-accent rounded-md w-full h-28 border border-gray-100 outline-none p-4"
                    onChange={(e) => setSecondGoal(e.target.value)}
                  />
                  {errors.secondGoal && <FormFieldRequiredError />}
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="py-2 text-sm sm:py-4">
                2. Bestäm dig för vilka aktiviteter du skall genomföra för att
                uppnå dina mål med hjälp av dina nya kunskaper och färdigheter
              </div>
              <div className="w-full">
                <div className="text-gray-500 text-sm">Aktivitet:</div>
                <textarea
                  className="shadow-accent rounded-md w-full h-28 border border-gray-100 outline-none p-4"
                  onChange={(e) => setActivity(e.target.value)}
                />
                {errors.activity && <FormFieldRequiredError />}
              </div>
            </div>
            <div className="flex justify-between pb-3 md:flex-col sm:flex-col">
              <div className="w-5/12 md:w-full sm:w-full">
                <div className="py-2 text-sm sm:py-4">
                  3. Sätt datum när dessa insatser börjar och slutar:
                </div>
                <div className="flex py-4 sm:justify-between">
                  <div
                    className={
                      "w-4/12 shadow-accent rounded-md h-14 border border-gray-100 " +
                      "pl-4 flex flex-col justify-center mr-4 sm:mr-0 sm:w-5/12"
                    }
                  >
                    <div className="text-gray-500 text-sm">Börjar</div>
                    <DatePicker
                      className="border-none outline-none text-sm max-w-9/10"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="d/MM/yyyy"
                      placeholderText="Välj datum"
                    />
                  </div>
                  <div
                    className={
                      "w-4/12 shadow-accent rounded-md h-14 border border-gray-100 " +
                      "pl-4 flex flex-col justify-center sm:w-5/12"
                    }
                  >
                    <div className="text-gray-500 text-sm">Slutar</div>
                    <DatePicker
                      className="border-none outline-none text-sm max-w-9/10"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="d/MM/yyyy"
                      placeholderText="Välj datum"
                    />
                  </div>
                </div>
                { /* eslint-disable-next-line no-mixed-operators */ }
                {errors.start || errors.end && <FormFieldRequiredError />}
              </div>
              <div>
                <div className="py-2 text-sm sm:py-4">
                  4. Bestäm vem som ska vara ansvarig för att insatsen genomförs
                </div>
                <div className="flex py-4 sm:justify-between">
                  <div
                    className={
                      "w-4/12 shadow-accent rounded-md h-14 border border-gray-100 " +
                      "pl-3 flex items-center mr-4 sm:mr-0 sm:w-5/12"
                    }
                  >
                    <input
                      className="border-none outline-none text-sm mr-2"
                      type="radio"
                      group="who"
                      checked={who === "Ensam"}
                      onChange={() => setWho("Ensam")}
                    />
                    <div className="text-gray-500 text-sm">Ensam</div>
                  </div>
                  <div
                    className={
                      "w-4/12 shadow-accent rounded-md h-14 border " +
                      "border-gray-100 pl-3 flex items-center sm:w-5/12"
                    }
                  >
                    <input
                      className="border-none outline-none text-sm mr-2"
                      type="radio"
                      group="who"
                      checked={who === "Med kollega"}
                      onChange={() => setWho("Med kollega")}
                    />
                    <div className="text-gray-500 text-sm">Med kollega</div>
                  </div>
                </div>
                {errors.who && <FormFieldRequiredError />}
              </div>
            </div>
            <div className="w-full pb-3">
              <div className="py-2 text-sm sm:py-4">
                5. Status 1 på dina aktiviteter – uppskatta eller värdera ditt
                nuläge idag på dina insatser, t ex inga alls eller påbörjade.
              </div>
              <div className="flex w-48per py-4 md:w-full sm:w-full sm:justify-between">
                <div
                  className={
                    "w-4/12 shadow-accent rounded-md h-14 border border-gray-100 " +
                    "pl-3 flex items-center mr-4 sm:mr-0 sm:w-5/12"
                  }
                >
                  <input
                    className="border-none outline-none text-sm mr-2"
                    type="radio"
                    group="status-1"
                    checked={status === "Inga alls"}
                    onChange={() => setStatus("Inga alls")}
                  />
                  <div className="text-gray-500 text-sm">Inga alls</div>
                </div>
                <div className="w-4/12 shadow-accent rounded-md h-14 border border-gray-100 pl-3 flex items-center sm:w-5/12">
                  <input
                    className="border-none outline-none text-sm mr-2"
                    type="radio"
                    group="status-1"
                    checked={status === "Påbörjade"}
                    onChange={() => setStatus("Påbörjade")}
                  />
                  <div className="text-gray-500 text-sm">Påbörjade</div>
                </div>
              </div>
              {errors.status && <FormFieldRequiredError />}
            </div>
            <div className="pb-3">
              <div className="py-2 text-sm sm:py-4">
                6. Status 2 - uppskatta eller värdera din måluppfyllelse och
                önskade läge för hur aktiviteterna fungerar efter en viss tid.
              </div>
              <div className="w-full">
                <div className="text-gray-500 text-sm">Kommentar:</div>
                <textarea
                  className="shadow-accent rounded-md w-full h-28 border border-gray-100 outline-none p-4"
                  onChange={(e) => setSecondStatus(e.target.value)}
                />
                {errors.secondStatus && <FormFieldRequiredError />}
              </div>
            </div>
            <div className="pb-3">
              <div className="py-2 text-sm sm:py-4">
                7. Kommentarer – ta med dig erfarenheter till nästa omgång av
                handlingsplanen
              </div>
              <div className="w-full">
                <div className="text-gray-500 text-sm">Kommentar:</div>
                <textarea
                  className="shadow-accent rounded-md w-full h-28 border border-gray-100 outline-none p-4"
                  onChange={(e) => setComments(e.target.value)}
                />
                {errors.comments && <FormFieldRequiredError />}
              </div>
            </div>
            <div className="flex justify-evenly py-8 px-6 sm:px-0 sm:justify-between">
              <button
                onClick={() => history.goBack()}
                className={
                  "rounded-md h-12 w-52 font-light border border-black " +
                  "flex justify-center items-center sm:w-5/12 sm:text-sm"
                }
              >
                Gå tillbaka till resultat
              </button>
              <button
                className={
                  "bg-black text-white rounded-md h-12 w-52 " +
                  "font-light flex justify-center items-center sm:w-5/12 sm:text-sm"
                }
                onClick={onSubmit}
              >
                Spara
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

QuizSurveyPage.propTypes = {
  submitQuizSurvey: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    submitQuizSurvey: bindActionCreators(
      courseActions.submitQuizSurvey,
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(QuizSurveyPage);
