import React from "react";
import PropTypes from "prop-types";
import { FormFieldRequiredError } from "./forms/FormFieldError";

const SurveyPersonalInfo = ({ role, setRole, sex, setSex, errors }) => {
  return (
    <div className="bg-white shadow-accent rounded-md py-16 px-12 text-lg w-96 sm:w-full sm:shadow-none sm:px-6 sm:py-0">
      <div className="pb-6 sm:pb-4 sm:font-semibold">Personlig Information</div>
      <div className="border border-gray-400 relative rounded-lg p-1 sm:border-blue-600">
        <div className="bg-white text-gray-400 absolute text-xs -top-2 px-2 left-5">
          Yrkesroll
        </div>
        <input
          onChange={(e) => setRole(e.target.value)}
          value={role}
          required
          className="outline-none text-sm"
        />
      </div>
      {errors.role && <FormFieldRequiredError />}
      <p className="py-6 sm:pb-2 sm:font-semibold">Kön</p>
      <div className="flex justify-between sm:justify-start">
        <div className="sm:mr-4">
          <input
            type="radio"
            id="Man"
            name="Man"
            value="Man"
            className="mr-1"
            checked={sex === "Man"}
            onChange={(e) => setSex(e.target.value)}
          />
          <label htmlFor="Man">Man</label>
        </div>
        <div className="sm:mr-4">
          <input
            type="radio"
            id="Kvinna"
            name="Kvinna"
            value="Woman"
            className="mr-1"
            checked={sex === "Woman"}
            onChange={(e) => setSex(e.target.value)}
          />
          <label htmlFor="Kvinna">Kvinna</label>
        </div>
        <div className="sm:mr-4">
          <input
            type="radio"
            id="Annat"
            name="Annat"
            value="Other"
            className="mr-1"
            checked={sex === "Other"}
            onChange={(e) => setSex(e.target.value)}
          />
          <label htmlFor="Annat">Annat</label>
        </div>
      </div>
      {errors.sex && <FormFieldRequiredError />}
    </div>
  );
};

SurveyPersonalInfo.propTypes = {
  setRole: PropTypes.func.isRequired,
  setSex: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  sex: PropTypes.string,
  role: PropTypes.string,
};

export default SurveyPersonalInfo;
