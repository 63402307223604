import React from "react";
import PropTypes from "prop-types";
import FormRenderer from "./FormRenderer";

const RequestResetPasswordForm = ({ success, onSubmit, toggleResetPasswordForm }) => {

  const fields = [
    {
      fieldName: "email",
      placeholder: "Din e-postadress",
      validation: {
        required: true,
      },
    },
  ];

  const bottom = (
    <>
      <p className="text-right pb-6 cursor-pointer" onClick={() => toggleResetPasswordForm()}>Logga in</p>
      {success ? (
        <p className="text-blue-600 text-center">Kolla din e-postadress och klicka på aktiveringslänken för att ändra ditt lösenord</p>
      ) : (
        <button type="submit" className="bg-blue-600 text-white w-full rounded font-semibold py-3">
          Skicka
        </button>
      )}
    </>
  );

  return (
    <FormRenderer submit={onSubmit} fields={fields} bottom={bottom} />
  );
};

RequestResetPasswordForm.propTypes = {
  success: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleResetPasswordForm: PropTypes.func.isRequired,
};

export default RequestResetPasswordForm;
