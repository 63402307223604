import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ProfileQuizSurvey from "./ProfileQuizSurvey";
import QuizSurveyModal from "../modals/QuizSurveyModal";

const modalStyles = {
  content: {
    padding: "0",
    height: "80%",
    width: "90%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "hsl(0deg 0% 0% / 75%)"
  }
};

const ProfileSlider = ({ quizSurveys }) => {
  const [currentId, setCurrentId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="w-48per bg-white rounded-card flex flex-col justify-between border border-gray-100 relative sm:w-full">
      <div className="flex w-full justify-around h-14">
        <div className="border-gray-300 w-full text-left py-4 px-8 font-semibold border-b cursor-pointer">
          Handlingsplan
        </div>
      </div>
      <div className="w-full h-60">
        {quizSurveys.length > 0 ? quizSurveys.map((el, index) => {
            return (
              <ProfileQuizSurvey
                key={el.id}
                idx={index}
                data={el}
                setCurrentId={setCurrentId}
                openModal={openModal}
              />
            );
          }) : (
            <div className="flex border-b border-gray-300 justify-center px-8 py-4">
              <div className="text-center text-sm text-gray-400">Du har inte genomfört några undersökningar.</div>
            </div>
          )
        }
      </div>
      <Modal isOpen={isOpen} style={modalStyles}>
        <QuizSurveyModal data={quizSurveys[currentId]} closeModal={closeModal}/>
      </Modal>
    </div>
  );
};

ProfileSlider.propTypes = {
  quizSurveys: PropTypes.array.isRequired,
};

export default ProfileSlider;
