import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as subscriptionsActions } from "../redux/actions/subscriptionsActions";
import { actions as userActions } from "../redux/actions/userActions";
import { actions as homePageActions } from "../redux/actions/pagesActions";
import SubscriptionElement from "../components/SubscriptionElement";
import SubscriptionHeader from "../components/SubscriptionHeader";
import InnerHTML from "dangerously-set-html-content";
import PlatformDescription from "../components/PlatformDescription";
import HowItWorkDescription from "../components/HowItWorkDescription";
import LoadingIndicator from "../components/LoadingIndicator";
import Footer from "../components/Footer";
import SubscriptionConfirmationCard from "../components/SubscriptionConfirmationCard";
import SubscriptionConfirmModal from "../components/SubscriptionConfirmModal";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const SubscribePage = ({
  fetchUserData,
  userData,
  fetchSubscriptions,
  subscriptions,
  subscriptionPaymentData,
  fetchHomePageDetails,
  homePageDetails,
  initiatePayment,
}) => {
  const [chosenSubscriptions, setChosenSubscription] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isPurchaseCompleted, setIsPurchaseCompleted] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  useEffect(() => {
    fetchHomePageDetails();
  }, [fetchHomePageDetails, location]);
  const onSubscribe = (sub) => {
    setChosenSubscription(sub);
  };

  const confirmPurchase = (id) => {
    initiatePayment(id);
    fetchUserData();
    setLoading(true);

    /*
      Need some time for task to finish in backend
    */
    setTimeout(() => {
      setLoading(false);
      setIsPurchaseCompleted(true);
    }, 2000);
  };

  if (isPurchaseCompleted && userData) {
    return <SubscriptionConfirmationCard
      invoiceEmail={userData.email}
    />;
  }

  if (subscriptionPaymentData) {
    return <InnerHTML html={subscriptionPaymentData.html_snippet} />;
  }

  if (isLoading || !subscriptions || !userData) {
    return <LoadingIndicator />;
  }

  const formatDescription = (desc) => {
    return desc.split("\n");
  };

  return (
    <div className="w-full -z-10">
      {chosenSubscriptions &&
       <SubscriptionConfirmModal
        name={chosenSubscriptions.name}
        price={chosenSubscriptions.price}
        tax={25}
        color={chosenSubscriptions.color}
        onCancel={() => setChosenSubscription(null)}
        onConfirm={() => confirmPurchase(chosenSubscriptions.id)}
       />
      }
      <SubscriptionHeader executeScroll={executeScroll}/>
      <div className="w-3/4 space-x-8 sm:space-x-0 flex mx-auto justify-between lg:w-4/5 md:w-11/12 sm:flex-col sm:w-full sm:px-6 sm:pt-10" ref={myRef}>
        {subscriptions.results.length > 0 &&
          subscriptions.results.map((sub) => {
            return (
              <SubscriptionElement
                key={`${sub.id}=${sub.name}`}
                name={sub.name}
                licences={sub.licences}
                price={sub.price}
                description={formatDescription(sub.description)}
                color={sub.color}
                onSubscribe={() => onSubscribe(sub)} />
            );
        })}
      </div>
      <div className="px-28 lg:px-16 md:px-12 sm:px-6">
        <HowItWorkDescription />
      </div>
      <PlatformDescription
        title={homePageDetails.boxes_title}
        subtitle={homePageDetails.boxes_subtitle}
        listeningDescription={homePageDetails.listening_box}
        readingDescription={homePageDetails.reading_box}
        videoDescription={homePageDetails.video_box}
      />
      <Footer />
    </div>
  );
};

SubscribePage.propTypes = {
  fetchSubscriptions: PropTypes.func.isRequired,
  subscriptionPaymentData: PropTypes.object,
  fetchUserData: PropTypes.func.isRequired,
  subscriptions: PropTypes.object,
  userData: PropTypes.object,
  fetchHomePageDetails: PropTypes.func.isRequired,
  homePageDetails: PropTypes.object.isRequired,
  initiatePayment: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    subscriptions: state.subscriptions.subscriptions,
    subscriptionPaymentData: state.subscriptions.subscriptionPaymentData,
    userData: state.userData.userdata,
    homePageDetails: state.pages.homePageDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSubscriptions: bindActionCreators(
      subscriptionsActions.fetchSubscriptions,
      dispatch
    ),
    fetchUserData: bindActionCreators(userActions.fetchUserData, dispatch),
    fetchHomePageDetails: bindActionCreators(
      homePageActions.fetchHomePageDetails,
      dispatch
    ),
    initiatePayment: bindActionCreators(
      subscriptionsActions.initiatePayment,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribePage);
