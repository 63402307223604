import React, { useState } from "react";
import PropTypes from "prop-types";
import { GrFormClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../../redux/actions/authActions";
import RegisterFormCompany from "../forms/RegisterFormCompany";

import logo from "../../assets/login-background.png";
import FormError from "../forms/FormError";

const RegisterCompanyModal = ({ register, login, closeModal }) => {
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const onSubmit = ({
    username,
    email,
    password,
    firstName,
    lastName,
    city,
    street,
    postalCode,
    orgNumber,
    orgName,
  }) => {
    setErrors(false);
    setLoading(true);
    register(username, email, password, firstName, lastName, city, street, postalCode, orgNumber, orgName)
      .then(() => {
        login(email, password).then(() => {
          history.push("/prenumerationer");
        });
      })
      .catch((e) => {
        setErrors(e);
        setLoading(false);
      });
  };

  return (
    <div className="w-full h-full bg-white flex">
      <div className="h-full w-1/2 relative flex justify-center md:hidden sm:hidden">
        <img className="h-full object-cover w-full" src={logo} alt="logo" />
        <h1 className="text-white absolute bottom-5 text-6xl">
          Learn<span className="font-bold">Flow</span>
          <sup className="text-4xl">®</sup>
        </h1>
      </div>
      <div className="flex flex-col flex-grow px-14 py-10 relative justify-evenly lg:px-6 md:w-2/3 md:px-12 sm:w-full sm:px-6 sm:py-5">
        <div
          onClick={() => closeModal()}
          className="absolute top-10 right-10 cursor-pointer h-md:top-7 h-md:right-7 sm:top-2 sm:right-2"
        >
          <GrFormClose className="w-6 h-6" />
        </div>
        <h1 className="font-semibold text-3xl text-blue-500 h-md:text-2xl sm:text-xl">
          Registrera organisation
        </h1>
        <p className="text-sm py-5 sm:py-1 h-md:py-1">
          Fyll i dina uppgifter och börja med LearnFlow redan idag.
        </p>
        <RegisterFormCompany onSubmit={onSubmit} />
        <div className="h-10">
          {errors && (
            <div className="mt-1 text-center text-red-500 text-sm">
              <FormError error={errors} />
            </div>
          )}
          {loading && (
            <div className="mt-1 text-center text-sm">Läser in...</div>
          )}
        </div>
      </div>
    </div>
  );
};

RegisterCompanyModal.propTypes = {
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    register: bindActionCreators(authActions.register, dispatch),
    login: bindActionCreators(authActions.login, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RegisterCompanyModal);
