import React from "react";
import PropTypes from "prop-types";
import { FormFieldPasswordError } from "./FormFieldError";

// eslint-disable-next-line no-unused-vars
const NewFormPasswordConfirmation = ({ id, name, type = "password", register, watch, errors }) => {
  return (
    <>
      <label htmlFor={id} className="sr-only">
        { name }
      </label>
      <input
        type="password"
        id={id}
        className="form-control mb-4 rounded  py-2 px-3"
        placeholder={name}
        {...register(id, {
          validate: (value) => value === watch("password") || "Passwords don't match."
        })}
      />
      {errors[id]?.type === "validate" && <FormFieldPasswordError />}
    </>
  );
};

NewFormPasswordConfirmation.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  type: PropTypes.string,
  errors: PropTypes.object
};

export default NewFormPasswordConfirmation;
