import React from "react";
import PropTypes from "prop-types";

const CompanyInfo = ({ name, license, invited, total }) => {
  return (
    <div className="w-48per bg-white rounded-card flex flex-col justify-between p-5 sm:w-full sm:mb-4">
      <div className="font-semibold text-3xl m-2 sm:pb-2">{name}</div>
      <div className="flex justify-start md:flex-wrap">
        <div className="border border-gray-100 shadow-md rounded-lg flex flex-col font-semibold py-2 px-3 mr-4 md:mb-4">
          Licens: {license === 1 ? "Pro" : "Basic"}
        </div>
        <div className="border border-gray-100 shadow-md rounded-lg flex flex-col font-semibold py-2 px-3 mr-4">
          Inbjudna: {invited + "/" + total}
        </div>
      </div>
    </div>
  );
};

CompanyInfo.propTypes = {
  name: PropTypes.string.isRequired,
  license: PropTypes.number.isRequired,
  invited: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default CompanyInfo;
