import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as quizActions } from "../redux/actions/quizCourseActions";
import { actions as courseActions } from "../redux/actions/coursesActions";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import QuizQuestion from "../components/QuizQuestion";

const CourseQuiz = ({ fetchQuizCourse, submitQuizCourse, quizDetails, submitCourseProgress }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentAnswer, setCurrentAnswer] = useState();
  const [userAnswers, setUserAnswers] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState();
  const [quizDescription, setQuizDescription] = useState(true);
  let { title } = useParams();
  let history = useHistory();

  useEffect(() => {
    setErrorOccurred(false);
    fetchQuizCourse(title).catch(() => {
      setErrorOccurred(true);
    });
  }, [fetchQuizCourse]);

  useEffect(() => {
    if (!quizDetails) {
      return;
    }
    setCurrentQuestion(quizDetails[0].question[0]);
    setTotalQuestions(quizDetails[0].question.length);
  }, [quizDetails]);

  const currentProgress = () => {
    const currentIdx = quizDetails[0].question.indexOf(currentQuestion);
    return Math.round((currentIdx / totalQuestions) * 100);
  };

  const checkAnswer = (question, answer) => {
    let tmpAnswers = [...userAnswers];
    const qa = {
      question: question,
      answer: answer,
    };
    if (userAnswers.filter((x) => x.question === currentQuestion.id).length > 0 && currentQuestion.multiple_choice === false) {
      tmpAnswers.splice(tmpAnswers.length - 1, 1);
    }
    tmpAnswers.push(qa);
    setUserAnswers(tmpAnswers);
  };

  const uncheckAnswer = (idx) => {
    let tmpAnswers = [...userAnswers];
    tmpAnswers.splice(idx, 1);
    setUserAnswers(tmpAnswers);
  };

  const onNextQuestion = () => {
    const currentIdx = quizDetails[0].question.indexOf(currentQuestion);

    if (ifLastQuestion()) {
      onSubmit(userAnswers);
      return;
    }
    setCurrentQuestion(quizDetails[0].question[currentIdx + 1]);
  };

  const onPreviousQuestion = () => {
    const currentIdx = quizDetails[0].question.indexOf(currentQuestion);
    if (currentIdx === 0) {
      setQuizDescription(true);
    } else {
      setCurrentQuestion(quizDetails[0].question[currentIdx - 1]);
    }
  };

  const returnToCoursePage = () => {
    history.push(`/tema/${title}/`);
  };

  const ifLastQuestion = () => {
    const currentIdx = quizDetails[0].question.indexOf(currentQuestion);
    return currentIdx + 1 === totalQuestions;
  };

  const onSubmit = (ans) => {
    const id = quizDetails[0].id;
    submitQuizCourse(quizDetails[0].id, ans)
    .then(() => {
      submitCourseProgress(title, "quiz", id).then(() => {
        history.push(`/tema/${title}/quiz-resultat/${id}`);
      });
    });
  };

  const checkCurrentIdx = () => {
    const currentIdx = quizDetails[0].question.indexOf(currentQuestion) + 1;
    return currentIdx;
  };

  if (!quizDetails || !currentQuestion) {
    return <LoadingIndicator />;
  }

  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <div className="flex justify-center items-center w-full h-screen bg-quiz-page md:px-12 md:h-3/4 sm:px-6 sm:pt-12 sm:min-h-screen sm:h-auto sm:flex-col sm:justify-evenly">
      {!quizDescription && (
        <div className="w-full flex-col hidden sm:flex sm:my-5">
          <div className="font-bold text-blue-600 pb-2">Fråga {checkCurrentIdx()} av {totalQuestions}</div>
          <div className="bg-gray-300 rounded-lg h-2 w-full relative">
            <div
              className="absolute h-full rounded-lg bg-blue-600"
              style={{ width: currentProgress() + "%" }}
            />
          </div>
        </div>
      )}
      <div
        className={"w-3/4 h-3/4 shadow-accent rounded-lg border border-gray-100 py-10 px-8 flex flex-col " +
        "justify-between bg-white md:w-full md:px-6 sm:w-full sm:px-6 sm:justify-center sm:rounded-md sm:h-auto sm:mb-5"}>
        {
          quizDescription ? (
            <>
              <div className="py-10 h-full flex flex-col justify-center items-center sm:py-0 w-1/2 sm:w-full mx-auto">
                <h2 className="font-bold text-3xl mb-2 md:text-2xl sm:text-lg sm:text-left">
                  {quizDetails[0].name}
                </h2>
                <div
                  className="text-center my-3"
                  dangerouslySetInnerHTML={{
                    __html: quizDetails[0].description
                  }}
                >
                </div>
                <div className="flex justify-center flex-col flex-wrap my-5 m-auto md:justify-evenly sm:my-5">
                  <button
                    onClick={() => setQuizDescription(false)}
                    className="bg-blue-600 text-white px-5 py-3 rounded-md bg-white border border-gray-100 font-semibold"
                  >
                    Starta test
                  </button>
                  <button
                    onClick={returnToCoursePage}
                    className="text-gray-500 underline mx-5 my-5"
                  >
                    Tillbaka
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-between items-center">
                <div className="font-bold text-2xl md:text-xl sm:hidden">
                  Quiz:<span className="italic">{quizDetails[0].name}</span>
                </div>
                <div className="w-2/3 flex items-center justify-end md:w-1/2 sm:hidden">
                  <div className="bg-gray-300 rounded-lg h-2 w-2/3 mx-2 relative md:w-4/5">
                    <div
                      className="absolute h-full rounded-lg bg-blue-600 mr-10"
                      style={{ width: currentProgress() + "%" }}
                    />
                  </div>
                  <div className="font-bold pl-3">{currentProgress()} %</div>
                </div>
              </div>
              <QuizQuestion
                currentQuestion={currentQuestion}
                setCurrentAnswer={setCurrentAnswer}
                userAnswers={userAnswers}
                checkAnswer={checkAnswer}
                uncheckAnswer={uncheckAnswer}
                currentAnswer={currentAnswer}
              />
              <div className="flex justify-end py-4 px-10 sm:flex-col sm:hidden">
                <button
                  onClick={onPreviousQuestion}
                  className="text-gray-500 underline"
                >
                  Tillbaka
                </button>
                <button
                  onClick={onNextQuestion}
                  disabled={!userAnswers.filter((x) => x.question === currentQuestion.id).length > 0}
                  className={`${
                    userAnswers.filter((x) => x.question === currentQuestion.id).length > 0 ? "bg-blue-600" : "bg-gray-400"
                  } px-5 py-1 text-white rounded ml-5`}
                >
                  {ifLastQuestion() ? "Submit" : "Nästa"}
                </button>
              </div>
            </>
          )
        }
      </div>
      {!quizDescription && (
        <div className="justify-end sm:flex-col hidden sm:flex w-full">
          <button
            onClick={onNextQuestion}
            disabled={!userAnswers.filter((x) => x.question === currentQuestion.id).length > 0}
            className={`${
              userAnswers.filter((x) => x.question === currentQuestion.id).length > 0 ? "text-blue-600" : "text-gray-500"
            } px-5 py-3 rounded-md bg-white shadow-accent border border-gray-100 font-semibold`}
          >
            {ifLastQuestion() ? "Submit" : "Nästa fråga"}
          </button>
          <button
            onClick={onPreviousQuestion}
            className="text-gray-500 underline my-3"
          >
            Tillbaka
          </button>
        </div>
      )}
    </div>
  );
};

CourseQuiz.propTypes = {
  fetchQuizCourse: PropTypes.func.isRequired,
  submitQuizCourse: PropTypes.func.isRequired,
  quizDetails: PropTypes.array.isRequired,
  submitCourseProgress: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    quizDetails: state.quizCourse.quizCourse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuizCourse: bindActionCreators(quizActions.fetchQuizCourse, dispatch),
    submitQuizCourse: bindActionCreators(
      quizActions.submitQuizCourse,
      dispatch
    ),
    submitCourseProgress: bindActionCreators(courseActions.submitCourseProgress, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseQuiz);
