export const SUBSCRIPTIONS_LIST_REQUEST = "SUBSCRIPTIONS_LIST_REQUEST";
export const SUBSCRIPTIONS_LIST_SUCCESS = "SUBSCRIPTIONS_LIST_SUCCESS";
export const SUBSCRIPTIONS_LIST_FAILURE = "SUBSCRIPTIONS_LIST_FAILURE";

export const SUBSCRIPTION_BUY_REQUEST = "SUBSCRIPTION_BUY_REQUEST";
export const SUBSCRIPTION_BUY_SUCCESS = "SUBSCRIPTION_BUY_SUCCESS";
export const SUBSCRIPTION_BUY_FAILURE = "SUBSCRIPTION_BUY_FAILURE";

export const PAYMENT_CHECK_REQUEST = "PAYMENT_CHECK_REQUEST";
export const PAYMENT_CHECK_SUCCESS = "PAYMENT_CHECK_SUCCESS";
export const PAYMENT_CHECK_FAILURE = "PAYMENT_CHECK_FAILURE";

const fetchSubscriptions = () => ({
  types: [SUBSCRIPTIONS_LIST_REQUEST, SUBSCRIPTIONS_LIST_SUCCESS, SUBSCRIPTIONS_LIST_FAILURE],
  endpoint: "subscriptions/",
  method: "GET"
});

const initiatePayment = (id) => ({
  types: [SUBSCRIPTION_BUY_REQUEST, SUBSCRIPTION_BUY_SUCCESS, SUBSCRIPTION_BUY_FAILURE],
  endpoint: `subscriptions/${id}/buy/`,
  method: "POST"
});

const checkPayment = (id) => ({
  types: [PAYMENT_CHECK_REQUEST, PAYMENT_CHECK_SUCCESS, PAYMENT_CHECK_FAILURE],
  endpoint: `payments/${id}/`,
  method: "GET"
});

export const actions = {
  fetchSubscriptions,
  initiatePayment,
  checkPayment
};

const ACTION_HANDLERS = {
  [SUBSCRIPTIONS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    subscriptions: payload
  }),
  [SUBSCRIPTION_BUY_SUCCESS]: (state, { payload }) => ({
    ...state,
    subscriptionPaymentData: payload
  }),
  [PAYMENT_CHECK_SUCCESS]: (state, { payload }) => ({
    ...state,
    paymentDetails: payload
  })
};

export const initialState = {
  subscriptions: null,
  subscriptionPaymentData: null,
  paymentDetails: null
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
