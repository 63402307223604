import React from "react";
import PropTypes from "prop-types";

const ProfileSummaryCard = ({ title, value }) => {
  return (
    <div className="text-center w-2/7">
      <p className="text-sm font-semibold mb-1">{title}</p>
      <div className="h-20 rounded-card px-5 py-1 flex flex-col justify-evenly sm:px-3">
        <h1 className="text-blue-600 text-4xl font-bold">{value}</h1>
      </div>
    </div>
  );
};

ProfileSummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default ProfileSummaryCard;
