import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import VideoIcon from "./VideoIcon.js";
import ReadingIcon from "./ReadingIcon.js";
import AudioIcon from "./AudioIcon.js";
import example1 from "../assets/example-slider.jpg";
import example2 from "../assets/example-slider-2.jpg";
import example3 from "../assets/example-slider-3.jpg";
import PlatformDescriptionSliderItemMobile from "./PlatformDescriptionSliderItemMobile.js";

const PlatformDescriptionMobile = ({
    listeningDescription,
    readingDescription,
    videoDescription,
  }) => {
  const settings = {
    className: "center landing-slider",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    dots: false,
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => (
      <div
        style={{
          bottom: "0px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };
  return (
    <div className="pt-0">
      <h2 className="text-2xl text-center pt-2 font-semibold px-6 sm:py-6">
        Det är upp till dig om du vill{" "}
        <span className="text-green-500">lyssna</span>,{" "}
        <span className="text-yellow-500">läsa</span> eller{" "}
        <span className="text-blue-500">titta</span>
      </h2>
      <div className="w-full h-96 mb-14">
        <Slider {...settings}>
          <PlatformDescriptionSliderItemMobile
            title={"Lyssna"}
            description={listeningDescription ? listeningDescription : "I alla kunskapsteman finns ett antal ljudfiler med innehållet för dig att lyssna på, antingen på datorn eller i mobilen. Du kan göra det närhelst du har tid och när det passar dig som bäst."}
            image={example2}
            icon={<AudioIcon type="gradient"/>}
          />
          <PlatformDescriptionSliderItemMobile
            title={"Läsa"}
            description={readingDescription ? readingDescription : "Du kan även välja att läsa dig till vad kunskapstemat innehåller, då varje tema innehåller ett antal korta textkapitel, med samma innehåll som i Lyssna eller Titta."}
            image={example3}
            icon={<ReadingIcon type="gradient"/>}
          />
          <PlatformDescriptionSliderItemMobile
            title={"Titta"}
            description={videoDescription ? videoDescription : "Du har också möjlighet att ta del av kunskapstemat genom ett antal kortare videoföredrag om det bäst passar ditt sätt att lära dig på. Det går naturligtvis också bra att kombinera Lyssna, Läsa och Titta."}
            image={example1}
            icon={<VideoIcon type="gradient"/>}
          />
        </Slider>
      </div>
    </div>
  );
};

PlatformDescriptionMobile.propTypes = {
  listeningDescription: PropTypes.string,
  readingDescription: PropTypes.string,
  videoDescription: PropTypes.string,
};

export default PlatformDescriptionMobile;
