import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Footer = ({ isLoggedIn }) => {
  return (
    <>
      <div className="border-gradient h-2 w-full"></div>
      <div className="w-full px-28 lg:px-16 md:px-12 sm:px-6 sm:bg-black">
        <div className="py-10 flex items-baseline justify-between max-w-6xl mx-auto sm:flex-col sm:items-start sm:pt-0">
          <div>
            <div className="logo-gradient text-5xl py-5 md:text-3xl sm:text-3xl">
              Learn<span className="font-semibold">Flow</span>
            </div>
            <p className="text-xs max-w-xs tracking-wide sm:text-white sm:w-5/6">
              LearnFlow® är ett unikt och digitaliserat pedagogiskt verktyg som
              bygger på vetenskaplig grund och praktisk erfarenhet.
            </p>
          </div>
          <div className="flex flex-col justify-end sm:text-white sm:border-b sm:border-white sm:w-full sm:py-4">
            <div className="font-semibold py-2 md:text-sm sm:text-sm sm:py-1">
              Om LearnFlow
            </div>
            <Link
              to={isLoggedIn ? "/" : "/teman"}
              className="py-1 font-light text-sm md:text-xs sm:text-xs"
            >
              Teman
            </Link>
            <Link
              to="/om-oss"
              className="py-1 font-light text-sm md:text-xs sm:text-xs"
            >
              Om oss
            </Link>
            <Link
              to="/hur-det-fungerar"
              className="py-1 font-light text-sm md:text-xs sm:text-xs"
            >
              Hur det fungerar
            </Link>
          </div>
          <div className="flex flex-col justify-end sm:text-white pt-4">
            <div className="font-semibold py-2 md:text-sm sm:text-sm sm:py-1">
              Om Webbplats
            </div>
            <Link
              to="/kopvillkor"
              className="py-1 font-light text-sm md:text-xs sm:text-xs"
            >
              Köpvillkor
            </Link>
            <Link
              to="/integritetspolicy"
              className="py-1 font-light text-sm md:text-xs sm:text-xs"
            >
              Integritetspolicy
            </Link>
            <Link
              to="/kakor"
              className="py-1 font-light text-sm md:text-xs sm:text-xs"
            >
              Kakor
            </Link>
          </div>
          <div className="flex flex-col justify-end sm:hidden">
            <div className="font-semibold py-2 md:text-sm">LearnFlow</div>
            <a
              href="mailto:kontakt@learnflow.se"
              className="py-1 font-light text-sm md:text-xs"
            >
              kontakt@learnflow.se
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.access != null,
  };
}

export default connect(mapStateToProps, null)(Footer);
