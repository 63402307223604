import React, { useRef, useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { actions as courseActions } from "../redux/actions/coursesActions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const VideoVimeoMobilePlayer = ({ id, idx, vimeoUrl, submitCourseProgress }) => {
  const vimeoRef = useRef(null);
  const { title } = useParams();

  useEffect(() => {
    if (vimeoRef.current) {
      const savedTime = JSON.parse(localStorage.getItem(`videoVimeoPlaybackTime-${title}-${idx}`));
      if (savedTime) {
        vimeoRef.current.player.setCurrentTime(savedTime);
      }

      vimeoRef.current.player.on("timeupdate", (data) => {
        localStorage.setItem(`videoVimeoPlaybackTime-${title}-${idx}`, JSON.stringify(data.seconds));
      });

      vimeoRef.current.player.on("pause", () => {
        savePlaybackTime();
      });

      vimeoRef.current.player.on("ended", () => {
        localStorage.removeItem(`videoVimeoPlaybackTime-${title}-${idx}`);
      });
    }
  }, [vimeoUrl]);

  const savePlaybackTime = () => {
    if (vimeoRef.current) {
      vimeoRef.current.player.getCurrentTime().then((currentTime) => {
        localStorage.setItem(`videoVimeoPlaybackTime-${title}-${idx}`, JSON.stringify(currentTime));
      });
    }
  };

  return (
    <div className="relative h-2/5 w-8/12 flex flex-col justify-between rounded-bl-lg rounded-tl-lg mx-auto md:w-11/12 sm:w-full sm:mb-5">
      <Vimeo
        key={vimeoUrl}
        video={vimeoUrl}
        ref={vimeoRef}
        responsive={true}
        onEnd={() => {
          submitCourseProgress(title, "video", id);
        }}
      />
    </div>
  );
};

VideoVimeoMobilePlayer.propTypes = {
  id: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  vimeoUrl: PropTypes.string.isRequired,
  submitCourseProgress: PropTypes.func.isRequired,
  handleVideoEnd: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    submitCourseProgress: bindActionCreators(courseActions.submitCourseProgress, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(VideoVimeoMobilePlayer);
