import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CourseProfile from "./CourseProfile";
import HighlightedCourseProfile from "./HighlightedCourseProfile";
import "./CoursesCarousel.css";

const CoursesCarousel = ({ courses }) => {
  const [coursesList, setCoursesList] = useState([]);
  const [highlightedCourse, setHighlightedCourse] = useState();

  useEffect(() => {
    const c = [...courses];
    const [highlighted, ...rest] = c;
    setCoursesList(rest);
    setHighlightedCourse(highlighted);
  }, [courses]);

  const onNextCourse = () => {
    const current = highlightedCourse;
    let tempList = coursesList;
    setHighlightedCourse(tempList.shift());
    tempList.push(current);
    setCoursesList(tempList);
  };

  const onPrevCourse = () => {
    const current = highlightedCourse;
    let tempList = coursesList;
    setHighlightedCourse(tempList.pop());
    tempList = [current, ...tempList];
    setCoursesList(tempList);
  };
  if (highlightedCourse == null) {
    return <div>Loading</div>;
  }
  return (
    <div className="pt-5 pb-28 max-w-screen-2xl w-full flex m-auto lg:max-w-none overflow-hidden">
      <HighlightedCourseProfile
        course={highlightedCourse}
        onNext={onNextCourse}
        onPrev={onPrevCourse}
      />
      <div className="w-4/6 overflow-hidden">
        <div className="text-2xl pb-2">Rekommenderat för dig</div>
        <div className="flex w-5000">
          <CourseProfile courses={coursesList} />
        </div>
      </div>
    </div>
  );
};

CoursesCarousel.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default CoursesCarousel;
