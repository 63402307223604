import React from "react";
import PropTypes from "prop-types";
import SurveyQuestion from "./SurveyQuestion";
import { FormFieldRequiredError } from "./forms/FormFieldError";

const SurveySection = ({ questions, answers, pickAnswer, error }) => {
  const findAnsForQuestion = (id) => {
    const ans = answers.find((el) => el.question === id);
    return ans ? ans.answer : undefined;
  };

  return (
    <div className="bg-white shadow-accent rounded-md p-4 text-lg w-11/12 sm:w-full sm:shadow-none sm:px-6 sm:pb-0 max-h-8/10 overflow-y-scroll scroller">
      <div className="flex items-center sm:hidden">
        <div className="w-1/2 font-semibold text-sm">
          Vänligen svara noggrant på varje< br/>enkätpåstående nedan.
        </div>
        <div className="w-1/2 flex flex-col items-center">
          <div className="flex justify-between text-sm text-center w-full">
            <p>Nästan< br/>aldrig</p>
            <p>Nästan< br/>alltid</p>
          </div>
          <div className="flex justify-between text-sm w-11/12">
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <p>4</p>
            <p>5</p>
            <p>6</p>
          </div>
        </div>
      </div>
      {questions?.map((q) => {
        return (
          <SurveyQuestion
            key={q.id}
            question={q}
            answer={findAnsForQuestion(q.id)}
            pickAnswer={pickAnswer}
          />
        );
      })}
      {error && <FormFieldRequiredError />}
    </div>
  );
};

SurveySection.propTypes = {
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  pickAnswer: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default SurveySection;
