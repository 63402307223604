import React from "react";
import PropTypes from "prop-types";

const QuizQuestion = ({ currentQuestion, userAnswers, checkAnswer, uncheckAnswer }) => {
  const isChecked = (id) => {
    if (userAnswers.filter((x) => x.answer === id).length > 0) {
      return true;
    }
    return false;
  };

  const setAnswer = (questionId, answerId) => {
    if (isChecked(answerId)) {
      const idx = userAnswers.findIndex((x) => x.answer == answerId);
      uncheckAnswer(idx);
    } else {
      checkAnswer(questionId, answerId);
    }
  };

  return (
    <div className="py-10 h-3/4 flex flex-col justify-center items-center sm:py-0">
      <h2 className="font-bold text-xl text-blue-600 mb-2 md:text-2xl sm:text-lg sm:font-normal sm:text-left">
        {currentQuestion.name}
      </h2>
      <div className="text-center">
        {currentQuestion.content}
      </div>
      <div className="flex justify-center flex-wrap my-5 w-full m-auto md:justify-evenly sm:my-5">
        {currentQuestion.answer.map((el) => (
          <button
            className={`${
              isChecked(el.id) ? "bg-blue-600 text-white" : "bg-white text-gray-500"
            } py-4 px-12 shadow-accent rounded-lg w-5/12 mx-4 my-2 font-bold sm:w-full sm:mx-0 sm:py-2 sm:text-left sm:px-4 sm:font-normal`}
            key={el.id}
            onClick={() => setAnswer(currentQuestion.id, el.id)}
          >
            {el.name}
          </button>
        ))}
      </div>
    </div>
  );
};

QuizQuestion.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  userAnswers: PropTypes.array.isRequired,
  checkAnswer: PropTypes.func.isRequired,
  uncheckAnswer: PropTypes.func.isRequired,
};

export default QuizQuestion;
