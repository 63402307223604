import React from "react";
import PropTypes from "prop-types";
import CoursesSlider from "./CoursesSlider";
import LoadingIndicator from "../LoadingIndicator";

const CoursesProfileSlider = ({ courses, title, description, completed }) => {
  if (!courses) {
    return <LoadingIndicator />;
  }

  return (
    <div className="w-48per bg-white rounded-card flex flex-col justify-between border border-gray-100 sm:hidden">
      <div className="border-b border-gray-300 py-4">
        <div className="font-semibold text-lg px-5 md:px-4">
          {title}
        </div>
        <p className={`${description ? "" : "pb-4"} text-gray-500 text-xs px-5 md:px-4`}>
          {description}
        </p>
      </div>
      <div className="py-4 px-6 w-full md:px-4 h-full">
        {courses.length > 0 ? (
          <CoursesSlider courses={courses} completed={completed} />
        ) : (
          <div className="text-center text-sm text-gray-400">Du har inga pågående teman</div>
        )}
      </div>
    </div>
  );
};

CoursesProfileSlider.propTypes = {
  courses: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  completed: PropTypes.bool,
};

export default CoursesProfileSlider;
