import React from "react";

const HowItWorkDescription = () => {
  const elements = [
    {
      id: 1,
      name: "Nulägesanalys",
      text: "Genomför en nulägesanalys utifrån lärande organisations Frågeformulär (DLOQ-test).",
    },
    {
      id: 2,
      name: "Teman",
      text: "Välj och studera ett utbildningstema utifrån text, videoföredrag och ljudfil.",
    },
    {
      id: 3,
      name: "Quiz",
      text: "Genomför ett quiz på temat och repeterar delar av temat vid felaktiga svar.",
    },
  ];

  return (
    <div className="py-10 lg:py-6 md:py-4 sm:px-6">
      <h2 className="text-2xl text-center pt-16 font-bold sm:py-5 sm:font-semibold">
        Hur det fungerar.
      </h2>
      <div className="text-gray-500 py-5 text-center md:text-sm sm:hidden">
        Skapa ett learnflow konto och vidareutbilda din personal genom kunskapsteman
      </div>
      <div className="flex justify-between max-w-6xl m-auto sm:flex-col">
        {elements.map((el, idx) => (
          <React.Fragment key={idx}>
            <div
              className="w-80 h-44 bg-white rounded-card p-6 flex flex-col lg:w-2/7 lg:h-48 md:w-2/7 md:h-48 md:p-4 sm:hidden"
            >
              <div className="flex items-center sm:flex-row">
                <div
                  className="bg-gray-300 text-white text-xl font-bold rounded-lg w-10 h-10 flex items-center justify-center"
                >
                  {el.id}
                </div>
                <div className="font-bold text-xl ml-4 md:text-base">
                  {el.name}
                </div>
              </div>
              <div className="text-sm max-h-20 mt-5 text-gray-500">
                {el.text}
              </div>
            </div>
            <div
              className={`${idx === 1 ? "flex-row-reverse text-right" : ""} hidden sm:flex justify-between items-center py-5`}
            >
              <div className="w-10 h-10 flex items-center justify-center sm:text-black sm:text-3xl sm:rounded-md sm:w-16 sm:h-16 bg-white text-white text-xl font-bold rounded-lg">
                {el.id}
              </div>
              <div className="w-3/4">
                <div className="font-semibold text-xl">
                  {el.name}
                </div>
                <div className="text-sm max-h-20">
                  Skapa ett learnflow konto och vidareutbilda din personal genom kunskapsteman
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HowItWorkDescription;
