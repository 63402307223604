export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAILURE = "USER_DATA_FAILURE";
export const SUBSCRIPTION_EMAIL_SUCCESS = "SUBSCRIPTION_EMAIL_SUCCESS";
export const SUBSCRIPTION_EMAIL_FAILURE = "SUBSCRIPTION_EMAIL_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_COMPLETE_REQUEST = "RESET_PASSWORD_COMPLETE_REQUEST";
export const RESET_PASSWORD_COMPLETE_SUCCESS = "RESET_PASSWORD_COMPLETE_SUCCESS";
export const RESET_PASSWORD_COMPLETE_FAILURE = "RESET_PASSWORD_COMPLETE_FAILURE";
export const RESET_PASSWORD_TOKEN_REQUEST = "RESET_PASSWORD_TOKEN_REQUEST";
export const RESET_PASSWORD_TOKEN_SUCCESS = "RESET_PASSWORD_TOKEN_SUCCESS";
export const RESET_PASSWORD_TOKEN_FAILURE = "RESET_PASSWORD_TOKEN_FAILURE";

const fetchUserData = () => ({
  types: [USER_DATA_SUCCESS, USER_DATA_FAILURE],
  endpoint: "current-user/",
  method: "GET",
});

const sendSubscriptionEmail = (email, licenses) => ({
  types: [SUBSCRIPTION_EMAIL_SUCCESS, SUBSCRIPTION_EMAIL_FAILURE],
  endpoint: "send-subscription-email/",
  method: "POST",
  body: {
    "email": email,
    "licenses": licenses,
  },
});

const sendResetPasswordEmail = (email) => ({
  types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
  endpoint: "reset-password/",
  method: "POST",
  body: {
    "email": email,
  },
});

const submitResetPassword = (token, password) => ({
  types: [RESET_PASSWORD_COMPLETE_REQUEST, RESET_PASSWORD_COMPLETE_SUCCESS, RESET_PASSWORD_COMPLETE_FAILURE],
  endpoint: `reset-password/complete/${token}/`,
  method: "POST",
  body: {
    "password": password,
  },
});

const validateResetPasswordToken = (token) => ({
  types: [RESET_PASSWORD_TOKEN_REQUEST, RESET_PASSWORD_TOKEN_SUCCESS, RESET_PASSWORD_TOKEN_FAILURE],
  endpoint: `reset-password/complete/${token}`,
  method: "GET",
});

export const actions = {
  fetchUserData,
  sendSubscriptionEmail,
  sendResetPasswordEmail,
  submitResetPassword,
  validateResetPasswordToken,
};

const ACTION_HANDLERS = {
  [USER_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    userdata: payload,
  }),
  [USER_DATA_FAILURE]: (state, { payload }) => ({
    ...state,
    userdata: payload,
  }),
  [SUBSCRIPTION_EMAIL_SUCCESS]: (state) => ({
    ...state,
  }),
  [SUBSCRIPTION_EMAIL_FAILURE]: (state) => ({
    ...state,
  }),
  [RESET_PASSWORD_SUCCESS]: (state) => ({
    ...state,
  }),
  [RESET_PASSWORD_FAILURE]: (state) => ({
    ...state,
  }),
  [RESET_PASSWORD_COMPLETE_REQUEST]: (state) => ({
    ...state,
  }),
  [RESET_PASSWORD_COMPLETE_FAILURE]: (state) => ({
    ...state,
  }),
  [RESET_PASSWORD_TOKEN_SUCCESS]: (state) => ({
    ...state,
  }),
  [RESET_PASSWORD_TOKEN_FAILURE]: (state) => ({
    ...state,
  }),
};

export const initialState = {
  userdata: null,
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
