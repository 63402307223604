import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../redux/actions/pagesActions";
import isEmptyObject from "../utils/isEmptyObject";
import img from "../assets/subscribe-background.png";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import Footer from "../components/Footer";

const ContentLoggedOutPage = ({ fetchPageDetails, pageDetails }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);

  const location = useLocation();
  const slug = location.pathname.replace("/", "");

  useEffect(() => {
    setErrorOccurred(false);
    fetchPageDetails(slug).catch(() => {
      setErrorOccurred(true);
    });
  }, [fetchPageDetails, location, slug]);

  if (isEmptyObject(pageDetails)) {
    return <LoadingIndicator />;
  }

  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <>
      <div className="w-full h-auto bg-black flex flex-col justify-between pb-20 sm:pt-10">
        <div className="h-auto w-full pt-10 flex justify-between items-center">
          <div className="filter brightness-75 sm:hidden">
            <img src={img} className="sm:w-full sm:object-cover" />
          </div>
          <div className="flex justify-center w-full items-center flex-col text-white sm:px-6 sm:py-0 sm:h-full">
            <div className="text-lg pb-10">{pageDetails.title}</div>
            <div className="text-3xl sm:text-2xl text-center max-w-4xl">
              {pageDetails.subtitle}
            </div>
          </div>
          <div className="filter brightness-75 sm:hidden">
            <img src={img} className="sm:w-full sm:object-cover" />
          </div>
        </div>
        <div className="w-3/5 mx-auto h-auto text-white flex flex-col justify-center lg:w-9/12 md:w-4/5 md:pb-10 sm:w-full sm:px-6 bg-black sm:pb-20">
          <div
            className="page-content sm:text-center"
            dangerouslySetInnerHTML={{ __html: pageDetails.content }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

ContentLoggedOutPage.propTypes = {
  fetchPageDetails: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    pageDetails: state.pages.pageDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPageDetails: bindActionCreators(
      actions.fetchPageDetails,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentLoggedOutPage);
