import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as coursesActions } from "../redux/actions/coursesActions";
import { useHistory } from "react-router-dom";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import CoursesCarousel from "../components/CoursesCarousel";
import Footer from "../components/Footer";
import CoursesCarouselMobile from "../components/CoursesCarouselMobile";
import IndividualCoursesAuthenticated from "../components/IndividualCoursesAuthenticated";
import GroupCoursesAuthenticated from "../components/GroupCoursesAuthenticated";
import OrganizationCoursesAuthenticated from "../components/OrganizationCoursesAuthenticated";
import Breadcrumbs from "../components/Breadcrumbs";

const CoursesPage = ({ fetchAllCourses, courses, userData, userType, survey }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (!userData) {
      return;
    }
    if (userType === "company" && !userData.profile_data.is_subscribed) {
      history.push("/prenumerationer");
    }

    if (userType === "employee" && !survey) {
      history.push("/enkat");
    }

  }, [userData, survey, userType]);

  useEffect(() => {
    setIsLoading(true);
    setErrorOccurred(false);
    fetchAllCourses()
      .catch(() => {
        setErrorOccurred(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchAllCourses]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <>
      <div className="pt-8">
        <div className="pt-16 sm:hidden mx-auto pl-28 lg:pl-6 md:pl-6 sm:pl-0">
          <Breadcrumbs />
          <CoursesCarousel courses={courses} />
        </div>
        <div className="hidden sm:block">
          <CoursesCarouselMobile courses={courses} />
        </div>
        <div className="px-28 lg:px-6 md:px-6 sm:px-0">
          <IndividualCoursesAuthenticated />
          <GroupCoursesAuthenticated />
          <OrganizationCoursesAuthenticated />
        </div>
      </div>
      <Footer />
    </>
  );
};

CoursesPage.propTypes = {
  fetchAllCourses: PropTypes.func.isRequired,
  courses: PropTypes.array.isRequired,
  userData: PropTypes.object,
  userType: PropTypes.string,
  survey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

function mapStateToProps(state) {
  return {
    courses: state.courses.courses,
    userData: state.userData.userdata,
    userType: state.auth.userType,
    survey: state.auth.survey,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCourses: bindActionCreators(
      coursesActions.fetchAllCourses,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesPage);
