import React from "react";
import PropTypes from "prop-types";
import { ImArrowRight2 } from "react-icons/im";

const VideoChapter = ({ idx, name, currentIdx, onVideoChange }) => {
  if (idx !== currentIdx) {
    return (
      <div className="bg-white shadow-accent w-56 h-44 p-4 flex flex-col justify-around mb-2 mr-10 md:mr-4">
        <div className="bg-blue-500 text-white rounded-md flex justify-center items-center w-16 font-bold">
          Del {idx + 1}
        </div>
        <div className="font-bold">{name}</div>
        <button className="text-blue-500 font-bold flex items-center" onClick={() => onVideoChange(idx)}>
          Titta
          <ImArrowRight2 className="ml-2" />
        </button>
      </div>
    );
  }

  return <></>;
};

VideoChapter.propTypes = {
  idx: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  currentIdx: PropTypes.number.isRequired,
  onVideoChange: PropTypes.func.isRequired,
};

export default VideoChapter;
