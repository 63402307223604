import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ImArrowRight2 } from "react-icons/im";
import ReadingIcon from "./ReadingIcon";

const ReadingBanner = ({ courseTitle, readingData }) => {
  return (
    <div className="h-64 shadow-accent rounded-lg p-6 flex flex-col justify-between md:p-4 md:h-56 w-2/7 sm:w-full sm:h-52 sm:mb-4 sm:border sm:border-gray-100">
      <div className="sm:flex">
        <ReadingIcon type="color"/>
        <div className="font-bold text-xl md:text-lg pt-2 sm:text-yellow-500 sm:ml-4">Läsa</div>
      </div>
      <div className="text-sm max-h-20 overflow-hidden sm:text-gray-600">{readingData}</div>
      <Link
        to={`/tema/${courseTitle}/lasning`}
        className="text-yellow-500 flex items-center md:text-sm self-end"
      >
        Läs
        <ImArrowRight2 className="ml-2" />
      </Link>
    </div>
  );
};

ReadingBanner.propTypes = {
  courseTitle: PropTypes.string.isRequired,
  readingData: PropTypes.string.isRequired,
};

export default ReadingBanner;
