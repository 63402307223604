import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Achievement from "./Achievement";

const ProfileAchievements = ({ achievements }) => {
  const [finished, setFinished] = useState([]);
  const [started, setStarted] = useState([]);
  const [notStarted, setNotStarted] = useState([]);

  useEffect(() => {
    const tmpFinished = achievements.filter((el) => el.status);
    const tmpStarted = achievements.filter((el) => el.current_score > 0 && !el.status);
    const tmpNotStarted = achievements.filter((el) => el.current_score === 0 && !el.status);

    setFinished(tmpFinished);
    setStarted(tmpStarted);
    setNotStarted(tmpNotStarted);

  }, [achievements]);

  return (
    <div className="w-48per h-96 bg-white rounded-card md:h-auto sm:w-full sm:mb-4 overflow-y-scroll scroller">
      <div className="border-b border-gray-300 py-4 sm:border-0">
        <div className="font-semibold text-lg px-5 md:px-4 sm:text-center">
          Prestationer
        </div>
        <p className="text-gray-500 text-xs px-5 md:px-4 sm:hidden">
          Här visas vilka prestationer du kan låsa upp genom att använda
          LearnFlow.
        </p>
      </div>
      <div className="flex flex-wrap justify-around items-stretch h-72 py-2 md:h-3/4 sm:h-auto sm:py-0 sm:px-6">
        {achievements && finished.length > 0 && (
          <div className="w-full">
            <div className="font-semibold text-sm px-5 pt-4 md:px-4 sm:text-center">
              Avslutade
            </div>
            <div className="flex flex-wrap justify-between items-stretch px-5">
              {finished.map((el, idx) => (
                <Achievement
                  key={idx}
                  name={el.name}
                  description={el.description}
                  status={el.status}
                  currentScore={el.current_score}
                  maxScore={el.max_score}
                  color={el.color}
                />
              ))}
            </div>
          </div>
        )}
        {achievements && started.length > 0 && (
          <div className="w-full">
            <div className="font-semibold text-sm px-5 pt-4 md:px-4 sm:text-center">
              Pågående
            </div>
            <div className="flex flex-wrap justify-between items-stretch px-5">
              {started.map((el, idx) => (
                <Achievement
                  key={idx}
                  name={el.name}
                  description={el.description}
                  status={el.status}
                  currentScore={el.current_score}
                  maxScore={el.max_score}
                  color={el.color}
                />
              ))}
            </div>
          </div>
        )}
        {achievements && notStarted.length > 0 && (
          <div className="w-full">
            <div className="font-semibold text-sm px-5 pt-4 md:px-4 sm:text-center">
              Inte påbörjade
            </div>
            <div className="flex flex-wrap justify-between items-stretch px-5">
              {notStarted.map((el, idx) => (
                <Achievement
                  key={idx}
                  name={el.name}
                  description={el.description}
                  status={el.status}
                  currentScore={el.current_score}
                  maxScore={el.max_score}
                  color={el.color}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ProfileAchievements.propTypes = {
  achievements: PropTypes.array.isRequired,
};

export default ProfileAchievements;
