import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../redux/actions/authActions";
import { actions } from "../redux/actions/userActions";
import InviteFormEmployee from "../components/forms/InviteFormEmployee";
import CompanyInfo from "../components/dashboard/CompanyInfo";
import LoadingIndicator from "../components/LoadingIndicator";
import Reports from "../components/dashboard/Reports";
import CompanyUserList from "../components/dashboard/CompanyUserList";

const DashBoardCompanyPage = ({ inviteUser, fetchUserData, userdata }) => {
  const [emails, setEmails] = useState([""]);
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (!userdata || loading) {
    return <LoadingIndicator />;
  }

  if (errors) {
    return <div>Error occurred</div>;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setErrors(false);
    setLoading(true);
    inviteUser(emails)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        fetchUserData();
      })
      .catch(() => {
        setErrors(true);
        setLoading(false);
      });
  };

  const addEmail = () => {
    let tmp = [...emails];
    tmp.push("");
    setEmails(tmp);
  };

  const updateEmail = (index, value) => {
    let tmp = [...emails];
    tmp[index] = value;
    setEmails(tmp);
  };

  return (
    <div className="px-28 py-10 max-w-8xl mx-auto lg:px-12 md:px-12 sm:px-6 sm:py-10 mt-10">
      <div className="flex justify-between mt-10 sm:flex-col sm:mt-10">
        <CompanyInfo
          name={userdata.profile_data.name}
          license={userdata.profile_data.subscription_type}
          invited={userdata.profile_data.invited_users}
          total={userdata.profile_data.user_limit}
        />

        <Reports />
      </div>
      <div className="flex justify-between mt-10 md:mt-6 md:flex-col sm:mt-0 sm:flex-col">
        <InviteFormEmployee
          onSubmit={onSubmit}
          success={success}
          emails={emails}
          addEmail={addEmail}
          updateEmail={updateEmail}
        />
        <CompanyUserList invitedUsers={userdata.invited_users} />
      </div>
    </div>
  );
};

DashBoardCompanyPage.propTypes = {
  inviteUser: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  userdata: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    userdata: state.userData.userdata,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    inviteUser: bindActionCreators(authActions.inviteUser, dispatch),
    fetchUserData: bindActionCreators(actions.fetchUserData, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashBoardCompanyPage);
