export const AUDIO_COURSE_REQUEST = "AUDIO_COURSE_REQUEST";
export const AUDIO_COURSE_SUCCESS = "AUDIO_COURSE_SUCCESS";
export const AUDIO_COURSE_FAILURE = "AUDIO_COURSE_FAILURE";

export const RESET_AUDIO_DETAILS = "RESET_AUDIO_DETAILS";

const fetchAudioCourse = (title) => ({
  types: [AUDIO_COURSE_REQUEST, AUDIO_COURSE_SUCCESS, AUDIO_COURSE_FAILURE],
  endpoint: `course/audio/${encodeURIComponent(title)}`,
  method: "GET",
});

const resetAudioDetails = () => {
  return {
    type: RESET_AUDIO_DETAILS,
  };
};

export const actions = {
  fetchAudioCourse,
  resetAudioDetails,
};

const ACTION_HANDLERS = {
  [AUDIO_COURSE_SUCCESS]: (state, { payload }) => ({
    ...state,
    audioCourse: payload,
  }),
  [RESET_AUDIO_DETAILS]: (state) => ({
    ...state,
    audioCourse: []
  }),
};

export const initialState = {
  audioCourse: [],
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
