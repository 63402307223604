import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ImArrowRight2 } from "react-icons/im";

const MobileCoursesSlider = ({ ongoingCourses, completedCourses }) => {
  const [ongoingThemes, setOngoingThemes] = useState(true);

  const toggleActive = () => {
    setOngoingThemes(!ongoingThemes);
  };

  return (
    <div className="w-full">
      <div className="flex w-full justify-between h-12 px-6">
        <div
          onClick={() => toggleActive()}
          className={`${
            ongoingThemes ? "border-blue-500 border-b-4" : "text-gray-400"
          } text-center py-4 font-semibold cursor-pointer`}
        >
          Pågående teman
        </div>
        <div
          onClick={() => toggleActive()}
          className={`${
            ongoingThemes ? "text-gray-400" : "border-blue-500 border-b-4"
          } text-center py-4 font-semibold cursor-pointer`}
        >
          Avslutade teman
        </div>
      </div>
      <div className="w-full bg-gray-100 px-6">
        {ongoingThemes ? (
          <div className="py-6">
            {ongoingCourses &&
              ongoingCourses.map((el, idx) => (
                <div className="flex py-2" key={idx}>
                  <img src={el.image} className="w-28 h-32 rounded object-cover" />
                  <div className="pl-4 flex flex-col justify-between">
                    <div className="font-semibold text-lg leading-5">
                      {el.title}
                    </div>
                    <div className="text-xs">{el.description}</div>
                    <Link to={`/tema/${encodeURIComponent(el.slug)}`} className="flex items-center">
                      <span className="underline text-sm">
                        Läs, titta eller lyssna
                      </span>
                      <ImArrowRight2 className="ml-2" />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="py-6">
            {completedCourses &&
              completedCourses.map((el) => (
                <div className="flex py-2" key={`completed-${el.title}`}>
                  <img src={el.image} className="w-28 h-32 rounded object-cover" />
                  <div className="pl-4 flex flex-col justify-between">
                    <div className="font-semibold text-lg leading-5">
                      {el.title}
                    </div>
                    <div className="text-xs">{el.description}</div>
                    <Link to={`/tema/${encodeURIComponent(el.slug)}`} className="flex items-center">
                      <span className="underline text-sm">
                        Läs, titta eller lyssna
                      </span>
                      <ImArrowRight2 className="ml-2" />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

MobileCoursesSlider.propTypes = {
  ongoingCourses: PropTypes.array,
  completedCourses: PropTypes.array,
};

export default MobileCoursesSlider;
