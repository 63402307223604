import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(["cookie-consent"]);

  const acceptCookie = () => {
    setCookie("cookie-consent", "accepted", { path: "/" });
  };

  return (
    <div
      className={`p-5 fixed w-96 sm:w-72 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-accent rounded-lg ${cookies["cookie-consent"] ? "hidden" : ""}`}
    >
      <div className="flex flex-col">
        <div className="px-3 mb-5">
          <p className="text-sm text-gray-500 font-medium text-center">
            Den här sidan använder cookies för att analysera trafik och skapa en
            bättre användningsupplevelse. Genom att fortsätta använda
            webbplatsen godkänner du detta.
          </p>
        </div>
        <div className="flex justify-center space-x-4 whitespace-nowrap">
          <button
            className="py-2 px-5 bg-blue-500 border border-blue-500 hover:bg-white hover:text-blue-500 text-white rounded-lg font-bold text-xs shadow-xl"
            onClick={() => acceptCookie()}
          >
            Jag förstår
          </button>
          <button className="py-2 px-5 bg-white border border-blue-500 hover:bg-blue-500 hover:text-white text-blue-500 rounded-lg font-bold text-xs shadow-xl">
            <Link to="/kakor">Läs mer</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
