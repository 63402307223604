export const POPULAR_COURSES_REQUEST = "POPULAR_COURSES_REQUEST";
export const POPULAR_COURSES_SUCCESS = "POPULAR_COURSES_SUCCESS";
export const POPULAR_COURSES_FAILURE = "POPULAR_COURSES_FAILURE";

export const GROUP_COURSES_REQUEST = "GROUP_COURSES_REQUEST";
export const GROUP_COURSES_SUCCESS = "GROUP_COURSES_SUCCESS";
export const GROUP_COURSES_FAILURE = "GROUP_COURSES_FAILURE";

export const INDIVIDUAL_COURSES_REQUEST = "INDIVIDUAL_COURSES_REQUEST";
export const INDIVIDUAL_COURSES_SUCCESS = "INDIVIDUAL_COURSES_SUCCESS";
export const INDIVIDUAL_COURSES_FAILURE = "INDIVIDUAL_COURSES_FAILURE";

export const ORGANIZATION_COURSES_REQUEST = "ORGANIZATION_COURSES_REQUEST";
export const ORGANIZATION_COURSES_SUCCESS = "ORGANIZATION_COURSES_SUCCESS";
export const ORGANIZATION_COURSES_FAILURE = "ORGANIZATION_COURSES_FAILURE";

const fetchPopularCourses = (id) => ({
  types: [
    POPULAR_COURSES_REQUEST,
    POPULAR_COURSES_SUCCESS,
    POPULAR_COURSES_FAILURE,
  ],
  endpoint: `courses/?category=${id}`,
  method: "GET",
});

const fetchGroupCourses = () => ({
  types: [
    GROUP_COURSES_REQUEST,
    GROUP_COURSES_SUCCESS,
    GROUP_COURSES_FAILURE,
  ],
  endpoint: "courses/?category=2",
  method: "GET",
});

const fetchIndividualCourses = () => ({
  types: [
    INDIVIDUAL_COURSES_REQUEST,
    INDIVIDUAL_COURSES_SUCCESS,
    INDIVIDUAL_COURSES_FAILURE,
  ],
  endpoint: "courses/?category=0",
  method: "GET",
});

const fetchOrganizationCourses = () => ({
  types: [
    ORGANIZATION_COURSES_REQUEST,
    ORGANIZATION_COURSES_SUCCESS,
    ORGANIZATION_COURSES_FAILURE,
  ],
  endpoint: "courses/?category=1",
  method: "GET",
});

export const actions = {
  fetchPopularCourses,
  fetchGroupCourses,
  fetchIndividualCourses,
  fetchOrganizationCourses,
};

const ACTION_HANDLERS = {
  [POPULAR_COURSES_SUCCESS]: (state, { payload }) => ({
    ...state,
    popularCourses: payload.results,
  }),
  [GROUP_COURSES_SUCCESS]: (state, { payload }) => ({
    ...state,
    groupCourses: payload.results,
  }),
  [INDIVIDUAL_COURSES_SUCCESS]: (state, { payload }) => ({
    ...state,
    individualCourses: payload.results,
  }),
  [ORGANIZATION_COURSES_SUCCESS]: (state, { payload }) => ({
    ...state,
    organizationCourses: payload.results,
  }),
};

export const initialState = {
  popularCourses: [],
  groupCourses: [],
  individualCourses: [],
  organizationCourses: [],
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
