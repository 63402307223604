import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { actions as popularCoursesActions } from "../redux/actions/popularCoursesActions";
import cutText from "../utils/cutText";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import arrow from "../assets/black-arrow.svg";

const OrganizationCoursesAuthenticated = ({ fetchOrganizationCourses, organizationCourses }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setErrorOccurred(false);
    fetchOrganizationCourses()
      .catch(() => {
        setErrorOccurred(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchOrganizationCourses]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <div>
      <div className="max-w-8xl mx-auto pt-6 font-semibold text-2xl flex items-center sm:py-6">
        <span className="h-1 w-4 bg-blue-600 mr-2"></span>
        Organisationsnivå
      </div>
      <div className="max-w-8xl m-auto py-6 flex flex-wrap justify-between md:justify-center md:flex-col sm:flex-col sm:bg-gray-100">
        {organizationCourses &&
          organizationCourses?.slice(0, 4).map((el) => {
            const url = "/tema/" + encodeURIComponent(el.slug);
            return (
              <div className="flex mb-10 w-48per md:justify-center md:items-center md:w-full sm:items-center sm:w-full sm:px-6" key={el.id}>
                <Link className="flex" to={url}>
                  <div className="bg-gray-300 rounded w-36 h-40 mr-4">
                    <img
                      className="rounded-lg object-cover h-full w-full"
                      src={el.image}
                    />
                  </div>
                  <div className="flex flex-col flex-grow h-full justify-between max-w-sm w-2/3 md:w-3/4 md:max-w-none sm:max-w-none sm:w-1/2">
                    <h3 className="font-bold text-lg md:text-lg">{el.title}</h3>
                    <div className="lg:text-sm md:py-2 sm:py-2 sm:text-xs">{cutText(el.description, 180)}</div>
                    <div className="underline flex cursor-pointer md:text-sm">
                      Läs, titta eller lyssna
                      <img className="ml-2 w-3.5" src={arrow} />
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
          <div className="flex w-full justify-center items-center sm:hidden">
            <Link
              to="/teman/organisation"
              className="bg-black text-white py-2 px-3 font-light rounded my-5"
            >
              Se alla på organisationsnivå
            </Link>
          </div>
      </div>
    </div>
  );
};

OrganizationCoursesAuthenticated.propTypes = {
  fetchOrganizationCourses: PropTypes.func.isRequired,
  organizationCourses: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    organizationCourses: state.popularCourses.organizationCourses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrganizationCourses: bindActionCreators(
      popularCoursesActions.fetchOrganizationCourses,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationCoursesAuthenticated);
