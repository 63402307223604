/* eslint-disable camelcase */
import { authStorage } from "../../storage/authStorage";

export const AUTH_INVITE_SUCCESS = "AUTH_INVITE_SUCCESS";
export const AUTH_INVITE_FAILURE = "AUTH_INVITE_FAILURE";

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";

export const AUTH_REFRESH_REQUEST = "AUTH_REFRESH_REQUEST";
export const AUTH_REFRESH_SUCCESS = "AUTH_REFRESH_SUCCESS";
export const AUTH_REFRESH_FAILURE = "AUTH_REFRESH_FAILURE";

export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAILURE = "AUTH_REGISTER_FAILURE";

export const AUTH_REGISTER_USER_SUCCESS = "AUTH_REGISTER_USER_SUCCESS";
export const AUTH_REGISTER_USER_FAILURE = "AUTH_REGISTER_USER_FAILURE";

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REMOVE_ACCESS = "AUTH_REMOVE_ACCESS";

export const AUTH_COMPLETE_SURVEY = "AUTH_COMPLETE_SURVEY";

const inviteUser = (emails) => ({
  types: [AUTH_INVITE_SUCCESS, AUTH_INVITE_FAILURE],
  endpoint: "invite-user/",
  method: "POST",
  body: {
    emails,
  },
});

const register = (
  username,
  email,
  password,
  firstName,
  lastName,
  city,
  street,
  postal_code,
  orgNumber,
  orgName
) => ({
  types: [AUTH_REGISTER_SUCCESS, AUTH_REGISTER_FAILURE],
  endpoint: "register-company/",
  method: "POST",
  body: {
    username,
    password,
    email,
    first_name: firstName,
    last_name: lastName,
    city: city,
    street: street,
    postal_code: postal_code,
    organisation_number: orgNumber,
    organisation_name: orgName,
  },
});

const registerUser = (username, password, firstName, lastName, token) => ({
  types: [AUTH_REGISTER_USER_SUCCESS, AUTH_REGISTER_USER_FAILURE],
  endpoint: "register/" + token + "/",
  method: "POST",
  body: {
    username,
    password,
    token,
    first_name: firstName,
    last_name: lastName,
  },
});

const login = (email, password) => ({
  types: [AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE],
  endpoint: "login/",
  method: "POST",
  body: {
    email,
    password,
  },
});

const refreshToken = (refresh) => ({
  types: [AUTH_REFRESH_REQUEST, AUTH_REFRESH_SUCCESS, AUTH_REFRESH_FAILURE],
  endpoint: "refresh/",
  method: "POST",
  body: {
    refresh,
  },
});

const logout = () => ({
  type: AUTH_LOGOUT,
});

const removeAccessToken = () => ({
  type: AUTH_REMOVE_ACCESS,
});

const completeSurvey = () => ({
  type: AUTH_COMPLETE_SURVEY,
});

export const actions = {
  login,
  logout,
  refreshToken,
  removeAccessToken,
  register,
  registerUser,
  inviteUser,
  completeSurvey,
};

const ACTION_HANDLERS = {
  [AUTH_LOGIN_SUCCESS]: (state, { payload }) => {
    authStorage.saveAccessToken(payload.access);
    authStorage.saveRefreshToken(payload.refresh);
    authStorage.saveUserType(payload.user_role);
    authStorage.saveSubscriptionInfo(payload.is_subscribed);
    authStorage.saveSurveyInfo(payload.is_survey_completed);

    return {
      ...state,
      access: payload.access,
      refresh: payload.refresh,
      userType: payload.user_role,
      subscribed: payload.is_subscribed,
      survey: payload.is_survey_completed,
    };
  },
  [AUTH_REFRESH_SUCCESS]: (state, { payload }) => {
    authStorage.saveAccessToken(payload?.access);
    return {
      ...state,
      access: payload?.access,
    };
  },
  [AUTH_REFRESH_FAILURE]: () => ({
    access: null,
    refresh: null,
  }),
  [AUTH_LOGOUT]: () => {
    authStorage.removeTokens();
    return {
      access: null,
      refresh: null,
    };
  },
  [AUTH_REMOVE_ACCESS]: (state) => ({
    ...state,
    access: null,
  }),
  [AUTH_COMPLETE_SURVEY]: (state) => ({
    ...state,
    survey: true,
  }),
};

const { access, refresh } = authStorage.retrieveTokens();
const userType = authStorage.retrieveUserType();
const survey = authStorage.retrieveSurveyInfo();

export const initialState = {
  access: access,
  refresh: refresh,
  userType: userType,
  survey: survey,
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
