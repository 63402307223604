import { combineReducers } from "redux";
import auth from "./actions/authActions";
import courses from "./actions/coursesActions";
import popularCourses from "./actions/popularCoursesActions";
import readingCourse from "./actions/readingCourseActions";
import audioCourse from "./actions/audioCourseActions";
import videoCourse from "./actions/videoCourseActions";
import subscriptions from "./actions/subscriptionsActions";
import quizCourse from "./actions/quizCourseActions";
import surveys from "./actions/surveyActions";
import userData from "./actions/userActions";
import achievements from "./actions/achievementsActions";
import pages from "./actions/pagesActions";

const rootReducer = combineReducers({
  auth,
  courses,
  popularCourses,
  readingCourse,
  audioCourse,
  videoCourse,
  quizCourse,
  subscriptions,
  surveys,
  userData,
  achievements,
  pages,
});

export default rootReducer;
