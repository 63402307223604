export const VIDEO_COURSE_REQUEST = "VIDEO_COURSE_REQUEST";
export const VIDEO_COURSE_SUCCESS = "VIDEO_COURSE_SUCCESS";
export const VIDEO_COURSE_FAILURE = "VIDEO_COURSE_FAILURE";

export const RESET_VIDEO_DETAILS = "RESET_VIDEO_DETAILS";

const fetchVideoCourse = (title) => ({
  types: [VIDEO_COURSE_REQUEST, VIDEO_COURSE_SUCCESS, VIDEO_COURSE_FAILURE],
  endpoint: `course/video/${encodeURIComponent(title)}`,
  method: "GET",
});

const resetVideoDetails = () => {
  return {
    type: RESET_VIDEO_DETAILS,
  };
};

export const actions = {
  fetchVideoCourse,
  resetVideoDetails,
};

const ACTION_HANDLERS = {
  [VIDEO_COURSE_SUCCESS]: (state, { payload }) => ({
    ...state,
    videoCourse: payload,
  }),
  [RESET_VIDEO_DETAILS]: (state) => ({
    ...state,
    videoCourse: [],
  }),
};

export const initialState = {
  videoCourse: [],
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
