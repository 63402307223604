import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as readingActions } from "../redux/actions/readingCourseActions";
import { actions as courseActions } from "../redux/actions/coursesActions";
import isEmptyObject from "../utils/isEmptyObject";
import { ImArrowRight2 } from "react-icons/im";
import LoadingIndicator from "../components/LoadingIndicator";
import ErrorOccurred from "../components/ErrorOccurred";
import BackgroundImage from "../components/BackgroundImage";

import image from "../assets/example-reading.png";
import imageMobile from "../assets/example-reading-mobile.png";

const CourseReadingChapter = ({ fetchReadingChapter, readingDetails, fetchCourseDetails, courseDetails, submitCourseProgress }) => {
  const [errorOccured, setErrorOccured] = useState(false);

  const nextChapterId = readingDetails.next_id;
  const { title } = useParams();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    setErrorOccured(false);
    fetchReadingChapter(id).catch(() => {
      setErrorOccured(true);
    });
  }, [fetchReadingChapter, id]);

  useEffect(() => {
    setErrorOccured(false);
    fetchCourseDetails(title).catch(() => {
      setErrorOccured(true);
    });
  }, [fetchCourseDetails]);

  if (isEmptyObject(readingDetails)) {
    return <LoadingIndicator />;
  }
  if (errorOccured) {
    return <ErrorOccurred />;
  }

  const onNextChapter = () => {
    submitCourseProgress(title, "reading", id);
    history.push(`/tema/${title}/lasning/kapitel/${nextChapterId}`);
  };

  const onLastChapter = () => {
    submitCourseProgress(title, "reading", id);
    history.push(`/tema/${title}`);
  };

  return (
    <div className="w-full -z-10">
      <BackgroundImage
        title={courseDetails.title}
        slug={courseDetails.slug}
        image={image}
        imageMobile={imageMobile}
        type="text"
        category={courseDetails.category}
        extrastyles="absolute z-50"
        staticImage={true}
      />
      <div className="w-full h-1/2">
        <div className="w-3/4 mx-auto pt-16 md:w-11/12 md:px-6 sm:w-full sm:pt-10 sm:px-6">
          <div className="flex flex-col items-start">
            <div className="bg-yellow-500 text-white rounded-md px-2 sm:absolute sm:top-24">
              Kapitel {readingDetails.order}
            </div>
            <div className="font-bold text-4xl pt-3 pb-8 sm:absolute sm:top-36 sm:text-white">
              {readingDetails.name}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: readingDetails.content }}
            />
          </div>
          <div className="flex justify-between py-20 sm:py-10">
            <Link
              className="bg-yellow-500 text-white rounded-md py-2 px-4 flex justify-center items-center sm:text-sm sm:py-1 sm:px-2"
              to={`/tema/${title}/lasning`}
            >
              Tillbaka till översikt
            </Link>
            {nextChapterId ? (
              <button
                onClick={onNextChapter}
                className="text-yellow-500 py-2 px-4 font-bold flex items-center sm:text-sm"
              >
                Läs kapitel {readingDetails.order + 1}
                <ImArrowRight2 className="ml-2" />
              </button>
            ) : (
              <button
                onClick={onLastChapter}
                className="text-yellow-500 py-2 px-4 font-bold flex items-center sm:text-sm"
              >
                Sluta läsa
                <ImArrowRight2 className="ml-2" />
              </button>)}
          </div>
        </div>
      </div>
    </div>
  );
};

CourseReadingChapter.propTypes = {
  fetchReadingChapter: PropTypes.func.isRequired,
  readingDetails: PropTypes.object.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  courseDetails: PropTypes.object.isRequired,
  submitCourseProgress: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    courseDetails: state.courses.courseDetails,
    readingDetails: state.readingCourse.readingChapter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchReadingChapter: bindActionCreators(
      readingActions.fetchReadingChapter,
      dispatch
    ),
    fetchCourseDetails: bindActionCreators(
      courseActions.fetchCourseDetails,
      dispatch
    ),
    submitCourseProgress: bindActionCreators(courseActions.submitCourseProgress, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseReadingChapter);
