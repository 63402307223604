import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ImArrowRight2 } from "react-icons/im";
import { FiEdit } from "react-icons/fi";
import cutText from "../utils/cutText";
import clipboard from "../assets/clipboard.svg";

const CourseQuizBanner = ({ courseSlug, quizData, isEnabled }) => {
  return (
    <div className="px-6 flex justify-center items-center flex-col sm:p-0 sm:items-start sm:pb-5">
      <h2 className="text-3xl text-center pt-16 font-bold md:pt-10 md:text-2xl sm:pt-7">
        Är du klar?
      </h2>
      <p className="text-center text-lg pb-10 md:pb-8 md:text-base sm:pb-5 sm:text-sm">
        Välj det format som passar dig bäst idag!
      </p>
      <div className={`${!isEnabled && "opacity-50 cursor-not-allowed"} w-2/3 h-60 rounded-lg shadow-accent py-10 px-6 flex max-w-2xl md:w-9/12 md:p-4 md:h-52 sm:hidden`}>
        <div className="w-4/5 justify-center flex flex-col">
          <h5 className="font-bold text-xl pb-3">Quiz</h5>
          <p className="max-w-md md:w-full md:pr-4 md:text-sm">{quizData}</p>
        </div>
        <div className="flex flex-col flex-grow justify-around items-center">
          <div className="w-20 h-20 rounded-full bg-black flex items-center justify-center">
            <img src={clipboard} />
          </div>
          <p className="text-sm font-semibold text-center">Redo för quiz?</p>
          <Link
            to={`/tema/${courseSlug}/quiz`}
            onClick={!isEnabled && ((e) => e.preventDefault())}
            className="py-1 rounded-sm text-xs text-black flex items-center"
          >
            Gör quiz
            <ImArrowRight2 className="ml-2" />
          </Link>
        </div>
      </div>
      <div
        className={`${!isEnabled && "opacity-50 cursor-not-allowed"} ` +
        "hidden sm:inline-block rounded-lg shadow-accent p-6 max-w-2xl w-full border border-gray-100"}>
        <div className="flex items-center">
          <div className="w-10 h-10 bg-black flex items-center justify-center rounded-md">
            <FiEdit className="text-white w-5 h-5" />
          </div>
          <h5 className="font-bold text-xl ml-4">Quiz</h5>
        </div>
        <p className="text-sm text-gray-600 py-3">{cutText(quizData, 150)}</p>
        <div className="flex flex-col flex-grow justify-around items-center">
          <Link
            to={`/tema/${courseSlug}/quiz`}
            onClick={!isEnabled && ((e) => e.preventDefault())}
            className="text-black flex self-end"
          >
            <ImArrowRight2 className="ml-2 w-10 h-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

CourseQuizBanner.propTypes = {
  courseSlug: PropTypes.string.isRequired,
  quizData: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

export default CourseQuizBanner;
