const moneyFormatter = (
  amount
) => {
  const formattedAmount = new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
  return formattedAmount;
};

export default moneyFormatter;
