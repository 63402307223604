import React from "react";
import PropTypes from "prop-types";
import FormRenderer from "./FormRenderer";

const ResetPasswordForm = ({ onSubmit }) => {

  const fields = [
    {
      fieldName: "password",
      placeholder: "Nytt lösenord",
      type: "password",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "password-confirm",
      placeholder: "Bekräfta lösenord",
      type: "passwordConfirmation",
      validation: {
        required: true,
      },
    },
  ];

  const bottom = (
    <>
      <button type="submit" className="bg-blue-600 text-white w-full rounded font-semibold py-3">
        Återställ lösenord
      </button>
    </>
  );

  return (
    <FormRenderer submit={onSubmit} fields={fields} bottom={bottom} />
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
