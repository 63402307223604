import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../../redux/actions/authActions";
import { Route, Switch, Redirect } from "react-router-dom";

import TopBarMenu from "../../components/TopBarMenu";
import AudioPage from "../AudioPage";
import CourseDetails from "../CourseDetails";
import CourseQuiz from "../CourseQuiz";
import CourseReadingChapter from "../CourseReadingChapter";
import CoursesPage from "../CoursesPage";
import ContentLoggedOutPage from "../ContentLoggedOutPage";
import ReadingPage from "../ReadingPage";
import VideoPage from "../VideoPage";
import SubscribePage from "../SubscribePage";
import ConfirmPayment from "../ConfirmPayment";
import ProfilePage from "../ProfilePage";
import QuizResultPage from "../QuizResultPage";
import SurveyPage from "../SurveyPage";
import GoToSurveyPage from "../GoToSurveyPage";
import SurveyResultPage from "../SurveyResultPage";
import QuizSurveyPage from "../QuizSurveyPage";
import NotificationsHandler from "../../components/NotificationsHandler";
import IndividualCoursesPage from "../IndividualCoursesPage";
import OrganizationCoursesPage from "../OrganizationCoursesPage";
import GroupCoursesPage from "../GroupCoursesPage";

const Routing = ({ logout }) => {
  return (
    <>
      <TopBarMenu logout={logout} />
      <NotificationsHandler />
      <Switch>
        <Route exact path="/" component={CoursesPage} />
        <Route exact path="/teman" component={CoursesPage} />
        <Route exact path="/tema/:title" component={CourseDetails} />
        <Route exact path="/tema/:title/quiz" component={CourseQuiz} />
        <Route exact path="/tema/:title/quiz-resultat/:id" component={QuizResultPage} />
        <Route exact path="/tema/:title/quiz-enkat" component={QuizSurveyPage} />
        <Route
          exact
          path="/tema/:title/lasning"
          component={ReadingPage}
        />
        <Route
          exact
          path="/tema/:title/lasning/kapitel/:id"
          component={CourseReadingChapter}
        />
        <Route exact path="/tema/:title/audio" component={AudioPage} />
        <Route exact path="/tema/:title/video/:id" component={VideoPage} />
        <Route exact path="/tema/:title/video/" component={VideoPage} />
        <Route exact path="/prenumerationer" component={SubscribePage} />
        <Route exact path="/kakor" component={ContentLoggedOutPage} />
        <Route exact path="/kopvillkor" component={ContentLoggedOutPage} />
        <Route exact path="/integritetspolicy" component={ContentLoggedOutPage} />
        <Route exact path="/om-oss" component={ContentLoggedOutPage} />
        <Route exact path="/hur-det-fungerar" component={ContentLoggedOutPage} />
        <Route exact path="/betalning/bekraftelse/:id" component={ConfirmPayment} />
        <Route exact path="/profil" component={ProfilePage} />
        <Route exact path="/se-enkat" component={GoToSurveyPage} />
        <Route exact path="/enkat" component={SurveyPage} />
        <Route exact path="/enkat-resultat" component={SurveyResultPage} />
        <Route exact path="/teman/individ" component={IndividualCoursesPage} />
        <Route exact path="/teman/organisation" component={OrganizationCoursesPage} />
        <Route exact path="/teman/grupp" component={GroupCoursesPage} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

Routing.propTypes = {
  logout: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(authActions.logout, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Routing);
