import React from "react";

const ErrorOccurred = () => {
  return (
    <div className="p-6 w-full flex items-center justify-center font-bold text-lg">
      Error Occurred
    </div>
  );
};

export default ErrorOccurred;
