import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "./Breadcrumbs";

const BackgroundImage = ({ title, slug, category, isVideoPage, type, image, imageMobile, extrastyles, staticImage }) => {
  return (
    <div className={`${isVideoPage ? "h-40" : "h-60"} w-full sm:h-96  ${staticImage ? "md:h-80" : "md:h-48"}`}>
      <div className={`pl-28 pt-20 sm:hidden md:pl-12 ${extrastyles ? extrastyles : ""}`}>
        <Breadcrumbs courseTitle={title} courseSlug={slug} courseCategory={category} type={type} />
      </div>
      <div className={`filter brightness-75 w-full sm:brightness-50 sm:relative sm:h-full ${staticImage ? "static h-full md:h-full" : "absolute h-96 -top-14 -z-10 sm:top-0 md:h-2/5"}`}>
        <img className="object-cover h-full w-full sm:hidden" src={image} alt="" />
        <img className="hidden object-cover h-full w-full sm:inline-block" src={imageMobile} />
      </div>
    </div>
  );
};

BackgroundImage.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  category: PropTypes.number.isRequired,
  isVideoPage: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  imageMobile: PropTypes.string,
  extrastyles: PropTypes.string,
  staticImage: PropTypes.bool,
};

export default BackgroundImage;
