export const SURVEY_LIST_REQUEST = "SURVEY_LIST_REQUEST";
export const SURVEY_LIST_SUCCESS = "SURVEY_LIST_SUCCESS";
export const SURVEY_LIST_FAILURE = "SURVEY_LIST_FAILURE";

export const SURVEY_SUBMIT_REQUEST = "SURVEY_SUBMIT_REQUEST";
export const SURVEY_SUBMIT_SUCCESS = "SURVEY_SUBMIT_SUCCESS";
export const SURVEY_SUBMIT_FAILURE = "SURVEY_SUBMIT_FAILURE";

export const SURVEY_SKIP_REQUEST = "SURVEY_SKIP_REQUEST";
export const SURVEY_SKIP_SUCCESS = "SURVEY_SKIP_SUCCESS";
export const SURVEY_SKIP_FAILURE = "SURVEY_SKIP_FAILURE";

const fetchSurveys = () => ({
  types: [
    SURVEY_LIST_REQUEST,
    SURVEY_LIST_SUCCESS,
    SURVEY_LIST_FAILURE,
  ],
  endpoint: "surveys/",
  method: "GET",
});

const submitSurvey = (surveyID, answers, position) => ({
  types: [
    SURVEY_SUBMIT_REQUEST,
    SURVEY_SUBMIT_SUCCESS,
    SURVEY_SUBMIT_FAILURE,
  ],
  endpoint: "surveys/",
  method: "POST",
  body: {
    survey: surveyID,
    answers,
    // eslint-disable-next-line camelcase
    user_position: position
  }
});

const skipSurvey = () => ({
  types: [
    SURVEY_SKIP_REQUEST,
    SURVEY_SKIP_SUCCESS,
    SURVEY_SKIP_FAILURE,
  ],
  endpoint: "surveys/skip/",
  method: "POST",
});

export const actions = {
  fetchSurveys,
  submitSurvey,
  skipSurvey
};

const ACTION_HANDLERS = {
  [SURVEY_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    surveys: payload.results
  }),
  [SURVEY_SUBMIT_SUCCESS]: (state, { payload }) => ({
    ...state,
    surveyResults: payload,
  }),
  [SURVEY_SKIP_SUCCESS]: (state, { payload }) => ({
    ...state,
    surveySkip: payload,
  })
};

export const initialState = {
  surveys: null,
  surveyResults: null,
  surveySkip: null,
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
