import React from "react";
import PropTypes from "prop-types";
import ReadingBanner from "./ReadingBanner";
import AudioBanner from "./AudioBanner";
import VideoBanner from "./VideoBanner";

const CourseOptions = ({ courseSlug, readingData, audioData, videoData, readingEnabled, audioEnabled, videoEnabled }) => {
  return (
    <div>
      <h2 className="text-3xl text-center pt-16 font-bold md:pt-10 md:text-2xl sm:text-2xl sm:text-left">Vad passar dig?</h2>
      <p className="text-center text-lg pb-10 md:pb-8 md:text-base sm:text-left sm:text-base sm:pb-5">Välj det format som passar dig bäst idag!</p>
      <div className="flex justify-between max-w-6xl m-auto sm:flex-col">
        {readingEnabled && (<ReadingBanner readingData={readingData} courseTitle={courseSlug} />)}
        {audioEnabled && (<AudioBanner audioData={audioData} courseTitle={courseSlug} />)}
        {videoEnabled && (<VideoBanner videoData={videoData} courseTitle={courseSlug} />)}
      </div>
    </div>
  );
};

CourseOptions.propTypes = {
  courseSlug: PropTypes.string.isRequired,
  readingData: PropTypes.string.isRequired,
  audioData: PropTypes.string.isRequired,
  videoData: PropTypes.string.isRequired,
  readingEnabled: PropTypes.bool.isRequired,
  audioEnabled: PropTypes.bool.isRequired,
  videoEnabled: PropTypes.bool.isRequired,
};

export default CourseOptions;
