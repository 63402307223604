import {
  actions as authActions, AUTH_LOGIN_REQUEST,
  AUTH_REFRESH_REQUEST,
} from "../actions/authActions";

const apiMiddleware = (store) => (next) => (action) => {
  if (typeof action.endpoint !== "string") {
    return next(action);
  }
  const [requestType, receiveType, errorType] = action?.types ?? [];

  let authHeaders;

  const state = store.getState();
  const token = state?.auth?.access;

  if (token) {
    authHeaders = {
      Authorization: `Bearer ${token}`,
    };
  }

  const config = {
    method: action.method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeaders,
    },
    body: action.body ? JSON.stringify(action.body) : undefined,
  };

  next({
    type: requestType,
    endpoint: action.endpoint,
    ...config,
  });

  let url = "https://learnflow-api-development.azurewebsites.net/api/";
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_API_URL) {
    // eslint-disable-next-line no-undef
    url = process.env.REACT_APP_API_URL;
  }

  return fetch(url + action.endpoint, config)
    .then((res) => {
      if (res.status >= 400) {
        if (res.status === 401 && requestType !== AUTH_REFRESH_REQUEST && requestType !== AUTH_LOGIN_REQUEST) {
          return handleRefresh(store, action, next, errorType);
        }
        return handleError(res, next, errorType);
      }
      res.json().then((data) => {
        next({
          type: receiveType,
          payload: data,
        });
        return Promise.resolve({ data: data });
      });
    });
};

const handleError = async (err, next, errorType) => {
  let error = err;
  if (errorType) {
    next({
      type: errorType,
    });
  }
  if ("status" in err && err.status === 400) {
    let errors = [];
    const data = await err.json();
    if ("non_field_errors" in data) {
      errors.push(data.non_field_errors);
    } else if ("details" in data) {
      errors.push(data.details);
    } else {
      errors.push(data);
    }
    error = errors;
  }
  return Promise.reject(error);
};

const handleRefresh = (store, action, next, errorType) => {
  const state = store.getState();
  const refreshToken = state?.auth?.refresh;
  if (refreshToken == null) {
    next({
      type: errorType,
    });
    return store.dispatch(authActions.logout());
  }
  store.dispatch(authActions.refreshToken(refreshToken)).then(() => {
    const token = state.auth.access;
    if (!token) {
      return store.dispatch(authActions.logout());
    }
    return store.dispatch(action);
  });
};

export default apiMiddleware;
