import React from "react";
import AudioIcon from "./AudioIcon";
import ReadingIcon from "./ReadingIcon";
import VideoIcon from "./VideoIcon";

const CoursesLoggedOutBanner = () => {
  return (
    <div className="flex justify-between py-12 mx-auto px-28 ld:px-16 md:px-12 sm:px-0 sm:hidden">
      <div className="flex items-center">
        <AudioIcon type="gradient"/>
        <div className="text-green-500 ml-2 text-lg font-semibold">Lyssna</div>
      </div>
      <div className="flex items-center">
        <ReadingIcon type="gradient"/>
        <div className="text-yellow-600 ml-2 text-lg font-semibold">Läsa</div>
      </div>
      <div className="flex items-center">
        <VideoIcon type="gradient"/>
        <div className="text-blue-600 ml-2 text-lg font-semibold">Titta</div>
      </div>
    </div>
  );
};

export default CoursesLoggedOutBanner;
