import React from "react";
import PropTypes from "prop-types";
import { BsCheckCircle } from "react-icons/bs";
import moneyFormatter from "../utils/moneyFormatter";
import { FiUsers } from "react-icons/fi";

const SubscriptionElement = ({
  name,
  price,
  description,
  licences,
  color,
  onSubscribe,
}) => {

  return (
    <div className="w-full rounded-card  bg-white h-fit lg:w-2/7 md:w-2/7 sm:w-full sm:mb-4">
    <div
      style={{ backgroundColor: color }}
      className="h-4 w-full rounded-tr-card rounded-tl-card"
    />
      <div className="p-4">
        <div className="text-xl font-semibold md:text-lg">{name}</div>
        <div className="text-xl font-semibold md:text-lg">{moneyFormatter(price)}/år</div>
        <div className="font-light text-gray-400 text-xs py-2 border-b border-gray-300">
          Moms tillkommer
        </div>
        <div className="py-2">
          {description.map((item, index) => {
            return (
              <div key={index} className="flex items-center py-3">
                <BsCheckCircle style={{ color: color }} className="mr-2" />
                <p className="md:text-sm">{item}</p>
              </div>
            );
          })}
          <div className="flex items-center py-3">
              <FiUsers style={{ color: color }} className="mr-2" />
              <p className="md:text-sm">
               {licences === 1 ? "1 licens" : `Upp till ${licences} licenser`}
               </p>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button
            onClick={onSubscribe}
            className="text-white w-44 text-center py-2 rounded-lg md:text-sm
            transition-all hover:opacity-100 opacity-80 duration-200 hover:shadow-sm ease-out"
            style={{ backgroundColor: color }}
          >
            Välj {name}
          </button>
        </div>
      </div>
    </div>
  );
};

SubscriptionElement.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.array.isRequired,
  licences: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onSubscribe: PropTypes.func.isRequired,
};


export default SubscriptionElement;

