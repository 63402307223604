import React from "react";
import Spinner from "./Spinner/Spinner";

const LoadingIndicator = () => {
  return (
    <div className="p-6 flex flex-col items-center justify-center">
      <div className="font-bold text-lg">Läser in...</div>
      <Spinner />
    </div>
  );
};

export default LoadingIndicator;
