import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../redux/actions/userActions";
import LoadingIndicator from "../components/LoadingIndicator";
import ProfileInfo from "../components/profile/ProfileInfo";
import ProfileStats from "../components/profile/ProfileStats";
import ProfileSummary from "../components/profile/ProfileSummary";
import CoursesProfileSlider from "../components/profile/CoursesProfileSlider";
import ProfileAchievements from "../components/profile/ProfileAchievements";
import ProfileSlider from "../components/profile/ProfileSlider";
import MobileCoursesSlider from "../components/profile/MobileCoursesSlider";

const ProfilePage = ({ fetchUserData, userData }) => {
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (!userData) {
    return <LoadingIndicator />;
  }

  const completedAchievements = userData.achievements.filter((el) => el.status);

  return (
    <>
      <div className="px-28 py-10 max-w-8xl mx-auto lg:px-16 md:px-12 sm:px-6 sm:py-0 mt-10">
        <div className="flex justify-between mt-10 sm:flex-col md:mt-0 sm:mt-16">
          <ProfileInfo
            firstName={userData.first_name}
            lastName={userData.last_name}
            position={userData.profile_data == null ? "" : userData.profile_data.user_position}
            company={userData.profile_data == null ? "" : userData.profile_data.company}
            surveyResult={
              {
                team: userData.survey_results == null ? null : userData.survey_results.TEAM,
                individual: userData.survey_results == null ? null : userData.survey_results.INDIVIDUAL,
                organization: userData.survey_results == null ? null : userData.survey_results.ORGANISATION,
              }
            }
          />
          <ProfileSummary
            ongoingCourses={userData.ongoing_courses.length}
            completedCourses={userData.completed_courses.length}
            completedAchievements={completedAchievements.length}
          />
        </div>
        <div className="flex justify-between mt-10 sm:flex-col md:mt-6 sm:mt-6">
          <ProfileAchievements achievements={userData.achievements} />
          <ProfileStats courses={userData.completed_courses} />
        </div>
        <div className="flex justify-between mt-10 sm:flex-col md:mt-6 sm:mt-6">
          <CoursesProfileSlider courses={userData.ongoing_courses} title={"Pågående teman"} description={"Slutför dina pågående teman idag."}/>
          <CoursesProfileSlider courses={userData.completed_courses} title={"Avslutade teman"} completed={true}/>
        </div>
        <div className="flex justify-between mt-10 sm:flex-col md:mt-6 sm:mt-6">
          <ProfileSlider quizSurveys={userData.quiz_survey} />
        </div>
      </div>
      <div className="hidden sm:inline-block w-full mt-5">
        <MobileCoursesSlider
          ongoingCourses={userData.ongoing_courses}
          completedCourses={userData.completed_courses}
        />
      </div>
    </>
  );
};

ProfilePage.propTypes = {
  fetchUserData: PropTypes.func.isRequired,
  userData: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    userData: state.userData.userdata,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserData: bindActionCreators(actions.fetchUserData, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
