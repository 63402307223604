import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import arrow from "../assets/black-arrow.svg";

const HighlightedCourseProfile = ({ course, onNext, onPrev }) => {
  return (
    <div className="flex flex-col mr-2 px-6 w-2/6 relative h-96 md:w-96 md:px-4">
      <button className="carousel-arrow next-arrow" onClick={onNext}>
        <img className="md:w-5 md:h-5" src={arrow} alt="arrow"/>
      </button>
      <Link to={`/tema/${encodeURIComponent(course.slug)}`}>
        <div className="relative">
          <div className="rounded-lg w-full h-64">
            <img
              className="rounded-lg object-cover h-full w-full"
              src={course.image}
            />
          </div>
          <div className="absolute text-white bg-black top-0 left-0 right-0 rounded-tr-lg rounded-tl-lg text-sm py-1 px-3">
            {course.title}
          </div>
          <div className="absolute text-white bg-black bottom-0 right-0 rounded-br-lg text-xs py-1 px-3">
            {course.length} min
          </div>
        </div>
        <div className="leading-4 py-3 md:text-sm md:leading-none">
          {course.description}
        </div>
        <div className="flex justify-end items-center py-4">
          <div className="bg-black text-white rounded w-2/5 py-2 text-center md:py-1">
            Välj
          </div>
        </div>
      </Link>
      <button className="carousel-arrow prev-arrow" onClick={onPrev}>
        <img className="md:w-5 md:h-5" src={arrow} alt="arrow"/>
      </button>
    </div>
  );
};

HighlightedCourseProfile.propTypes = {
  course: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default HighlightedCourseProfile;
