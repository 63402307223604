import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Chart from "react-google-charts";

const ReportStats = ({ title, answers }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (answers) {
      let graphData = answers.map((el, idx) => [(idx + 1).toString(), el]);
      graphData = [["", ""], ...graphData];
      setData(graphData);
    }
  }, [answers]);
  return (
    <div className="w-49per shadow-accent rounded-lg flex flex-col p-6 border border-gray-100 sm:w-full mb-4">
      <div className="font-semibold pb-4">{title}</div>
      {
        answers ? (
          <Chart
            width={"100%"}
            height={"200px"}
            chartType="Bar"
            loader={<div>Läser in diagram</div>}
            data={data}
            options={{
              legend: { position: "none" },
            }}
          />
        ) : (
          <div className="flex w-100 h-52 justify-center items-center">
            <div className="text-gray-400">
              Inga nulägesanalyser har genomförts
            </div>
          </div>
        )
      }
    </div>
  );
};

ReportStats.propTypes = {
  title: PropTypes.string.isRequired,
  answers: PropTypes.array,
};

export default ReportStats;
