import React from "react";
import PropTypes from "prop-types";

const FormInput = ({ idx, id, label, onChange, type }) => {

  const onEdit = (e) => {
    onChange(idx, e.target.value);
  };

  return (
    <>
    <label htmlFor={id} className="sr-only">
    { label }
    </label>
    <input
      type={type ? type : "text"}
      id={id + "-" + idx}
      placeholder="ex email@company.se"
      className="bg-blue-100 rounded flex-grow p-2 mr-8 mb-3"
      required=""
      onChange={(e) => onEdit(e)}
    />
    </>
  );
};

FormInput.propTypes = {
  idx: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default FormInput;
