import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";

import apiMiddleware from "./middleware/apiMiddleware";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

export default () => {
  let store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk, apiMiddleware, reduxImmutableStateInvariant())
    )
  );
  return store;
};
