import React from "react";
import PropTypes from "prop-types";
import VideoIcon from "./VideoIcon.js";
import ReadingIcon from "./ReadingIcon.js";
import AudioIcon from "./AudioIcon.js";
import PlatformDescriptionMobile from "./PlatformDescriptionMobile.js";

const PlatformDescription = ({ isLanding, title, subtitle, listeningDescription, readingDescription, videoDescription }) => {
  return (
    <div className="py-10 px-12 lg:py-6 md:py-4 sm:p-0">
      <div className="sm:hidden">
        {isLanding ? (
          <h2 className="text-2xl text-center pt-16 font-bold">
            {title ? title : "Det är upp till dig om du vill lyssna, läsa eller titta"}
          </h2>
        ) : (
          <h2 className="text-2xl text-center pt-16 font-bold">
            Det är upp till dig om du vill{" "}
            <span className="text-green-500">lyssna</span>,{" "}
            <span className="text-yellow-500">läsa</span> eller{" "}
            <span className="text-blue-500">titta</span>
          </h2>
        )}
        <div className="text-gray-500 py-5 text-center md:text-sm sm:hidden mb-6">
          {subtitle ? subtitle : "Vi anpassar oss efter dig. Därför finns allt vårt material i tre olika format så att du själv kan bestämma."}
        </div>
        <div className="flex justify-between max-w-6xl m-auto sm:flex-col">
          <div className="w-80 h-56 bg-white rounded-2xl p-6 flex flex-col lg:w-2/7 lg:h-64 md:w-2/7 md:h-64 md:p-4">
            <div className="flex items-center">
              {isLanding ? (
                <AudioIcon type="landing" />
              ) : (
                <AudioIcon type="faded" />
              )}
              <div className="font-bold text-xl ml-4">Lyssna</div>
            </div>
            <div className="text-sm max-h-20 mt-5 text-gray-500 md:mt-3"
              dangerouslySetInnerHTML={{ __html: listeningDescription }}
            >
            </div>
          </div>
          <div className="w-80 h-56 bg-white rounded-2xl p-6 flex flex-col lg:w-2/7 lg:h-64 md:w-2/7 md:h-64 md:p-4">
            <div className="flex items-center">
              {isLanding ? (
                <ReadingIcon type="landing" />
              ) : (
                <ReadingIcon type="faded" />
              )}
              <div className="font-bold text-xl ml-4">Läsa</div>
            </div>
            <div className="text-sm max-h-20 mt-5 text-gray-500 md:mt-3"
              dangerouslySetInnerHTML={{ __html: readingDescription }}
            >
            </div>
          </div>
          <div className="w-80 h-56 bg-white rounded-2xl p-6 flex flex-col lg:w-2/7 lg:h-64  md:w-2/7 md:h-64 md:p-4">
            <div className="flex items-center">
              {isLanding ? (
                <VideoIcon type="landing" />
              ) : (
                <VideoIcon type="faded" />
              )}
              <div className="font-bold text-xl ml-4">Titta</div>
            </div>
            <div className="text-sm max-h-20 mt-5 text-gray-500 md:mt-3"
              dangerouslySetInnerHTML={{ __html: videoDescription }}
            >
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:block">
        <PlatformDescriptionMobile />
      </div>
    </div>
  );
};

PlatformDescription.propTypes = {
  isLanding: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  listeningDescription: PropTypes.string,
  readingDescription: PropTypes.string,
  videoDescription: PropTypes.string,
};

export default PlatformDescription;
