import React from "react";
import PropTypes from "prop-types";
import FormRenderer from "./FormRenderer";

const RegisterFormUser = ({ onSubmit }) => {

  const fields = [
    {
      fieldName: "username",
      placeholder: "Användarnamn",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "firstName",
      placeholder: "Förnamn",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "lastName",
      placeholder: "Efternamn",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "password",
      placeholder: "Lösenord",
      type: "password",
      validation: {
        required: true,
      },
    },
    {
      fieldName: "password-confirm",
      placeholder: "Bekräfta lösenord",
      type: "passwordConfirmation",
      validation: {
        required: true,
      },
    },
  ];

  const header = (
    <>
      <h1 className="font-semibold text-2xl text-blue-500">Registrera användare</h1>
      <p className="text-sm py-4">
        Fyll i dina uppgifter och börja med LearnFlow redan idag.
      </p>
    </>
  );

  const bottom = (
    <>
      <button type="submit" className="bg-black text-white w-full rounded font-semibold py-2">
        Registrera
      </button>
    </>
  );

  return (
    <FormRenderer fields={fields} submit={onSubmit} header={header} bottom={bottom} />
  );
};

RegisterFormUser.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RegisterFormUser;
