import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { actions as achievementsActions } from "../redux/actions/achievementsActions";
import NotificationMsg from "./NotificationMsg";
import Confetti from "react-dom-confetti";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationsHandler = ({ viewNotification, newAchievement }) => {
  const config = {
    angle: "90",
    spread: "360",
    startVelocity: "20",
    elementCount: "200",
    dragFriction: 0.12,
    duration: 4500,
    stagger: 3,
    width: "7px",
    height: "7px",
    perspective: "500px",
    colors: ["#a67c00", "#bf9b30", "#ffbf00", "#ffcf40", "#ffdc73 "]
  };

  useEffect(() => {
    if (newAchievement) {
      viewNotification(newAchievement.id);
      toast(<NotificationMsg achievement={newAchievement.achievement} />, {
          position: "top-right",
          autoClose: 5000,
          type: "success",
          icon: false,
      });
    }
  }, [newAchievement]);

  return (
    <>
      <div className="fixed top-0 right-20">
        <Confetti
          active={newAchievement}
          config={config}
        />
      </div>
      <ToastContainer />
    </>
  );
};

NotificationsHandler.propTypes = {
  viewNotification: PropTypes.func.isRequired,
  newAchievement: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    newAchievement: state.achievements.newAchievement,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    viewNotification: bindActionCreators(achievementsActions.markNotificationAsSeen, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsHandler);
