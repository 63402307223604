import React from "react";
import PropTypes from "prop-types";

const SurveyQuestion = ({ question, pickAnswer, answer }) => {
  const questionName = question.id;
  const answers = () => {
    let ans = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 6; i++) {
      const a = (
        <div key={i}>
          <input
            type="radio"
            id={`ans${i}`}
            name={questionName}
            value={i}
            checked={answer === i}
            onChange={(e) => pickAnswer(question.id, parseInt(e.target.value, 10))}
          />
        </div>
      );
      ans.push(a);
    }
    return ans;
  };

  return (
    <div className="flex py-3 sm:flex-col sm:bg-white sm:shadow-accent sm:rounded-lg sm:mb-6 sm:border sm:border-gray-100 sm:py-2">
      <div className="w-1/2 font-semibold text-sm sm:w-full sm:px-4 sm:py-2">
        {question.content}
      </div>
      <div className="w-1/2 flex justify-center sm:w-full sm:flex-col sm:px-4">
        <div className="hidden justify-between text-xs w-full sm:flex py-2 font-semibold">
          <p>
            Nästan
            <br />
            aldrig
          </p>
          <p className="text-right">
            Nästan
            <br />
            alltid
          </p>
        </div>
        <div
          className="w-11/12 flex justify-between text-sm sm:w-full"
          id={questionName}
        >
          {answers()}
        </div>
        <div className="w-full items-center hidden sm:flex justify-between text-xs px-0.5 font-semibold">
          <p>1</p>
          <p>2</p>
          <p>3</p>
          <p>4</p>
          <p>5</p>
          <p>6</p>
        </div>
      </div>
    </div>
  );
};

SurveyQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  pickAnswer: PropTypes.func.isRequired,
  answer: PropTypes.number,
};

export default SurveyQuestion;
