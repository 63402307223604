/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../redux/actions/surveyActions";
import { actions as authActions } from "../redux/actions/authActions";
import LoadingIndicator from "../components/LoadingIndicator";
import SurveyPersonalInfo from "../components/SurveyPersonalInfo";
import SurveySection from "../components/SurveySection";

const SurveyPage = ({
  fetchSurveys,
  submitSurvey,
  surveys,
  completeSurvey,
  skipSurvey,
}) => {
  let history = useHistory();
  const personalErrorsObj = {
    role: false,
    sex: false,
  };

  const [section, setSection] = useState(1);
  const [role, setRole] = useState();
  const [sex, setSex] = useState();
  const [errors, setErrors] = useState(personalErrorsObj);
  const [answers, setAnswers] = useState([]);
  const [tiredError, setTirredError] = useState(false);
  const [individualError, setIndividualdError] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [organisationError, setOrganisationError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSurveys();
  }, [fetchSurveys]);

  const resetErrors = () => {
    setErrors(personalErrorsObj);
  };

  const resetAnswersErrors = () => {
    setTirredError(false);
    setIndividualdError(false);
    setTeamError(false);
    setOrganisationError(false);
  };

  const validatePersonal = () => {
    resetErrors();
    const tmpErr = {
      role: role == null,
      sex: sex == null,
    };
    setErrors(tmpErr);
  };

  const pickAnswer = (id, ans) => {
    const tmp = [...answers];
    const idx = tmp.map((el) => el.question).indexOf(id);
    if (idx === -1) {
      tmp.push({ question: id, answer: ans });
      setAnswers(tmp);
      return;
    }
    tmp[idx] = { question: id, answer: ans };
    setAnswers(tmp);
  };

  if (!surveys) {
    return <LoadingIndicator />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const onPrevSection = () => {
    if (section === 1) {
      return;
    }
    setSection(section - 1);
  };

  const onNextSection = () => {
    validatePersonal();
    resetAnswersErrors();
    if (section === 5 || role == null || sex == null) {
      return;
    }

    if (section === 2 && answers.length < surveys[0].questions_tired.length) {
      setTirredError(true);
      return;
    }

    if (
      section === 3 &&
      answers.length - surveys[0].questions_tired.length <
        surveys[0].questions_individual.length
    ) {
      setIndividualdError(true);
      return;
    }

    if (
      section === 4 &&
      answers.length -
        (surveys[0].questions_tired.length +
          surveys[0].questions_individual.length) <
        surveys[0].questions_team.length
    ) {
      setTeamError(true);
      return;
    }

    setSection(section + 1);
  };

  const onSubmit = () => {
    if (
      section !== 5 ||
      answers.length -
        (surveys[0].questions_tired.length +
          surveys[0].questions_individual.length +
          surveys[0].questions_team.length) !==
        surveys[0].questions_organisation.length
    ) {
      setOrganisationError(true);
      return;
    }
    completeSurvey();
    submitSurvey(surveys[0].id, answers, role).then(() => {
      history.push("/enkat-resultat");
    });
  };

  const onSurveySkip = () => {
    setLoading(true);
    skipSurvey().then(() => {
      setTimeout(() => {
        setLoading(false);
        completeSurvey();
        history.push("/");
      }, 2000);
    });
  };

  if (surveys?.length > 0) {
    const survey = surveys[0];
    return (
      <div className="flex h-screen w-full sm:flex-col sm:justify-start">
        <div
          className={"w-1/2 h-full bg-white shadow-accent flex flex-col justify-between " +
          "sm:w-full sm:shadow-none sm:justify-start sm:h-auto"}
        >
          <div className="h-24 text-2xl px-14 border-b border-gray-400 flex items-center md:px-6 sm:px-6 sm:h-16 sm:border-0 sm:text-lg">
            Learn
            <span className="font-bold">
              Flow<sup>®</sup>
            </span>
          </div>
          {section === 1 && (
            <div className="px-14 h-5/6 flex flex-col justify-center md:px-6 sm:px-6 sm:h-auto">
              <div className="rounded-md bg-blue-500 text-white w-24 py-1 text-center sm:hidden">
                {survey.duration} min
              </div>
              <div className="text-2xl font-semibold py-6 md:text-xl sm:font-semibold sm:text-3xl sm:py-0">
                {survey.name}
              </div>
              <div
                className="text-base font-semibold py-6 md:font-normal md:text-base sm:text-base sm:py-2"
                dangerouslySetInnerHTML={{ __html: survey.description }}
              ></div>
            </div>
          )}
          {section === 2 && (
            <div className="px-14 h-4/6 flex flex-col justify-center md:px-6 sm:px-6 sm:h-auto sm:pt-10">
              <div className="text-2xl font-semibold py-6 md:text-xl sm:font-semibold sm:text-3xl sm:py-0">
                {survey.tired_of_change_section_title}
              </div>
              <div
                className="text-base font-semibold py-6 md:font-normal md:text-base sm:text-base sm:py-2"
                dangerouslySetInnerHTML={{
                  __html: survey.tired_of_change_section_description,
                }}
              ></div>
            </div>
          )}
          {section === 3 && (
            <div className="px-14 h-4/6 flex flex-col justify-center md:px-6 sm:px-6 sm:h-auto sm:pt-8">
              <div className="text-2xl font-semibold py-6 md:text-xl sm:font-semibold sm:text-3xl sm:py-0">
                {survey.individual_section_title}
              </div>
              <div
                className="text-base font-semibold py-6 md:font-normal md:text-base sm:text-base sm:py-2"
                dangerouslySetInnerHTML={{
                  __html: survey.individual_section_description,
                }}
              ></div>
            </div>
          )}
          {section === 4 && (
            <div className="px-14 h-4/6 flex flex-col justify-center md:px-6 sm:px-6 sm:h-auto sm:pt-8">
              <div className="text-2xl font-semibold py-6 md:text-xl sm:font-semibold sm:text-3xl sm:py-0">
                {survey.team_section_title}
              </div>
              <div
                className="text-base font-semibold py-6 md:font-normal md:text-base sm:text-base sm:py-2"
                dangerouslySetInnerHTML={{
                  __html: survey.team_section_description,
                }}
              ></div>
            </div>
          )}
          {section === 5 && (
            <div className="px-14 h-4/6 flex flex-col justify-center md:px-6 sm:px-6 sm:h-auto sm:pt-10">
              <div className="text-2xl font-semibold py-6 md:text-xl sm:font-semibold sm:text-3xl sm:py-0">
                {survey.organisation_section_title}
              </div>
              <div
                className="text-base font-semibold py-6 md:font-normal md:text-base sm:text-base sm:py-2"
                dangerouslySetInnerHTML={{
                  __html: survey.organisation_section_description,
                }}
              ></div>
            </div>
          )}
          <div className="border-t border-gray-400 h-24 flex items-center justify-center sm:hidden px-2">
            {section !== 1 && (
              <button
                onClick={onPrevSection}
                className="rounded-md bg-blue-600 text-white w-28 py-2 md:w-20 md:py-1"
              >
                Tillbaka
              </button>
            )}
            {section === 1 && (
              <button
                onClick={() => onSurveySkip()}
                className="rounded-md bg-gray-300 py-2 px-4 md:py-1 ml-6"
              >
                Hoppa över all
              </button>
            )}
            <div className="px-10 whitespace-nowrap">Steg {section} av 5</div>
            {section !== 5 && (
              <button
                onClick={onNextSection}
                className="rounded-md bg-blue-600 text-white w-28 py-2 md:w-20 md:py-1"
              >
                Nästa
              </button>
            )}
            {section === 5 && (
              <button
                onClick={onSubmit}
                className="rounded-md bg-blue-600 text-white w-28 py-2 md:w-20 md:py-1"
              >
                Submit
              </button>
            )}
          </div>
        </div>
        <div
          className={
            "w-1/2 h-full bg-gray-200 bg-survey-page flex justify-center " +
            "items-center flex-col sm:w-full sm:bg-white sm:h-auto sm:p-0"
          }
        >
          {section !== 1 && (
            <div className="bg-gray-300 rounded-lg h-3 w-1/2 mx-2 absolute top-8 sm:w-11/12 sm:top-16 sm:mx-4">
              <div
                className="absolute h-full rounded-lg bg-blue-600 mr-10 sm:mr-0"
                // eslint-disable-next-line no-mixed-operators
                style={{ width: (section / 5) * 100 + "%" }}
              />
            </div>
          )}
          {section === 1 && (
            <SurveyPersonalInfo
              role={role}
              setRole={setRole}
              sex={sex}
              setSex={setSex}
              errors={errors}
            />
          )}
          {section === 2 && (
            <SurveySection
              answers={answers}
              pickAnswer={pickAnswer}
              questions={survey.questions_tired}
              error={tiredError}
            />
          )}
          {section === 3 && (
            <SurveySection
              answers={answers}
              pickAnswer={pickAnswer}
              questions={survey.questions_individual}
              error={individualError}
            />
          )}
          {section === 4 && (
            <SurveySection
              answers={answers}
              pickAnswer={pickAnswer}
              questions={survey.questions_team}
              error={teamError}
            />
          )}
          {section === 5 && (
            <SurveySection
              answers={answers}
              pickAnswer={pickAnswer}
              questions={survey.questions_organisation}
              error={organisationError}
            />
          )}
        </div>
        <div className="hidden items-center justify-center sm:flex flex-col px-6 py-6">
          {section !== 5 && (
            <button
              onClick={onNextSection}
              className="rounded-lg bg-blue-600 text-white py-3 w-full"
            >
              Nästa
            </button>
          )}
          {section === 5 && (
            <button
              onClick={onSubmit}
              className="rounded-lg bg-blue-600 text-white py-3 w-full"
            >
              Submit
            </button>
          )}
          {section !== 1 && (
            <button
              onClick={onPrevSection}
              className="underline text-gray-500 py-3 w-full"
            >
              Tillbaka
            </button>
          )}
        </div>
      </div>
    );
  }

  return <div>No surveys available</div>;
};

SurveyPage.propTypes = {
  fetchSurveys: PropTypes.func.isRequired,
  submitSurvey: PropTypes.func.isRequired,
  completeSurvey: PropTypes.func.isRequired,
  skipSurvey: PropTypes.func.isRequired,
  surveys: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    surveys: state.surveys.surveys,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSurveys: bindActionCreators(actions.fetchSurveys, dispatch),
    submitSurvey: bindActionCreators(actions.submitSurvey, dispatch),
    skipSurvey: bindActionCreators(actions.skipSurvey, dispatch),
    completeSurvey: bindActionCreators(authActions.completeSurvey, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);
