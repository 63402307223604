import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as quizActions } from "../redux/actions/quizCourseActions";
import { actions as coursesActions } from "../redux/actions/coursesActions";
import isEmptyObject from "../utils/isEmptyObject";
import { BiBlock, BiCheck } from "react-icons/bi";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import Confetti from "react-dom-confetti";

const QuizResultPage = ({ fetchQuizScore, fetchCourseDetails, courseDetails, quizScore, resetQuiz }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [totalScore, setTotalScore] = useState();
  const [quizLength, setQuizLength] = useState(null);
  const [filteredScore, setFilteredScore] = useState([]);
  const config = {
    angle: "90",
    spread: "360",
    startVelocity: "20",
    elementCount: "200",
    dragFriction: 0.12,
    duration: 4500,
    stagger: 3,
    width: "7px",
    height: "7px",
    perspective: "500px",
    colors: ["#a67c00", "#bf9b30", "#ffbf00", "#ffcf40", "#ffdc73 "]
  };
  let { id } = useParams();
  let { title } = useParams();

  useEffect(() => {
    return () => {
      resetQuiz();
    };
  }, []);

  useEffect(() => {
    setErrorOccurred(false);
    fetchCourseDetails(title).catch(() => {
      setErrorOccurred(true);
    });
  }, [fetchCourseDetails]);

  const removeDuplicates = (arr) => {
    const duplicates = new Set();
    const filtered = arr.filter((el) => {
      const duplicate = duplicates.has(el.question.id);
      duplicates.add(el.question.id);
      return !duplicate;
    });
    setFilteredScore(filtered);
  };

  useEffect(() => {
    removeDuplicates(quizScore);
  }, [quizScore]);

  useEffect(() => {
    setErrorOccurred(false);
    fetchQuizScore(id).catch(() => {
      setErrorOccurred(true);
    });
  }, [fetchQuizScore, id]);

  useEffect(() => {
    setQuizLength(quizScore.length);
  }, [setQuizLength, quizScore]);

  useEffect(() => {
    let tempScore = 0;
    const duplicates = new Set();
    const filtered = quizScore.filter((el) => {
      const duplicate = duplicates.has(el.question.id);
      duplicates.add(el.question.id);
      return !duplicate;
    });
    filtered.forEach((el) => {
      el.question.answer.forEach((element) => {
        if (element.correct) {
          tempScore += 1;
          setTotalScore(tempScore);
        }
      });
    });
  }, [setTotalScore, quizScore]);

  if (isEmptyObject(quizScore)) {
    return <LoadingIndicator />;
  }
  if (errorOccurred) {
    return <ErrorOccurred />;
  }

  return (
    <div className="pt-8 h-full flex flex-col items-center justify-start mt-12 bg-quiz-result sm:mt-0 sm:justify-start">
      <div className="bg-white shadow-accent rounded-lg w-4/5 border border-gray-100 py-6 md:w-11/12 sm:bg-transparent sm:w-full sm:mt-0">
        <div className="flex border-b border-gray-400 pb-4 sm:hidden">
          <div className="pl-7 font-bold">Resultat : </div>
          <div className="pl-2">{courseDetails.title}</div>
        </div>
        <div className="px-7 pt-5">
        <h1 className="text-2xl inline-block relative">
            Bra jobbat! Du fick {totalScore ? totalScore : "0"} av {quizLength} rätt.
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Confetti
                active={totalScore === quizLength}
                config={config}
              />
            </div>
          </h1>
          <p>Nedanför kan du se rätt svar på frågorna.</p>
          <div className="flex flex-wrap justify-between py-6 sm:pb-0">
            {filteredScore &&
              filteredScore.map((el, idx) => (
                <div
                  key={`${el.question.id}-${idx}`}
                  className="rounded-lg shadow-accent p-6 border border-gray-100 bg-white mb-4 flex flex-col justify-start w-48per sm:w-full sm:p-4"
                >
                  <div className="pb-5 md:text-sm">{el.question.content}</div>
                  {el.question.answer.map((element) => (
                    <div key={`answer-${element.id}`} className="flex justify-between items-center">
                      <div className="font-bold md:text-sm">
                        Ditt svar: {element.name}
                      </div>
                      {element.correct === false ? (
                        <BiBlock className="text-red-600 w-7 h-7" />
                      ) : (
                        <BiCheck className="text-green-600 w-7 h-7" />
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
          <div className="flex justify-evenly py-4 px-6 sm:px-0 sm:flex-col sm:items-center">
            <Link
              to={`/tema/${title}`}
              className="rounded-md h-12 w-52 font-light border border-black flex justify-center items-center sm:w-9/12 sm:mb-5"
            >
              Gå tillbaka till tema
            </Link>
            <Link
              to={`/tema/${title}/quiz-enkat`}
              className="bg-black text-white rounded-md h-12 w-52 font-light flex justify-center items-center sm:w-9/12"
            >
              Fyll i handlingsplan
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

QuizResultPage.propTypes = {
  fetchQuizScore: PropTypes.func.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  courseDetails: PropTypes.object.isRequired,
  resetQuiz: PropTypes.func.isRequired,
  quizScore: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    quizScore: state.quizCourse.quizScore,
    courseDetails: state.courses.courseDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuizScore: bindActionCreators(quizActions.fetchQuizScore, dispatch),
    fetchCourseDetails: bindActionCreators(coursesActions.fetchCourseDetails, dispatch),
    resetQuiz: bindActionCreators(quizActions.resetQuiz, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizResultPage);
