export const READING_COURSE_REQUEST = "READING_COURSE_REQUEST";
export const READING_COURSE_SUCCESS = "READING_COURSE_SUCCESS";
export const READING_COURSE_FAILURE = "READING_COURSE_FAILURE";

export const READING_CHAPTER_REQUEST = "READING_CHAPTER_REQUEST";
export const READING_CHAPTER_SUCCESS = "READING_CHAPTER_SUCCESS";
export const READING_CHAPTER_FAILURE = "READING_CHAPTER_FAILURE";

export const RESET_READING_DETAILS = "RESET_READING_COURSE";

const fetchReadingCourse = (title) => ({
  types: [
    READING_COURSE_REQUEST,
    READING_COURSE_SUCCESS,
    READING_COURSE_FAILURE,
  ],
  endpoint: `course/reading/${encodeURIComponent(title)}`,
  method: "GET",
});

const fetchReadingChapter = (id) => ({
  types: [
    READING_CHAPTER_REQUEST,
    READING_CHAPTER_SUCCESS,
    READING_CHAPTER_FAILURE,
  ],
  endpoint: `reading/${id}`,
  method: "GET",
});

const resetReadingDetails = () => {
  return {
    type: RESET_READING_DETAILS,
  };
};


export const actions = {
  fetchReadingCourse,
  fetchReadingChapter,
  resetReadingDetails,
};

const ACTION_HANDLERS = {
  [READING_COURSE_SUCCESS]: (state, { payload }) => ({
    ...state,
    readingCourse: payload,
  }),
  [READING_CHAPTER_SUCCESS]: (state, { payload }) => ({
    ...state,
    readingChapter: payload,
  }),
  [RESET_READING_DETAILS]: (state) => ({
    ...state,
    readingCourse: [],
  }),
};

export const initialState = {
  readingCourse: [],
  readingChapter: {},
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}
