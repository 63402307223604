import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import NewFormInput from "./NewFormInput";
import NewFormPasswordConfirmation from "./NewFormPasswordConfimation";

const FormRenderer = ({ fields, submit, header, bottom }) => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  return (
    <form onSubmit={handleSubmit(submit)}>
      {header}
      <div className="form-group flex flex-col">
        {fields.map((f, idx) => {
          if (f.type === "passwordConfirmation") {
            return (
              <NewFormPasswordConfirmation
                key={idx}
                register={register}
                id={f.fieldName}
                name={f.placeholder}
                validation={f.validation}
                type={f.type}
                errors={errors}
                watch={watch}
              />
            );
          }
            return (
              <NewFormInput
                key={idx}
                register={register}
                id={f.fieldName}
                name={f.placeholder}
                validation={f.validation}
                type={f.type}
                errors={errors}
              />
            );

        })}
      </div>
      {bottom}
    </form>
  );
};

FormRenderer.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      placeholder: PropTypes.string,
      validation: PropTypes.object,
      type: PropTypes.string,
    }).isRequired
  ),
  submit: PropTypes.func.isRequired,
  bottom: PropTypes.object.isRequired,
  header: PropTypes.object,
};

export default FormRenderer;
