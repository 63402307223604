import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as videoActions } from "../redux/actions/videoCourseActions";
import { actions as coursesActions } from "../redux/actions/coursesActions";
import isEmptyObject from "../utils/isEmptyObject";
import ErrorOccurred from "../components/ErrorOccurred";
import LoadingIndicator from "../components/LoadingIndicator";
import BackgroundImage from "../components/BackgroundImage";
import VideoChapter from "../components/VideoChapter";
import VideoPlayer from "../components/VideoPlayer";
import VideoVimeoPlayer from "../components/VideoVimeoPlayer";
import VideoVimeoMobilePlayer from "../components/VideoVimeoMobilePlayer";
import { ImArrowRight2 } from "react-icons/im";

import image from "../assets/example-video.png";
import imageMobile from "../assets/example-video-mobile.png";
import bgImage from "../assets/bg-video-page.png";
import bgImageRwd from "../assets/bg-mobile-video.svg";
import { Link } from "react-router-dom";

const VideoPage = ({
  fetchVideoCourse,
  fetchCourseDetails,
  resetVideoDetails,
  videoDetails,
  courseDetails,
}) => {
  const [errorVideoDetailsOccurred, setErrorVideoDetailsOccurred] = useState(false);
  const [errorCourseDetailsOccurred, setErrorCourseDetailsOccurred] = useState(false);
  let { id } = useParams();
  const [currentIdx, setCurrentIdx] = useState(id ? parseInt(id, 10) : 0);
  const [autoplay, setAutoplay] = useState(false);
  let { title } = useParams();
  let history = useHistory();

  if (id == null) {
    id = 0;
  }

  const onVideoChange = (idx) => {
    history.push(`/tema/${title}/video/${idx}`);
    setCurrentIdx(idx);
  };

  const handleVideoEnd = (currentIndex) => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < videoDetails.length) {
      setAutoplay(true);
      setCurrentIdx(nextIndex);
      onVideoChange(nextIndex);
    }
  };

  useEffect(() => {
    setErrorVideoDetailsOccurred(false);
    fetchVideoCourse(title).catch(() => {
      setErrorVideoDetailsOccurred(true);
    });
    return () => {
      resetVideoDetails();
    };
  }, [fetchVideoCourse, title]);

  useEffect(() => {
    if (!isEmptyObject(courseDetails)) {
      return;
    }
    setErrorCourseDetailsOccurred(false);
    fetchCourseDetails(title).catch(() => {
      setErrorCourseDetailsOccurred(true);
    });
  }, [fetchCourseDetails, title]);

  if (isEmptyObject(videoDetails) || isEmptyObject(courseDetails)) {
    return <LoadingIndicator />;
  }
  if (errorVideoDetailsOccurred || errorCourseDetailsOccurred) {
    return <ErrorOccurred />;
  }

  console.log(videoDetails);

  return (
    <>
      <div className="w-full -z-20">
        <BackgroundImage
          title={courseDetails.title}
          slug={courseDetails.slug}
          image={image}
          imageMobile={imageMobile}
          type="video"
          isVideoPage="isVideoPage"
          category={courseDetails.category}
        />
        <div className="hidden h-36 rounded-md mx-auto w-full flex-col bg-transparent shadow-none absolute top-20 sm:flex px-6">
          <div className="p-4 flex-col justify-center sm:p-0 text-white w-full">
            <div className="font-bold z-10 text-2xl text-blue-500 pb-6">
              Titta
            </div>
            <div className="font-bold text-base pb-2">
              Vad handlar temat om?
            </div>
            <div className="text-base">{courseDetails.description}</div>
          </div>
        </div>
        <div className="sm:hidden">
          {videoDetails[currentIdx].vimeo_url ? (
            <VideoVimeoPlayer
              id={videoDetails[currentIdx].id}
              idx={currentIdx}
              vimeoUrl={videoDetails[currentIdx].vimeo_url}
              handleVideoEnd={handleVideoEnd}
              autoplay={autoplay}
              setAutoplay={setAutoplay}
            />
          ) : (
            <VideoPlayer
              id={videoDetails[currentIdx].id}
              idx={currentIdx}
              videos={videoDetails[currentIdx].stream}
              handleVideoEnd={handleVideoEnd}
              autoplay={autoplay}
            />
          )}
          <div className="relative w-full">
            <img
              className="w-full absolute -z-20 object-contain -top-96 md:-top-32"
              src={bgImage}
            />
            <div className="w-8/12 mx-auto py-10 md:w-full md:px-12">
              <div className="bg-blue-500 text-white rounded-md flex justify-center items-center w-16 font-bold">
                Del {currentIdx + 1}
              </div>
              <div className="font-bold text-black py-3">
                {videoDetails[currentIdx].name}
              </div>
              <div className="text-black">
                {videoDetails[currentIdx].content}
              </div>
            </div>
            <div className="flex w-8/12 pb-10 flex-wrap mx-auto md:w-full md:px-12">
              {videoDetails &&
                videoDetails.map((el, idx) => (
                  <VideoChapter
                    key={el.id}
                    idx={idx}
                    name={el.name}
                    currentIdx={currentIdx}
                    onVideoChange={onVideoChange}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex relative w-full flex-col">
        <img
          className="w-full absolute -z-20 object-contain bottom-20 md:-top-32"
          src={bgImageRwd}
        />
        <div className="font-semibold px-8 py-4 text-sm">Alla delar</div>
        <div className="pb-6 w-full px-6">
          {videoDetails &&
            videoDetails.map((el, idx) => (
              el.vimeo_url ? (
                <VideoVimeoMobilePlayer
                  key={el.id}
                  id={el.id}
                  idx={idx}
                  vimeoUrl={el.vimeo_url}
                />
              ) : (
                <VideoPlayer
                  key={el.id}
                  idx={idx}
                  videos={el.stream}
                />
              )
            ))}
        </div>
      </div>
      <div className="w-8/12 mx-auto pb-10">
        {courseDetails.quiz_enabled && (
          <div className="flex bg-blue-500 shadow-accent max-w-md mx-auto h-36 p-4 flex-col justify-around mb-4 rounded-lg sm:mt-5">
            <div className="bg-white text-blue-500 rounded-md flex justify-center items-center w-1/6">
              Quiz
            </div>
            <div className="font-bold text-white">
              Är du klar? Sätt igång quizet nu!
            </div>
            <Link
              to={`/tema/${title}/quiz`}
              className="text-white font-bold flex items-center sm:self-end sm:text-sm"
            >
              Starta Quiz
              <ImArrowRight2 className="ml-2" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

VideoPage.propTypes = {
  fetchVideoCourse: PropTypes.func.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  resetVideoDetails: PropTypes.func.isRequired,
  videoDetails: PropTypes.array.isRequired,
  courseDetails: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    videoDetails: state.videoCourse.videoCourse,
    courseDetails: state.courses.courseDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchVideoCourse: bindActionCreators(
      videoActions.fetchVideoCourse,
      dispatch
    ),
    fetchCourseDetails: bindActionCreators(
      coursesActions.fetchCourseDetails,
      dispatch
    ),
    resetVideoDetails: bindActionCreators(
      videoActions.resetVideoDetails,
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);
