import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Chart from "react-google-charts";
import LoadingIndicator from "../../components/LoadingIndicator";

const ProfileStats = ({ courses }) => {

  const [individualCourses, setIndividualCourses] = useState(0);
  const [organisationCourses, setOrganisationCourses] = useState(0);
  const [groupCourses, setGroupCourses] = useState(0);

  const countCourses = () => {
    let individual = 0;
    let organisation = 0;
    let group = 0;

    courses?.forEach((element) => {
      if (element.category === 0) {
        individual += 1;
      }
      if (element.category === 1) {
        organisation += 1;
      }
      if (element.category === 2) {
        group += 1;
      }
    });

    setIndividualCourses(individual);
    setOrganisationCourses(organisation);
    setGroupCourses(group);

  };

  useEffect(() => {
    countCourses();
  }, [courses]);

  if (!courses) {
    return <LoadingIndicator />;
  }

  return (
    <div className="w-48per h-96 bg-white rounded-card flex flex-col justify-start border border-gray-100 sm:w-full">
      <div className="border-b border-gray-300 py-4 sm:border-0">
        <div className="font-semibold text-lg px-5 md:px-4">
          Fördelning av genomförda teman
        </div>
        <p className="text-gray-500 text-xs px-5 md:px-4 sm:hidden">
          Här ser du fördelningen mellan vilka områden du genomfört.
        </p>
      </div>
      <div className={`flex justify-center items-center overflow-hidden grid ${courses.length === 0 ? "" : "grid-cols-2"} direction-col h-full`}>
        {
          courses.length === 0 ? (
            <div className="flex items-center justify-center text-gray-500 text-xs">
              Du har inga genomförda teman.
            </div>
          ) : (
            <>
              <div className="flex h-full justify-start items-center pl-16">
                <ul className="legend leading-loose font-semibold text-lg">
                  <li>Individnivå</li>
                  <li>Organisationsnivå</li>
                  <li>Gruppnivå</li>
                </ul>
              </div>
              <div>
                <Chart
                  width={"100%"}
                  height={"auto"}
                  chartType="PieChart"
                  loader={<div>Läser in diagram</div>}
                  data={[
                    ["TypeOfCourses", "NumberOfCourses"],
                    ["Individnivå", individualCourses],
                    ["Organisationsnivå", organisationCourses],
                    ["Gruppnivå", groupCourses],
                  ]}
                  options={{
                    titleTextStyle: { fontSize: 20 },
                    legend: {
                      position: "none",
                    },
                    chartArea: { width: "60%", height: "90%", position: "right" },
                    colors: ["#5A5B9B", "#7CDF92", "#257AFA"],
                    pieSliceText: "none",
                    pieHole: 0.7,
                  }}
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

ProfileStats.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default ProfileStats;
