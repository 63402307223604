import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ courseTitle, courseSlug, courseCategory, type }) => {
  const [categoryObj, setCategoryObj] = useState({
    "name": "",
    "slug": "",
  });
  const setCategoryFields = (category) => {
    if (category === 0) {
      setCategoryObj({
        "name": "Individnivå",
        "slug": "individ",
      });
    }

    if (category === 1) {
      setCategoryObj({
        "name": "Organisationsnivå",
        "slug": "organisation",
      });
    }

    if (category === 2) {
      setCategoryObj({
        "name": "Gruppnivå",
        "slug": "grupp",
      });
    }
  };

  useEffect(() => {
    if (courseCategory != null) {
      setCategoryFields(courseCategory);
    }
  }, [courseCategory]);

  return (
    <div className="flex pb-2 pl-1 text-base md:text-sm sm:hidden">
      <Link to="/" className={`${type ? "text-white" : "text-black"} `}>
        Utforska
      </Link>
      {categoryObj.name && (
        <Link to={`/teman/${categoryObj.slug}`} className={`${type ? "text-white" : "text-black"} `}>
          &nbsp;/ {categoryObj.name}
        </Link>
      )}
      {courseTitle && (
        <Link
          to={`/tema/${encodeURIComponent(courseSlug)}`}
          className={`${type ? "text-white" : "text-blue-600"} `}
        >
          &nbsp;/ {courseTitle}
        </Link>
      )}
      {type === "text" && (
        <span className="cursor-pointer text-yellow-500 font-bold">&nbsp;/ Läsa</span>
      )}
      {type === "audio" && (
        <span className="cursor-pointer text-green-500 font-bold">&nbsp;/ Lyssna</span>
      )}
      {type === "video" && (
        <span className="cursor-pointer text-blue-500 font-bold">&nbsp;/ Titta</span>
      )}
    </div>
  );
};

Breadcrumbs.propTypes = {
  courseTitle: PropTypes.string,
  courseSlug: PropTypes.string,
  courseCategory: PropTypes.number,
  type: PropTypes.string,
};

export default Breadcrumbs;
