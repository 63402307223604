/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import moneyFormatter from '../utils/moneyFormatter';


const SubscriptionConfirmModal = ({
  name,
  price,
  tax,
  color,
  onCancel,
  onConfirm,
}) => {
  const taxAmount = price * (tax * 0.01);
  const total = price + taxAmount;
  return (
    <div
    className="fixed bg-opacity h-screen w-screen z-40"
    >
      <div
        className="bg-white fixed z-40 opacity-100 text-center py-8 px-12 rounded-card top-1/3 left-1/2 transform -translate-x-1/2 md:-translate-y-3/4
        flex flex-col justify-center items-center space-y-6 inner-shadow-lg"
      >
        <div className="relative">
        <div className="uppercase whitespace-nowrap md:text-2xl font-bold">
          Bekräfta köp av
        </div>
        </div>
        <div>
          <div
            className="text-2xl font-bold mb-2"
            style={{ color: color }}
          >{name}</div>
          <div
            className="mt-6 mb-2"
          >
            <div
              className="text-xl font-bold mt-2"
            >Totalt: {moneyFormatter(total)}</div>
            <div
              className="text-xs opacity-60"
            >(Moms {moneyFormatter(taxAmount)})</div>
          </div>
        </div>
        <div
          className="flex space-x-8 select-none"
        >
          <div
            onClick={onCancel}
            className="whitespace-nowrap bg-gray-500 py-2 px-3 text-white uppercase md:w-1/2
             hover:opacity-100 opacity-90 hover:shadow-lg hover:cursor-pointer"
          >Avbryt</div>
      <a
        onClick={onConfirm}
        className="whitespace-nowrap bg-black py-2 px-3 text-white uppercase md:w-1/2
         hover:opacity-100 opacity-90 hover:shadow-lg hover:cursor-pointer"
      >köp
      </a>
        </div>
      </div>
    </div>
  );
};

SubscriptionConfirmModal.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SubscriptionConfirmModal;
