import React from "react";
import PropTypes from "prop-types";

const PlatformDescriptionSliderItemMobile = ({
   title,
   image,
   description,
   icon,
  }) => {
  return (
      <div className="w-full h-96 mb-24">
        <div className="rounded-card p-6 bg-white relative h-full flex flex-col space-y-4">
            <div className="w-full h-1/2">
              <img src={image} className="rounded-md object-cover h-full" />
            </div>
            <div className="flex items-center">
              {icon}
              <div className="font-semibold text-2xl ml-4">
                {title}
              </div>
            </div>
            <div className="text-sm h-3/5 overflow-scroll text-gray-800 leading-5">
              {description}
            </div>
          </div>
      </div>
  );
};

PlatformDescriptionSliderItemMobile.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
};

export default PlatformDescriptionSliderItemMobile;
