import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as authActions } from "../redux/actions/authActions";
import RegisterFormCompany from "../components/forms/RegisterFormCompany";
import FormError from "../components/forms/FormError";

const RegisterPageMobile = ({ register, login }) => {
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const onSubmit = ({
    username,
    email,
    password,
    firstName,
    lastName,
    orgNumber,
    orgName,
  }) => {
    setErrors(false);
    setLoading(true);
    register(username, email, password, firstName, lastName, orgNumber, orgName)
      .then(() => {
        login(username, password).then(() => {
          history.push("/prenumerationer");
        });
      })
      .catch((e) => {
        setErrors(e);
        setLoading(false);
      });
  };

  return (
    <div className="w-full h-screen pt-20 bg-black px-6 flex justify-evenly flex-col">
      <div className="logo-gradient text-6xl font-bold pb-2">
        Register
      </div>
      <RegisterFormCompany onSubmit={onSubmit} />
      <div className="text-gray-500 text-center">
        Har du redan ett konto?{" "}
        <Link to="/login">
          <button className="text-gray-100">Logga in</button>
        </Link>
      </div>
      <div className="h-10">
        {errors && (
          <div className="mt-1 text-center text-red-500 text-sm">
            <FormError error={errors} />
          </div>
        )}
        {loading && <div className="mt-1 text-center text-sm text-white">Läser in...</div>}
      </div>
    </div>
  );
};

RegisterPageMobile.propTypes = {
  register: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(authActions.login, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RegisterPageMobile);
