import React from "react";
import PropTypes from "prop-types";
import ProfileSummaryCard from "./ProfileSummayCard";

const ProfileSummary = ({ ongoingCourses, completedCourses, completedAchievements }) => {

  return (
    <div
      className={
        "w-48per " +
        "h-52 " +
        "bg-white " +
        "rounded-card " +
        "px-6 " +
        "py-4 " +
        "flex " +
        "flex-row " +
        "justify-between " +
        "items-center " +
        "flex-wrap " +
        "md:h-56 " +
        "sm:w-full " +
        "sm:px-4"}
      >
      <ProfileSummaryCard
        title={"Påbörjade teman "}
        value={ongoingCourses}
      />
      <ProfileSummaryCard
        title={"Genomförda teman"}
        value={completedCourses}
      />
      <ProfileSummaryCard
        title={"Uppnådda prestationer"}
        value={completedAchievements}
      />
    </div>
  );
};

ProfileSummary.propTypes = {
  ongoingCourses: PropTypes.number.isRequired,
  completedCourses: PropTypes.number.isRequired,
  completedAchievements: PropTypes.number.isRequired,
};

export default ProfileSummary;
