import React from "react";
import PropTypes from "prop-types";
import { ImArrowRight2 } from "react-icons/im";

const LandingGradientBanner = ({ title, content, buttonText, buttonUrl }) => {

  return (
    <div className="pt-10 sm:px-6">
      <div className="shadow-lg rounded-lg landing-banner-gradient max-w-6xl m-auto px-12 py-14 flex justify-between items-center md:py-8 md:px-10 sm:flex-col sm:px-4 sm:py-6 sm:items-start">
        <div className="text-white w-2/5 sm:w-full sm:pb-6">
          <div className="text-4xl font-semibold md:text-3xl sm:text-2xl">{title}</div>
          <div className="text-sm font-light pt-8 sm:pt-4"
          dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
        <a
          className="border-2 border-white rounded-lg flex items-center h-12 w-64"
          href={buttonUrl}
        >
          <div className="w-1/4 bg-white h-full flex justify-center items-center rounded-tl-md rounded-bl-md">
            <ImArrowRight2 className="w-20 h-6 text-blue-500" />
          </div>
          <div className="w-3/4 flex justify-center items-center text-white">
            {buttonText}
          </div>
        </a>
      </div>
    </div>
  );
};

LandingGradientBanner.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
};

export default LandingGradientBanner;
