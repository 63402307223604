import { useEffect, useRef } from "react";

const useInterval = (callback, delay) => {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    if (!delay) {
      // eslint-disable-next-line no-empty-function
      return () => {};
    }

    const interval = setInterval(() => {
      // eslint-disable-next-line no-unused-expressions
      callbackRef.current && callbackRef.current();
    }, delay);
    return () => clearInterval(interval);
  }, [delay]);
};

export default useInterval;
