import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../redux/actions/userActions";
import LoadingIndicator from "../LoadingIndicator";
import ResetPasswordForm from "../forms/ResetPasswordForm";

import logo from "../../assets/login-background.png";
import FormError from "../forms/FormError";

const ResetPasswordModal = ({ validateResetPasswordToken, submitResetPassword, closeModal }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [success, setSuccess] = useState(false);
  let { token } = useParams();

  useEffect(() => {
    setIsLoading(true);
    setErrorOccurred(false);
    validateResetPasswordToken(token)
      .catch(() => {
        setErrorOccurred(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [validateResetPasswordToken]);

  const onSubmit = (data) => {
    submitResetPassword(token, data.password)
    .then(() => {
      setShowForm(false);
      setSuccess(true);
    })
    .catch((e) => {
      setErrors(e);
    });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (errors) {
    return <div>Error occurred</div>;
  }

  return (
    <div className="w-full h-full bg-white flex">
      <div className="h-full w-1/2 relative flex justify-center">
        <img className="h-full object-cover w-full" src={logo} alt="logo" />
        <h1 className="text-white absolute bottom-5 text-6xl">
          Learn<span className="font-bold">Flow</span>
          <sup className="text-4xl">®</sup>
        </h1>
      </div>
      <div className="flex flex-col flex-grow px-14 py-10 relative justify-evenly">
        <div
          onClick={closeModal}
          className="absolute top-10 right-10 cursor-pointer"
        >
          <GrFormClose className="w-6 h-6" />
        </div>
        {errorOccurred && (
          <div className="font-semibold px-6 text-center text-xl">Invalid token</div>
        )}
        {showForm && (
          <div className="flex flex-col border-b border-gray-100 px-6 pb-4">
            <div className="font-semibold text-2xl">Återställning av lösenord</div>
            <div className="text-sm my-4 text-gray-800 leading-5">Vänligen fyll i ditt nya lösenord nedan. Klicka sedan på &quot;Bekräfta lösenord&quot;</div>
            <ResetPasswordForm onSubmit={onSubmit} />
          </div>
        )}
        {success && (
          <h2 className="text-blue-600 text-2xl text-center">
            Ditt lösenord har återställts, vänligen logga in.
          </h2>
        )}
        <div className="h-10">
          {errors && (
            <div className="mt-1 text-center text-red-500 text-sm">
              <FormError error={errors} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ResetPasswordModal.propTypes = {
  validateResetPasswordToken: PropTypes.func.isRequired,
  submitResetPassword: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    validateResetPasswordToken: bindActionCreators(actions.validateResetPasswordToken, dispatch),
    submitResetPassword: bindActionCreators(actions.submitResetPassword, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ResetPasswordModal);
