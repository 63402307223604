import React from "react";
import PropTypes from "prop-types";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const SubscriptionConfirmationCard = ({
  invoiceEmail,
}) => {
  return (
    <div
    className="h-screen bg-white relative"
    >
      <div
        className="fixed z-40 opacity-100 text-center py-8 px-6 rounded-card top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2
        flex flex-col justify-center items-center space-y-6 inner-shadow-lg border-4"
      >
        <div className="relative">
        <div className="uppercase whitespace-nowrap md:text-2xl font-bold">
          Tack för ditt köp
        </div>
          <IoMdCheckmarkCircleOutline
            className="text-green-500 sm:text-2xl text-4xl absolute -right-10 -top-2 sm:-right-6"
          />
        </div>
        <div>
          <div>Din order bearbetas.</div>
          <div>Vänligen hantera din faktura som vi har skickat till <span className="bolder border-b border-black">{invoiceEmail}</span></div>
        </div>
      <a
        className="whitespace-nowrap bg-black py-2 px-3 text-white uppercase md:w-1/2 hover:opacity-100 opacity-90 hover:shadow-lg hover:cursor-pointer"
        href="/profil"
      >Till Din Profil
      </a>
      </div>
    </div>
  );
};

SubscriptionConfirmationCard.propTypes = {
  invoiceEmail: PropTypes.string.isRequired,
};


export default SubscriptionConfirmationCard;
