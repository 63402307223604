import { useState } from "react";
import useInterval from "./useInterval";

const useTimer = () => {
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useInterval(() => {
    setSeconds(seconds + 1);
  }, isRunning ? 1000 : null);

  const start = () => {
    setIsRunning(true);
  };

  const pause = () => {
    setIsRunning(false);
  };

  const stop = () => {
    setIsRunning(false);
    setSeconds(0);
  };

  const changeTime = (sec) => {
    setSeconds(sec);
  };

  return { seconds, start, pause, stop, changeTime };
};

export default useTimer;
