import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../redux/actions/userActions";
import { Link } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";
import Footer from "../components/Footer";
import Chart from "react-google-charts";
import LoadingIndicator from "../components/LoadingIndicator";
import IndividualCoursesAuthenticated from "../components/IndividualCoursesAuthenticated";
import GroupCoursesAuthenticated from "../components/GroupCoursesAuthenticated";
import OrganizationCoursesAuthenticated from "../components/OrganizationCoursesAuthenticated";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const SurveyResultPage = ({ fetchUserData, userData }) => {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (!userData) {
    return <LoadingIndicator />;
  }
  return (
    <div className="w-full h-4/5 sm:h-screen">
      <div className="bg-black text-white h-2/3 flex items-center pt-28 justify-between px-28 lg:px-16 md:px-12 sm:flex-col sm:px-0 sm:relative sm:h-full sm:justify-end sm:py-10">
        <div className="w-1/2 md:pr-6 sm:w-full sm:px-6">
          <div className="text-6xl font-semibold max-w-lg md:text-4xl sm:text-center">
            <span className="text-blue-600">Hej,</span> här är ditt resultat av
            testet
          </div>
          <div className="text-xl max-w-md py-10 md:text-base md:max-w-xs sm:text-center sm:text-sm sm:py-6 sm:mx-auto">
            Baserat på ditt reslutat rekommenderar vi dig att titta på teman som
            visas nedan.{" "}
          </div>
        </div>
        <div className="w-1/2 flex items-center h-4/5 w-30per sm:w-2/3 sm:h-2/5 sm:items-end sm:justify-center">
          {userData && userData.survey_results.TEAM && userData.survey_results.INDIVIDUAL && userData.survey_results.ORGANISATION && (
            <Chart
              width={"100%"}
              height={"100%"}
              chartType="ColumnChart"
              loader={<div>Läser in diagram</div>}
              data={[
                ["", " ", { role: "style" }],
                ["Team/Grupp", Number(userData.survey_results.TEAM.toFixed(2)), "#2563eb"],
                ["Individuellt", Number(userData.survey_results.INDIVIDUAL.toFixed(2)), "#10b981"],
                ["Organisation", Number(userData.survey_results.ORGANISATION.toFixed(2)), "#4c1d95"],
              ]}
              options={{
                legend: { position: "none" },
                vAxis: {
                  textStyle: { color: "#FFFFFF" },
                  viewWindow: {
                      min: 0
                  }
                },
                hAxis: { textPosition: "none" },
                backgroundColor: {
                  fill: "#000000",
                },
              }}
            />
          )}
        </div>
        <div className="py-10 justify-end hidden sm:flex flex-col">
            <div className="pb-3 text-xs font-semibold">
              Scrolla för att se teman
            </div>
            <button
              className="flex bg-white rounded-full w-10 h-10 justify-center items-center mx-auto outline-none"
              onClick={executeScroll}
            >
              <FaArrowDown className="h-6 w-6 font-bold text-blue-600" />
            </button>
          </div>
      </div>
      <div className="hidden justify-center items-center pt-12 sm:flex">
        <Link
          to="/"
          className="bg-black text-white py-2 px-3 font-light rounded"
        >
          Se alla teman
        </Link>
      </div>
      <div className="py-12 sm:pb-0" ref={myRef}>
        <IndividualCoursesAuthenticated />
        <GroupCoursesAuthenticated />
        <OrganizationCoursesAuthenticated />
      </div>
      <Footer />
    </div>
  );
};

SurveyResultPage.propTypes = {
  fetchUserData: PropTypes.func.isRequired,
  userData: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    userData: state.userData.userdata,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserData: bindActionCreators(actions.fetchUserData, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResultPage);
