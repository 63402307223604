import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Vimeo from "@u-wave/react-vimeo";
import LoadingIndicator from "./LoadingIndicator";

const VideoLanding = ({ title, subtitle, video, vimeo }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const vimeoRef = useRef(null);
  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };
  return (
    <div className="sm:px-6">
      <h2 className="text-2xl text-center pt-16 font-bold sm:py-5 sm:font-semibold">
        {title}
      </h2>
      <div className="text-gray-500 py-5 text-center md:text-sm sm:hidden">
        {subtitle}
      </div>
      <div className="flex justify-between items-center max-w-6xl m-auto sm:flex-col shadow-accent rounded-md overflow-hidden">
        {vimeo && (
          <Vimeo
            className="w-full h-full"
            video={vimeo}
            ref={vimeoRef}
            responsive={true}
            controls={true}
          />
        )}
        {video && !vimeo && (
          <div className="w-full h-full relative">
          {!isVideoLoaded && (
            <LoadingIndicator />
          )}
          <video
            className="h-full w-full object-cover absolute top-0 left-0 z-0 rounded-md"
            onLoadedData={handleVideoLoaded}
            controls
            src={video}
          />
        </div>
        )}
      </div>
    </div>
  );
};

VideoLanding.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  video: PropTypes.string,
  vimeo: PropTypes.string,
};

export default VideoLanding;
