import React from "react";
import PropTypes from "prop-types";
import img from "../assets/howitwork.png";

const HowItWorkLanding = ({ title, subtitle, content, image }) => {
  return (
    <div className="py-10 lg:py-6 md:py-4 sm:px-6">
      <h2 className="text-2xl text-center pt-16 font-bold sm:py-5 sm:font-semibold">
        {title}
      </h2>
      <div className="text-gray-500 py-5 text-center md:text-sm sm:hidden">
        {subtitle}
      </div>
      <div className="flex justify-between items-center p-6 max-w-6xl m-auto sm:flex-col bg-white rounded-2xl">
        <div className="w-3/5 sm:w-full">
          <img src={image ? image : img} className="w-11/12"/>
        </div>
        <div className="w-2/5 flex flex-col items-center sm:w-full sm:items-start sm:pt-6 landing-how-it-works" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

HowItWorkLanding.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default HowItWorkLanding;
